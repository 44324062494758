import apostas from "./image/apostas.png";
import bebidas from "./image/bebidas.png";
import beleza from "./image/beleza.png";
import carros from "./image/carros.png";
import decoracao from "./image/decoracao.png";
import educacao from "./image/educacao.png";
import empreendorismo from "./image/empreendorismo.png";
import entertenimento from "./image/entertenimento.png";
import esportes from "./image/esportes.png";
import financas from "./image/financas.png";
import fitness from "./image/fitness.png";
import games from "./image/games.png";
import gastronomia from "./image/gastronomia.png";
import infantil from "./image/infantil.png";
import lifeStyle from "./image/lifeStyle.png";
import moda from "./image/moda.png";
import vegano from "./image/vegano.png";
import viagens from "./image/viagens.png";
import vidaSaudavel from "./image/vidaSaudavel.png";

export const listaItensCarrossel = [
    {
        id: 1,
        image: beleza,
        label: "Beleza",
        alt: "Uma mulher usando maquiagem"
    },
    {
        id: 2,
        image: carros,
        label: "Carros",
        alt: "Um carro esportivo"
    },
    {
        id: 3,
        image: apostas,
        label: "Apostas",
        alt: "Cartas de baralho"
    },
    {
        id: 4,
        image: infantil,
        label: "Infantil",
        alt: "Crianças de lego"
    },
    {
        id: 5,
        image: decoracao,
        label: "Decoração",
        alt: "Um quarto completo"
    },
    {
        id: 6,
        image: bebidas,
        label: "Bebidas",
        alt: "Colocando alguma bebida no copo"
    },
    {
        id: 7,
        image: educacao,
        label: "Educação",
        alt: "Uma mulher com roupa de formatura"
    },
    {
        id: 8,
        image: entertenimento,
        label: "Entreternimento",
        alt: "Um controle de televisão "
    },
    {
        id: 9,
        image: empreendorismo,
        label: "Empreendorismo",
        alt: "Um homem de terno indo trabalhar"
    },
    {
        id: 10,
        image: moda,
        label: "Moda",
        alt: "Uma mulher"
    },
    {
        id: 11,
        image: financas,
        label: "Finanças",
        alt: "Um cofre de porquinho"
    },
    {
        id: 12,
        image: fitness,
        label: "Fitness",
        alt: "Uma mulher se exercitando"
    },
    {
        id: 13,
        image: games,
        label: "Games",
        alt: "Um Nintendo Switch"
    },
    {
        id: 14,
        image: gastronomia,
        label: "Gastronomia",
        alt: "Derramando mel em panquecas"
    },
    {
        id: 15,
        image: vidaSaudavel,
        label: "Vida saudável",
        alt: "Um copo de iorgute com castanhas"
    },
    {
        id: 16,
        image: lifeStyle,
        label: "Lifestyle",
        alt: "Uma mulher meditando"
    },
    {
        id: 17,
        image: esportes,
        label: "Esportes",
        alt: "Uma bola de futrbol batendo na rede"
    },
    {
        id: 18,
        image: viagens,
        label: "Viagens",
        alt: "Um avião"
    },
    {
        id: 19,
        image: vegano,
        label: "Vegano",
        alt: "Uma sacola com legumes"
    },
]