import { ReactNode } from "react";

interface BotaoSubitProps {
    children: ReactNode,
    classe: string
}

export default function BotaoSubmit({ children, classe }: BotaoSubitProps) {
    return(
        <button type="submit" className={classe}> {children} </button>
    );
}