import { useEffect, useState } from "react";
import styles from "./ChatSuporte.module.css";
import MessageIcon from '@mui/icons-material/Message';
import { collection, doc, getDocs, updateDoc } from "firebase/firestore/lite";
import { db } from "../../../../db/banco";
import AreaTextoMenor from "../../../../components/AreaTextoMenor";
import usuario from "../../../../Image/usuario.png";
import suporte from "../../../../Image/suporte.png";
import IChamado from "../../../../types/IChamado";
import { useLocation } from "react-router-dom";

export default function ChatFinanceiro() {
    // Pegar parametro da página
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const parametro = params.get("id"); // Parâmetro da página

    const [meuChamado, setMeuChamado] = useState<IChamado[]>([]);

    // Mensagens existentes
    const [aux, setAux] = useState([""]);
    const [novaMensagem, setNovaMensagem] = useState("");
    // enviar e atualizar
    const [atualizar, setAtualizar] = useState(true);

    const useCollectionRef = collection(db, "abrirChamado");
    // puxar dados
    useEffect(() => {
        const obterChamados = async () => {
            const dataBD = await getDocs(useCollectionRef);
            const todosChamados: IChamado[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id } as IChamado))
            const filtrado = todosChamados.filter(chamado => chamado.id === parametro);
            setMeuChamado(filtrado);

            // pega as mensagens já existentes e coloca numa variável auxiliar
            const mensagensJaTem = filtrado.map(men => men?.conversaLadoSuporte);
            

            setAux(mensagensJaTem[0]);

        }
        obterChamados();

    }, [atualizar]);

    async function editarChamado(novosDados: any) {
        try {
            if (parametro) {
                const chamadoDocRef = doc(db, "abrirChamado", parametro);

                await updateDoc(chamadoDocRef, novosDados);
                // alert("Dados editados");
            }


        } catch (error) {
            alert("Nao deu certo")
        }
    }

    async function enviarMensagem(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        const conversaLadoSuporte = [...aux, novaMensagem]

        const conversa = {
            conversaLadoSuporte
        }
        editarChamado(conversa)
        
        setAtualizar(!atualizar);
        setNovaMensagem("");
    }

    return (
        <section className={styles.ChatSuporte}>
            <h2> Chat com o usuário </h2>

            <h3> Descrição do problema:  {meuChamado.map(chamado => (
                <> {chamado.descreva} </>
            ))}</h3>

            <aside className={styles.Chat}>
                <div className={styles.LadoUsuario}>
                    <img src={suporte} alt="suporte" />

                    <ul className={styles.MensagemUsuario}>
                        {meuChamado.map(mensa => (
                            mensa.conversaLadoSuporte?.map(cadaMensagem => (
                                <li> {cadaMensagem} </li>
                            ))
                        ))}
                    </ul>
                </div>

                <div className={styles.LadoUsuario}>
                    <ul className={styles.PrimeiraMensagem}>
                        {meuChamado.map(mensa => (
                            mensa.conversaLadoUsuario?.map(cadaMensagem => (
                                <li> {cadaMensagem} </li>
                            ))
                        ))}
                    </ul>

                    <img src={usuario} alt="usuário" />


                </div>

            </aside>

            <form onSubmit={enviarMensagem}>
                <AreaTextoMenor
                    titulo="Mensagem"
                    valor={novaMensagem}
                    controle={setNovaMensagem}
                    colunas={30}
                    linhas={3}
                    placeholder="Gostaria de mais ajuda..."
                    icone={<MessageIcon fontSize="large" />}
                />

                <button className="btn btn-success"> Enviar </button>
            </form>
        </section>
    );
}