import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function BotaoVoltar() {
    const navigate = useNavigate();
    function voltarPagina() {
        navigate(-1)
    }
    return (
        <button onClick={voltarPagina} type="button" className="btn btn-outline-secondary">
            <ArrowBackIcon fontSize="large" />
            Voltar
        </button>
    )
}