import { Switch } from "@mui/material";
import styles from "./CampoSwitch.module.css";

interface CampoSwitchProps {
    titulo: string,
    valor: boolean,
    controle: (value: boolean) => void
}

export default function CampoSwitch({ titulo, valor, controle }: CampoSwitchProps) {
    const troca = () => {
        if (valor) controle(false)
        else controle(true)
    }

    return(
        <div className={styles.CampoSwitch}>
            <label htmlFor={titulo}> {titulo} </label>
            <Switch
                onChange={troca} 
                inputProps={{ 'aria-label': 'controlled' }}
                value={valor}           
            />
        </div>
    )    
}