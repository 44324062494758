import { action, makeAutoObservable, observable } from "mobx";

interface IUsuario {
    aceito: string,    
}

class AceitoStore {
    
    usuario: IUsuario = {aceito: "aceito"};

    constructor() {
        makeAutoObservable(this, {
            
            usuario: observable,
            statusAceito: action,            
        });        
    }

    statusAceito({aceito} :IUsuario) {        
        this.usuario = {aceito};       
    }

    getStatus(): string {
        return this.usuario.aceito;
    }

    
}

const aceitoStore = new AceitoStore();

export default aceitoStore;
