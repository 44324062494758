import CampoTexto from "../../../components/CampoTexto";
import styles from "./PerfilAnunciante.module.css";
import { useState, useEffect } from "react";
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import SmartphoneOutlinedIcon from '@mui/icons-material/SmartphoneOutlined';
import { mascaraCEP, mascaraCnpj, mascaraTelefone } from "../../../utils/mascaras";
import SignpostIcon from '@mui/icons-material/Signpost';
import SixtyFpsIcon from '@mui/icons-material/SixtyFps';
import WrapTextIcon from '@mui/icons-material/WrapText';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import MapIcon from '@mui/icons-material/Map';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CampoEscolha from "../../../components/CampoEscolha";
import { estados, obterNomesDasCidadesPorEstadoNome } from "../../../utils/apiIbge";
import { useNavigate } from "react-router-dom";
import BotaoSubmit from "../../../components/BotaoSubmit";
import { collection, doc, getDocs, updateDoc } from "firebase/firestore/lite";
import { auth, db } from "../../../db/banco";
import autenticaStore from "../../../stores/autentica.store";
import IAnunciante from "../../../types/IAnunciante";
import { useSendEmailVerification, useUpdateEmail, useUpdatePassword } from "react-firebase-hooks/auth";
import 'firebase/auth';
import 'firebase/compat/auth';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';

export default function PerfilAnunciante() {
    // senha do authenticator
    const [password, setPassword] = useState('');
    const [passwordDois, setPasswordDois] = useState('');
    const [updatePassword] = useUpdatePassword(auth);
    const [credenciais, setCredenciais] = useState(false);
    const [senhasDiferentes, setSenhasDiferentes] = useState(false);
    // pedir para mudar senha
    const [quererMudarSenha, setQuererMudarSenha] = useState(false);
    // Para mudar o email
    const [quererMudarEmail, setQuererMudarEmail] = useState(false);
    const [updateEmail, updating] = useUpdateEmail(auth);
    const [emailAuth, setEmailAuth] = useState("");
    // Verifica se é verificado
    const [verificado, setVerificado] = useState(false);
    const [statusEmail, setStatusEmail] = useState(false);
    // Controla mostrar/esconder senha
    const [mostrarSenha, setMostrarSenha] = useState(false);
    const [senhaExibida, setSenhaExibida] = useState("********");
    // navegar
    const navigate = useNavigate();
    // obtem o email(serve como id) do anunciante
    const emailId = autenticaStore.getEmail();
    
    // obter o id
    const [idAnunciante, setIdAnunciante] = useState("")
    // Dados já cadastrados
    const [nomeCompleto, setNomeCompleto] = useState("");
    const [email, setEmail] = useState("");
    const [cnpj, setCnpj] = useState("");
    const [razaoSocial, setRazaoSocial] = useState("");
    const [telefone, setTelefone] = useState("");
    const [senha, setSenha] = useState("");
    const [confirmarSenha, setConfirmarSenha] = useState("");
    // Novos dados
    const [cep, setCep] = useState("");
    const [numero, setNumero] = useState("");
    const [complemento, setComplemento] = useState("");
    const [logradouro, setLogradouro] = useState("");
    const [bairro, setBairro] = useState("");
    const [estado, setEstado] = useState("");
    const [cidade, setCidade] = useState("");
    // aux para pegar as cidades
    const [nomesDasCidades, setNomesDasCidades] = useState<string[]>([]);
    // Controla se as senhas criadas são iguais

    // Verifica email
    const [sendEmailVerification, sending, error] = useSendEmailVerification(auth);
    const [user, setUser] = useState(null);
    const [jaFoiRecusado, setJaFoiRecusado] = useState(false);

    // Puxa a lista de estados pela api do IBGE
    const nomesEstados = estados.map((estado) => estado.nome);
    nomesEstados.sort();

    useEffect(() => {
        if (estado) {
            obterNomesDasCidadesPorEstadoNome(estado)
                .then(nomesDasCidades => {
                    
                    // Atualize o estado das cidades com os nomes obtidos
                    setCidade('');
                    setNomesDasCidades(nomesDasCidades);
                })
                .catch(error => {
                    console.error('Ocorreu um erro:', error);
                });
        }
    }, [estado]);

    // banco de dados
    const useCollectionRef = collection(db, "anunciante");
    // puxar dados
    useEffect(() => {
        const obterAnunciantes = async () => {
            const dataBD = await getDocs(useCollectionRef);
            const todosAnunciantes: IAnunciante[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as IAnunciante)
            const filtrado: IAnunciante[] = todosAnunciantes.filter(filtrado => filtrado.email === emailId)
            filtrado.forEach(anunciante => {
                if ("id" in anunciante) setIdAnunciante(anunciante.id)
                if ("nomeCompleto" in anunciante) setNomeCompleto(anunciante.nomeCompleto as string)
                if ("email" in anunciante) setEmail(anunciante.email as string)
                if ("cnpj" in anunciante) setCnpj(anunciante.cnpj as string)
                if ("razaoSocial" in anunciante) setRazaoSocial(anunciante.razaoSocial as string);
                if ("telefone" in anunciante) setTelefone(anunciante.telefone as string);
                if ("senha" in anunciante) {
                    setSenha(anunciante.senha as string);
                    setConfirmarSenha(anunciante.senha as string);
                }
                if ("cep" in anunciante) setCep(anunciante.cep as string);
                if ("numero" in anunciante) setNumero(anunciante.numero as string);
                if ("complemento" in anunciante) setComplemento(anunciante.complemento as string);
                if ("logradouro" in anunciante) setLogradouro(anunciante.logradouro as string);
                if ("bairro" in anunciante) setBairro(anunciante.bairro as string);
                if ("estado" in anunciante) setEstado(anunciante.estado as string);
                if ("cidade" in anunciante) setCidade(anunciante.cidade as string);
                //
                if ("emailVerificado" in anunciante) setStatusEmail(anunciante.emailVerificado as boolean);
                setJaFoiRecusado(anunciante.jaRecusado);
            })
            
        }
        obterAnunciantes();
    }, [])

    async function editarAnunciante(novosDados: any) {
        try {
            const anuncianteDocRef = doc(db, "anunciante", idAnunciante);

            await updateDoc(anuncianteDocRef, novosDados);

            alert("Dados editados");
        } catch (error) {
            alert("Nao deu certo")
        }
    }

    async function editarStatus(novosDados: any) {
        try {
            const anuncianteDocRef = doc(db, "anunciante", idAnunciante);

            await updateDoc(anuncianteDocRef, novosDados);

            // alert("Dados editados");
            

        } catch (error) {
            // alert("Nao deu certo")
            

        }
    }

    async function editarSenha(novosDados: any) {
        try {
            const anuncianteDocRef = doc(db, "anunciante", idAnunciante);

            await updateDoc(anuncianteDocRef, novosDados);

            // alert("Dados editados");
            

        } catch (error) {
            // alert("Nao deu certo")
            

        }
    }

    function concluirCadastro(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        let verificaJaFoiRecusado = false;

        if (jaFoiRecusado) verificaJaFoiRecusado = true;

        const anunciante = {
            nomeCompleto,
            email,
            cnpj,
            razaoSocial,
            telefone,
            cep,
            numero,
            complemento,
            logradouro,
            bairro,
            estado,
            cidade,
            editouAposRecusado: verificaJaFoiRecusado
        }

        editarAnunciante(anunciante);
        
        // navigate("/anunciante/inicio");
    }

    useEffect(() => {
        const emailConfirmado = async () => {
            
            

            if (auth.currentUser?.emailVerified) {
                
                setVerificado(true);

            } else {
                
                setVerificado(false);
            }
        }
        emailConfirmado();

    }, []);  


    if (error) {
        return (
            <div>
                <p>Error: {error.message}</p>
            </div>
        );
    }
    if (sending) {
        return <p>Sending...</p>;
    }

    function confirmarCliqueEmail() {
        if (verificado) {
            const anunciante = {
                emailVerificado: true
            }
            editarStatus(anunciante);
            alert("Email confirmado");
            navigate("/anunciante/inicio")
        } else {
            
        }
    }

    function mostraSenha() {
        setMostrarSenha(true);
        setSenhaExibida(senha);
    }

    function esconderSenha() {
        setMostrarSenha(false);
        setSenhaExibida("********")
    }

    function quererTrocarSenha() {
        setQuererMudarSenha(!quererMudarSenha)
    }

    function quererTrocarEmail() {
        setQuererMudarEmail(!quererMudarEmail)
    }   

    const handleLogout = () => {
        autenticaStore.logout(); // Chame o método logout da AutenticaStore
    };

    return (
        <section className={styles.PerfilAnunciante}>
            <h2> Perfil</h2>

            <section>
                <label> Email </label>
                <div>
                    <EmailIcon fontSize="large" />
                    <label> {emailId} </label>
                </div>

                {!verificado ? <button onClick={async () => {
                    const success = await sendEmailVerification();
                    if (success) {
                        alert('Email de verificação enviado');
                        navigate("/login")

                    }
                }}
                    className="btn btn-warning"> Verificar email </button> : ""}

                {statusEmail === false && verificado ? <button onClick={confirmarCliqueEmail} className="btn btn-info"> Confirmar </button> : ""}

                {statusEmail && verificado ? <p> Email verificado </p> : ""}

                {/* <button className="btn btn-primary" onClick={quererTrocarEmail}> Trocar email </button> */}

                {quererMudarEmail && <><label> Novo email </label>
                    <div>
                        <MarkEmailUnreadIcon fontSize="large" />
                        <input
                            type="email"
                            value={emailAuth}
                            onChange={(e) => setEmailAuth(e.target.value)}
                            placeholder="joao@gmail.com"
                            min={8}
                        />
                    </div>
                    <button
                        className="btn btn-info"
                        onClick={async () => {
                            const success = await updateEmail(emailAuth);
                            if (success) {
                              alert('Updated email address');
                            }
                          }}
                    >
                        Editar email
                    </button></>}

                <label> Senha atual </label>
                <div>
                    <PasswordOutlinedIcon fontSize="large" />
                    <label> {senhaExibida} </label>
                    {!mostrarSenha ? <VisibilityOffOutlinedIcon fontSize="small" onClick={mostraSenha} className={styles.ClickOlho} /> :
                        <VisibilityOutlinedIcon fontSize="small" onClick={esconderSenha} className={styles.ClickOlho}
                        />}

                </div>

                <button className="btn btn-primary" onClick={quererTrocarSenha}> Trocar senha </button>

                {quererMudarSenha && <>
                    <label> Nova senha </label>
                    <div>
                        <HttpsOutlinedIcon fontSize="large" />
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="********"
                            minLength={8}
                        />
                    </div>

                    {password.length < 8 && <p> Mínimo de 8 caractertes </p>}

                    <label> Repetir nova senha </label>
                    <div>
                        <HttpsOutlinedIcon fontSize="large" />
                        <input
                            type="password"
                            value={passwordDois}
                            onChange={(e) => setPasswordDois(e.target.value)}
                            placeholder="********"
                            min={8}
                        />
                    </div>

                    {passwordDois.length < 8 && <p> Mínimo de 8 caractertes </p>}

                    {senhasDiferentes && <p> Senhas diferentes! </p>}

                    <button className="btn btn-info" onClick={async () => {
                        if (password !== passwordDois) {
                            setSenhasDiferentes(true);
                        } else {
                            const success = await updatePassword(password);
                            if (success) {
                                const anunciante = {
                                    senha: password
                                }
                                editarSenha(anunciante);
                                alert('Senha alterada');
                                setSenhaExibida(password);
                                setSenhasDiferentes(false);
                            } else {
                                alert("Entre na plataforma novamente");
                                handleLogout();
                                navigate("/login")
                            }
                        }

                    }}
                        disabled={!password || !passwordDois || password.length < 8 || passwordDois.length < 8}

                    > Editar senha </button> </>}

            </section>

            <form onSubmit={concluirCadastro}>
                <h3> Caso queira editar seus dados: </h3>

                <CampoTexto
                    titulo="Nome completo"
                    valor={nomeCompleto}
                    controle={setNomeCompleto}
                    icone={<PersonIcon fontSize="large" />}
                    placeholder="João da Silva Pereira"
                    minLength={8}
                />
                {!nomeCompleto && <p> Preencha esse campo </p>}
                {nomeCompleto.length < 8 ? <p> Número mínimo de caracteres: 8 </p> : ""}

                {/* <CampoTexto
                    titulo="E-mail"
                    valor={email}
                    controle={setEmail}
                    icone={<EmailIcon fontSize="large" />}
                    placeholder="joaosilva@gmail.com"
                    type="email"
                    minLength={11}
                />
                
                {!email && <p> Preencha esse campo </p>}
                {email.length < 11 ? <p> Número mínimo de caracteres: 11 </p> : ""} */}

                <CampoTexto
                    titulo="CNPJ"
                    valor={mascaraCnpj(cnpj)}
                    controle={setCnpj}
                    icone={<AssignmentOutlinedIcon fontSize="large" />}
                    placeholder="99.999.999/9999-99"
                    minLength={17}
                />
                {!cnpj && <p> Preencha esse campo </p>}
                {cnpj.length < 17 ? <p> Número mínimo de caracteres: 17 </p> : ""}

                <CampoTexto
                    titulo="Razão social"
                    valor={razaoSocial}
                    controle={setRazaoSocial}
                    icone={<DriveFileRenameOutlineOutlinedIcon fontSize="large" />}
                    placeholder="Padaria pão doce LTDA"
                    minLength={3}
                />
                {!razaoSocial && <p> Preencha esse campo </p>}
                {razaoSocial.length < 3 ? <p> Número mínimo de caracteres: 3 </p> : ""}

                <CampoTexto
                    titulo="Telefone"
                    valor={mascaraTelefone(telefone)}
                    controle={setTelefone}
                    icone={<SmartphoneOutlinedIcon fontSize="large" />}
                    placeholder="(99) 99999-9999"
                />
                {!telefone && <p> Preencha esse campo </p>}
                {telefone.length < 14 ? <p> Número mínimo de caracteres: 14 </p> : ""}

                {/* <CampoTexto
                    titulo="Senha"
                    valor={senha}
                    controle={setSenha}
                    icone={<HttpsOutlinedIcon fontSize="large" />}
                    placeholder="********"
                    type="password"
                    minLength={8}
                />
                {!senha && <p> Preencha esse campo </p>}
                {senha.length < 8 ? <p> Número mínimo de caracteres: 8 </p> : ""}

                <CampoTexto
                    titulo="Confirmar senha"
                    valor={confirmarSenha}
                    controle={setConfirmarSenha}
                    icone={<LockClockOutlinedIcon fontSize="large" />}
                    placeholder="********"
                    type="password"
                    minLength={8}
                />
                {!confirmarSenha && <p> Preencha esse campo </p>}
                {confirmarSenha.length < 8 ? <p> Número mínimo de caracteres: 8 </p> : ""}

                {senhaDiferente && <Alertas controle={setSenhaDiferente} mensagem="Senhas diferentes" tipoMensagem="warning" />} */}

                <hr />

                <h3> Complete seu cadastro </h3>

                <CampoTexto
                    titulo="CEP"
                    valor={mascaraCEP(cep)}
                    controle={setCep}
                    icone={<SignpostIcon fontSize="large" />}
                    placeholder="99999-999"
                    minLength={9}
                />

                {!cep && <p> Preencha esse campo </p>}
                {cep.length < 9 ? <p> Número mínimo de caracteres: 9 </p> : ""}

                <CampoTexto
                    titulo="Número"
                    valor={numero}
                    controle={setNumero}
                    icone={<SixtyFpsIcon fontSize="large" />}
                    placeholder="136"
                    minLength={1}
                />

                {!numero && <p> Preencha esse campo </p>}

                <CampoTexto
                    titulo="Complemento"
                    valor={complemento}
                    controle={setComplemento}
                    icone={<WrapTextIcon fontSize="large" />}
                    placeholder="apartamento 807"
                    minLength={5}
                />

                {!complemento && <p> Preencha esse campo </p>}
                {complemento.length < 5 ? <p> Número mínimo de caracteres: 5 </p> : ""}

                <CampoTexto
                    titulo="Logradouro"
                    valor={logradouro}
                    controle={setLogradouro}
                    icone={<EditRoadIcon fontSize="large" />}
                    placeholder="Rua José Maria"
                    minLength={5}
                />

                {!logradouro && <p> Preencha esse campo </p>}
                {logradouro.length < 5 ? <p> Número mínimo de caracteres: 5 </p> : ""}

                <CampoTexto
                    titulo="Bairro"
                    valor={bairro}
                    controle={setBairro}
                    icone={<MapsHomeWorkIcon fontSize="large" />}
                    placeholder="Centro"
                    minLength={5}
                />

                {!bairro && <p> Preencha esse campo </p>}
                {bairro.length < 5 ? <p> Número mínimo de caracteres: 5 </p> : ""}

                <CampoEscolha
                    titulo="Estado"
                    lista={nomesEstados}
                    controle={setEstado}
                    icone={<MapIcon fontSize="large" />}
                    valorPadrao={estado}
                />

                {estado.length === 0 ? <p> Preencha esse campo </p> : ""}

                <CampoEscolha
                    titulo="Cidade"
                    lista={nomesDasCidades}
                    controle={setCidade}
                    icone={<LocationOnIcon fontSize="large" />}
                    valorPadrao={cidade}
                />

                {cidade.length === 0 ? <p> Preencha esse campo </p> : ""}

                <div className={styles.BotoesNavegação}>
                    <BotaoSubmit classe="btn btn-secondary">
                        Concluir
                    </BotaoSubmit>
                </div>
            </form>
        </section>
    )
}