import { Link } from "react-router-dom";
import styles from "./EstatisticasDeCampanhaModerador.module.css";
import { collection, getDocs } from "firebase/firestore/lite";
import { useEffect, useState } from "react";
import { db } from "../../../../db/banco";
import ICriarCampanha from "../../../../types/ICriarCampanha";
import { listaTiposDeCampanha } from "../../../../utils/listas";
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';

export default function EstatisticasDeCampanhaModerador() {
    // Recebe e administra as campanhas de performance
    const [campanhas, setCampanhas] = useState<ICriarCampanha[]>([]);

    // Obtem e atualiza os dados
    const useCollectionRef = collection(db, "criarCampanha");
    useEffect(() => {
        const obterCampanhasPerformance = async () => {
            const dataBD = await getDocs(useCollectionRef);
            const todasCampanhas: ICriarCampanha[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as ICriarCampanha);
            const campanhasFiltradas: ICriarCampanha[] = todasCampanhas.filter(campanhasPerformance => campanhasPerformance.tipoDeCampanha === listaTiposDeCampanha[2] || campanhasPerformance.tipoDeCampanha === listaTiposDeCampanha[3]
                || campanhasPerformance.tipoDeCampanha === listaTiposDeCampanha[4] && campanhasPerformance.codigoIntegracao);
            setCampanhas(campanhasFiltradas);

        }
        obterCampanhasPerformance();
    }, [])
    return (
        <section className={styles.EstatisticasDeCampanhaModerador}>
            <h2> Estatísticas de campanha com performance </h2>

            <p> Somente campanhas integradas </p>

            <table className="table table-light table-striped text-center">
                <thead>
                    <tr>
                        <th scope="col" className="text-white" style={{ width: '33%' }}> Nome </th>                        
                        <th scope="col" className="text-white" style={{ width: '33%' }}> Ações </th>
                    </tr>
                </thead>
                <tbody>
                    {campanhas.map((cel, index) => (
                        <tr key={cel.id} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
                            <td style={{ width: '33%' }}> {cel.nomeDaCampanha} </td>
                            <td style={{
                                width: '33%',
                                maxWidth: '300px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                            }}>
                                {cel.codigoIntegracao && <Link to={`/moderador/ver-estatisticas-de-campanha?id=${cel.codigoIntegracao}`}> <EqualizerOutlinedIcon  /> </Link>}
                            </td>
                            
                        </tr>
                    ))}
                </tbody>
            </table>
        </section>
    )
}