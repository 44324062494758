export const listaRedesSociais = [
    'Facebook',
    'YouTube',
    'Instagram',
    'TikTok',
    'Twitter',
    'Linkedin',
    'Pinterest',
    'Skype',
    'Snapchat',
    'WebSite'
];