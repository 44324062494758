import { Link } from "react-router-dom";
import INotificacao from "../../types/INotificao";
import styles from "./Notificacoes.module.css";
import { doc, updateDoc } from "firebase/firestore/lite";
import { db } from "../../db/banco";

interface NotificacoesProps {
    lista: INotificacao[]    
}

export default function Notificacoes({ lista }: NotificacoesProps) {    

    async function editarNotificacao(novosDados: any, id: string) {
        try {
            const useCollectionRefNotificacao = doc(db, "notificacao", id);

            await updateDoc(useCollectionRefNotificacao, novosDados);
            

        } catch (error) {
            alert("Nao deu certo")
        }
    }

    async function vistoNotificacao(id: string) {
        const notificacao = {
            visto: true
        }
        editarNotificacao(notificacao, id);
    }

    console.log("not no compo", lista);
    

    return (
        <>
            {lista.map(notificacao => (
                <div className={styles.Notificacoes}>
                    <Link to={notificacao.link}><p onClick={() => vistoNotificacao(notificacao.id)}> {notificacao.mensagem} {notificacao.data} </p></Link>                   
                </div>
            ))}
        </>
    );
}