import Cabecalho from "./Cabecalho";
import Parceiros from "./Parceiros";
import Planos from "./Planos";
import Remuneracao from "./Remuneracao";
import Segmentos from "./Segmentos";
import Sobre from "./Sobre";
import Vantangens from "./Vantagens";

export default function PaginaInicial() {
    return(
        <>
            <Cabecalho />
            <Sobre /> 
            <Segmentos />      
            <Parceiros /> 
            <Remuneracao />
            <Planos />
            <Vantangens />
        </>
    )
}