export const listaDeCategorias = [
    "Todas",
    'Automóveis',
    'Bebida',
    'Beleza',
    'Casa de apostas',
    'Decoração',
    'Educação',
    'Empreendorismo',
    'Esportes',
    'Finanças',
    'Fitness',
    'Games/Geek',
    'Gastronomia',
    'Infantil',
    'Lifestyle',
    'Livros',
    'Moda',
    'Moda masculina',
    'Música',
    'Pets',
    'Veganos',
    'Viagem',
    'Vida saudável'
];