import { useState, useEffect } from "react";
import guardaPaginaAnterior from "../../../../stores/paginaAnterior";
import styles from "./RecicladosUsuarios.module.css";
import RecusadosAnunciante from "../UsuariosRecusados/RecusadosAnunciante";
import RecicladosAnunciante from "./RecicladosAnunciante";
import RecicladosInfluenciadores from "./RecicladosInfluenciadores";

export default function RecicladosUsuarios() {
    // Controla qual tipo de conta sera criada
    const [qualUsuario, setQualUsuario] = useState(true); // true é para influenciador
    // controla o botao clicado
    const [botaoAnunciante, setBotaoAnunciante] = useState("btn btn-outline-secondary");
    const [botaoInfluenciador, setBotaoInfluenciador] = useState("btn btn-dark");

    // Funcao que muda o formulario para Anunciante
    function mudaParaAnunciante() {
        setQualUsuario(false);
        setBotaoAnunciante("btn btn-dark");
        setBotaoInfluenciador("btn btn-outline-secondary");
    }
    // Funcao que muda o formulario para Influenciador
    function mudaParaInfluenciador() {
        setQualUsuario(true);
        setBotaoInfluenciador("btn btn-dark");
        setBotaoAnunciante("btn btn-outline-secondary");
    }

    useEffect(() => {
        const guardaTipo = guardaPaginaAnterior.getTipo();        
        setQualUsuario(guardaTipo);
        if (guardaTipo)  mudaParaInfluenciador()
        else mudaParaAnunciante();
    }, []);

    return(
        <section className={styles.RecicladosUsuarios}>
            <h2> Usuários reciclados </h2>
            <p> Escolha o tipo de usuário que deseja visualizar. </p>

            <div>
                <button type="button" className={botaoInfluenciador} onClick={mudaParaInfluenciador}> Influenciador </button>
                <button type="button" className={botaoAnunciante} onClick={mudaParaAnunciante}> Anunciante </button>
            </div>

            {qualUsuario ? <RecicladosInfluenciadores /> : <RecicladosAnunciante />}
            
        </section>
    );
}