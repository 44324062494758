import styles from "./SmallTextArea.module.css";
import { ReactNode } from 'react';

interface SmallTextAreaProps {
    title?: string,
    value: string,
    control: (value: string) => void,
    icon: ReactNode,
    placeholder?: string,
    lines: number,
    collumns: number,
    requiredField?: boolean   
}

export default function SmallTextArea({ title, value, control, icon, placeholder, lines, collumns, requiredField}: SmallTextAreaProps) {
    return(
        <div className={styles.SmallTextArea}>
            <label> {title} </label>
            <div>
                {icon}
                <textarea
                    value={value}
                    onChange={(e) => control(e.target.value)}
                    placeholder={placeholder}                    
                    required={requiredField}
                    rows={lines}
                    cols={collumns}
                />
            </div>
        </div>
    )
}