import styles from "./Faq.module.css";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useState } from "react";
import BotaoVoltar from "../../../components/BotaoVoltar";
import logo from "./logo.png";

export default function Faq() {
    // Controla o abre e fecha, mostrando o conteudo e alterando a setinha
    const [seguidores, setSeguidores] = useState(false);
    const [demora, setDemora] = useState(false);
    const [pagamento, setPagamento] = useState(false);
    const [cadastro, setCadastro] = useState(false);
    const [bandeiras, setBandeiras] = useState(false);
    const [sobre, setSobre] = useState(false);
    const [custa, setCusta] = useState(false);     
    // Funcoes para o abre e fecha
    function openSeguidores() {
        if (seguidores) setSeguidores(false);
        else setSeguidores(true);
    }

    function openDemora() {
        if (demora) setDemora(false);
        else setDemora(true);
    }

    function openPagamento() {
        if (pagamento) setPagamento(false);
        else setPagamento(true);
    }

    function openCadastro() {
        if (cadastro) setCadastro(false);
        else setCadastro(true);
    }

    function openBandeiras() {
        if (bandeiras) setBandeiras(false);
        else setBandeiras(true);
    }
    
    function openSobre() {
        if (sobre) setSobre(false);
        else setSobre(true);
    }

    function openCusta() {
        if (custa) setCusta(false);
        else setCusta(true);
    }   
    // Ao abrir a pagina, ir sempre para o topo
    window.scrollTo(0,0);
   
    return (
        <section className={styles.Faq}>
            <nav>
                <BotaoVoltar />  
                <img src={logo} alt="Logo da ISM, sendo um pássaro" />
            </nav>         
            <div className="container-fluid">
                <h2> FAQ </h2>
                <ul>
                    <li onClick={openSeguidores} className={styles.Listas}>
                        {seguidores ? <ArrowDropDownIcon /> : <ArrowRightIcon />}

                        <p> Quantos seguidores preciso ter para trabalhar com o ISM? </p>
                    </li>
                    {seguidores ? <span> É importante que você tenha pelo menos 10.000 seguidores </span> : ''}

                    <li onClick={openDemora} className={styles.Listas}>
                        {demora ? <ArrowDropDownIcon /> : <ArrowRightIcon />}

                        <p>  Quanto tempo demora para a minha conta ser verificada pela administração?  </p>
                    </li>
                    {demora ? <span> Essa etapa costuma ser bem rápida. Geralmente verificamos no mesmo dia em que você fez o cadastro,
                        mas o nosso prazo é de até 2 dias úteis. Caso tenha passado esse tempo e a sua conta ainda não tenha sido avaliada,
                        entre em contato conosco através do suporte.</span> : ''}

                    <li onClick={openPagamento} className={styles.Listas}>
                        {pagamento ? <ArrowDropDownIcon /> : <ArrowRightIcon />}

                        <p> Como funciona o pagamento aos influenciadores? </p>
                    </li>
                    {pagamento ? <span> Atualmente, trabalhamos nos formatos de publipost e permuta. Você consegue identificar o formato de
                        remuneração de uma campanha no menu informativo sobre ela. Para receber o pagamento, você deve ter pelo menos 50 reais
                        acumulados em seu saldo e solicitar o saque pela nossa plataforma. Os pagamentos serão realizados via transferência
                        bancária ou paypal em sua conta pessoal cadastrada previamente na plataforma. </span> : ''}

                    <li onClick={openCadastro} className={styles.Listas}>
                        {cadastro ? <ArrowDropDownIcon /> : <ArrowRightIcon />}

                        <p> Meu cadastro não foi aprovado no ISM. Por quê? </p>
                    </li>
                    {cadastro ? <><span> Todos são bem vindos a se cadastrar no ISM. Porém, para estar apto a participar das nossas campanhas,
                        a triagem da nossa equipe especializada avaliará os seguintes pontos: </span>
                        <ol className={styles.ListasPonto}>
                            <li> * O perfil deve ter pelo menos 10k seguidores </li>
                            <li> * Seguidores devem ser reais (detectamos perfis com seguidores comprados/bots) </li>
                            <li> * Taxa de engajamento deve ser condizente com o número de seguidores (detectamos o uso de grupos de engajamento) </li>
                            <li> * O cadastro deve estar completo </li>
                        </ol>
                    </> : ''}

                    <li onClick={openBandeiras} className={styles.Listas}>
                        {bandeiras ? <ArrowDropDownIcon /> : <ArrowRightIcon />}

                        <p> Quais bandeiras são aceitas ao realizar um pagamento? </p>
                    </li>
                    {bandeiras ? <><span> No <strong> Pagseguro </strong> as bandeiras aceitas são: </span>
                        <ol>
                            <li> - Visa </li>
                            <li> - MasterCard </li>
                            <li> - American Express </li>
                            <li> - Diners </li>
                            <li> - Hipercard </li>
                            <li> - Aura </li>
                            <li> - Elo </li>
                            <li> - PLENOCard </li>
                            <li> - PersonalCard </li>
                            <li> - JCB </li>
                            <li> - Discover </li>
                            <li> - BrasilCard </li>
                            <li> - FORTBRASIL </li>
                            <li> - CARDBAN </li>
                            <li> - ValeCARD </li>
                            <li> - Cabal </li>
                            <li> - Mais! </li>
                            <li> - Avista </li>
                            <li> - GRANDCARD </li>
                            <li> - Sorocred </li>
                            <li> - Up Policard </li>
                            <li> - Up Banese Car </li>
                        </ol>
                        <span> Para o <strong> PayPal: </strong> </span> <br />
                        <span> Cartão de crédito </span> <br />
                        <span> O PayPal possibilita o pagamento com 6 bandeiras nacionais e internacionais: </span>

                        <ol>
                            <li> - Visa </li>
                            <li> - MasterCard </li>
                            <li> - American Express </li>
                            <li> - Hipercard </li>
                            <li> - Hiper </li>
                            <li> - Elo </li>
                        </ol>

                        <span> Para compras em moedas diferentes do Real, são aceitos apenas cartões com a função
                            “internacional” habilitada. </span> <br /> <br />
                        <span> Débito online </span> <br /> <br />
                        <span> Para clientes correntistas dos bancos Citibank, HSBC, Itaú e Santander, há a possibilidade
                            de pagamento por débito online.</span><br /> <br />
                        <span> Nessa opção, o valor da compra é debitado automaticamente da conta bancária cadastrada
                            no PayPal. Funciona de forma semelhante ao cartão de débito, com a facilidade de buscar
                            o valor diretamente na conta do comprador.</span>

                    </> : ''}

                    <li onClick={openSobre} className={styles.Listas}>
                        {sobre ? <ArrowDropDownIcon /> : <ArrowRightIcon />}

                        <p> O que é o ISM? </p>
                    </li>
                    {sobre ? <>
                        <span> O Influencie Sua Marca (ISM) é uma plataforma de Marketing de Influência que funciona de maneira self-service. </span> <br /> <br />
                        <span> Basicamente, somos um Marketplace de Influenciadores Digitais, ou seja: </span> <br /> <br />
                        <span> Por aqui, Influenciadores e Marcas podem se conectar e executar campanhas incríveis em diversos formatos de parceria </span> <br /> <br />
                        <span> Acreditamos na autenticidade e na criatividade dos influenciadores. Nossa plataforma permite que eles expressem suas
                            conexões com suas audiências de forma única. Para as marcas, oferecemos acesso a uma variedade de influenciadores especializados,
                            tornando suas campanhas mais direcionadas e eficazes. </span> <br /> <br />
                        <span> Juntos, construímos parcerias de sucesso, ampliando o alcance das marcas e impulsionando o crescimento dos influenciadores.
                            Seja você um influenciador talentoso ou uma marca inovadora, venha se conectar conosco no ISM e explore o poder do marketing
                            de influência!</span>
                    </> : ''}

                    <li onClick={openCusta} className={styles.Listas}>
                        {custa ? <ArrowDropDownIcon /> : <ArrowRightIcon />}

                        <p> Quanto custa para ter uma conta no ISM? </p>
                    </li>
                    {custa ? <>
                        <span> Ser um influencer cadastrado na nossa plataforma é gratuito! </span> <br />
                        <span> Você não precisa pagar nada para criar sua conta e ter acesso às campanhas :) </span>
                    </> : ''}
                </ul>
            </div>
        </section>
    );
}