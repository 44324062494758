import styles from "./PlanoISM.module.css";

export default function PlanoISM() {
    return(
        <div className={styles.PlanoISM}>
            <label> Plano </label>
            <div>
                <ul>
                    <li> - Possui 2 meses grátis </li>
                    <li> - Campanhas ilimitadas </li>
                    <li> - Materiais ilimitados </li>
                    <li> - Taxa ao criar a campanha de 10% sobre o seu budget </li>
                </ul>
            </div>
        
        
        </div>
    )
}