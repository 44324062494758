import { Outlet } from "react-router-dom";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import autenticaStore from "../../../stores/autentica.store";
import MenuPainelAnunciante from "../../../components/MenuPainelAnunciante";

export default function PaginaBaseRotaPrivadaAnunciante() {
    // obtem o email(serve como id) do anunciante
    const email = autenticaStore.getEmail();
    
    
    return (
        <>
            <MenuPainelAnunciante
                // Painel e ícone início
                painel={`Painel anunciante`}
                toInicio="/anunciante/inicio"
                // Campanhas
                camapanhasUm="Criar campanha"
                campanhasDois="Minhas campanhas"
                toCampanhaUm="/anunciante/criar-campanha"
                toCampanhaDois="/anunciante/minhas-campanhas"
                // Influenciadores
                iconeGerenciarInfluenciadores={<PeopleAltIcon fontSize="large" />}
                textoGerenciarInfluenciadores="Gerenciar influenciadores"
                // Tipo da conta
                ehAnunciante={true}
            />
            <Outlet />
        </>
    );
}