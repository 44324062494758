import CampoTexto from "../../../components/CampoTexto";
import styles from "./PerfilInfluenciador.module.css"
import { useState, useEffect } from "react";
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import InstagramIcon from '@mui/icons-material/Instagram';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import ScoreIcon from '@mui/icons-material/Score';
import CategoryIcon from '@mui/icons-material/Category';
import MapIcon from '@mui/icons-material/Map';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import SmartphoneOutlinedIcon from '@mui/icons-material/SmartphoneOutlined';
import CampoEscolha from "../../../components/CampoEscolha";
import { listaTipoDePessoa } from "../../../utils/listaTipoDePessoa";
import { listaRedesSociais } from "../../../utils/listaRedesSociais";
import { listaQuantidadeDeSeguidores } from "../../../utils/listaQuantidadeDeSeguidores";
import { listaCategorias } from "../../../utils/listaCategorias";
import { listaGeneros } from "../../../utils/listaGerenos";
import { mascaraCEP, mascaraCnpj, mascaraCpf, mascaraTelefone } from "../../../utils/mascaras";
import { estados, obterNomesDasCidadesPorEstadoNome } from "../../../utils/apiIbge";
import AreaTextoMenor from "../../../components/AreaTextoMenor";
import Person3OutlinedIcon from '@mui/icons-material/Person3Outlined';
import SignpostIcon from '@mui/icons-material/Signpost';
import SixtyFpsIcon from '@mui/icons-material/SixtyFps';
import WrapTextIcon from '@mui/icons-material/WrapText';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import ImageSearchOutlinedIcon from '@mui/icons-material/ImageSearchOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { collection, doc, getDocs, updateDoc } from "firebase/firestore/lite";
import { auth, db, storage } from "../../../db/banco";
import { useNavigate } from "react-router-dom";
import { getDownloadURL, getStorage, listAll, ref, uploadBytesResumable } from "firebase/storage";
import IInfluenciador from "../../../types/IInfluenciador";
import autenticaStore from "../../../stores/autentica.store";
import pessoa from "./pessoa.png";
import { useSendEmailVerification, useUpdatePassword } from "react-firebase-hooks/auth";
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import CommentBankOutlinedIcon from '@mui/icons-material/CommentBankOutlined';
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import PinIcon from '@mui/icons-material/Pin';
import MoneyIcon from '@mui/icons-material/Money';
import CampoSwitch from "../../../components/CampoSwitch";

export default function PerfilInfluenciador() {   
    // senha do authenticator
    const [password, setPassword] = useState('');
    const [passwordDois, setPasswordDois] = useState('');
    const [sendEmailVerification, sending, error] = useSendEmailVerification(auth);
    const [updatePassword, updating] = useUpdatePassword(auth);
    const [senhasDiferentes, setSenhasDiferentes] = useState(false);
    // Para mudar o email
    const [quererMudarEmail, setQuererMudarEmail] = useState(false);
    // Verifica se é verificado
    const [verificado, setVerificado] = useState(false);
    const [statusEmail, setStatusEmail] = useState(false);
    // pedir para mudar senha
    const [quererMudarSenha, setQuererMudarSenha] = useState(false);
    // Controla mostrar/esconder senha
    const [mostrarSenha, setMostrarSenha] = useState(false);
    const [senhaExibida, setSenhaExibida] = useState("********");
    // obtem o email(serve como id) do influenciador
    const emailId = autenticaStore.getEmail();
    // obter o id
    const [idInfluenciador, setIdInfluenciador] = useState("")
    // Atributos já cadastrados
    const [nomeCompleto, setNomeCompleto] = useState("");
    const [email, setEmail] = useState("");
    const [tipoDePessoa, setTipoDePessoa] = useState("");
    const [redeSocialPrincipal, setRedeSocialPrincipal] = useState("");
    const [arrobaPrincipal, setArrobaPrincipal] = useState("");
    const [quantidadeDeSeguidores, setQuantidadeDeSeguidoes] = useState("");
    const [categoria, setCategoria] = useState("");
    const [estado, setEstado] = useState("");
    const [cidade, setCidade] = useState("");
    // aux para pegar as cidades
    const [nomesDasCidades, setNomesDasCidades] = useState<string[]>([]);
    // Restro dos atributos
    const [data, setData] = useState("");
    const [genero, setGenero] = useState("");
    const [telefone, setTelefone] = useState("");
    const [senha, setSenha] = useState("");
    const [confirmarSenha, setConfirmarSenha] = useState("");
    // Controla se as senhas criadas são iguais
    const [senhaDiferente, setSenhaDiferente] = useState(false);
    // Usado para navegar entre rotas
    // Atributos pós cadastros
    const [sobre, setSobre] = useState("");
    const [cep, setCep] = useState("");
    const [numero, setNumero] = useState("");
    const [complemento, setComplemento] = useState("");
    const [logradouro, setLogradouro] = useState("");
    const [bairro, setBairro] = useState("");
    // Redes sociais e arrobas adicionais
    const [redesSociais, setRedesSociais] = useState([""]);
    const [arrobas, setArrobas] = useState([""]);
    // dados bancários
    const [banco, setBanco] = useState("");
    const [agencia, setAgencia] = useState("");
    const [conta, setConta] = useState("");
    const [tipoDeContaBancaria, setTipoDeContaBancaria] = useState("");
    const listaTipoDeContaBancario = ["Corrente", "Poupança"];
    const [cpf, setCpf] = useState("");
    const [cnpj, setCnpj] = useState("");
    const [codigoDoBanco, setCodigoDoBanco] = useState("");
    const [aceitaPix, setAceitaPix] = useState(false);

    // imagens
    const [progress, setProgress] = useState(0);
    const [foto, setFoto] = useState("");
    const [downloadFoto, setDownloadFoto] = useState("");
    // MidiaKit
    const [midiaKit, setMidiaKit] = useState("");
    const [downloadMidiaKit, setDownloadMidiaKit] = useState("");
    // veririca se ja foi recusado
    const [jaFoiRecusado, setJaFoiRecusado] = useState(false);

    // Usado para navegar entre rotas
    const navigate = useNavigate();

    // Puxa a lista de estados pela api do IBGE
    const nomesEstados = estados.map((estado) => estado.nome);
    nomesEstados.sort();

    useEffect(() => {
        if (estado) {
            obterNomesDasCidadesPorEstadoNome(estado)
                .then(nomesDasCidades => {
                    // console.log(nomesDasCidades);
                    // Atualize o estado das cidades com os nomes obtidos
                    setCidade('');
                    setNomesDasCidades(nomesDasCidades);
                })
                .catch(error => {
                    console.error('Ocorreu um erro:', error);
                });
        }
    }, [estado]);

    // banco de dados
    const useCollectionRef = collection(db, "influenciador");
    // puxar dados
    useEffect(() => {
        const obterAnunciantes = async () => {
            const dataBD = await getDocs(useCollectionRef);
            const todosInfluenciadores: IInfluenciador[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as IInfluenciador)
            const filtrado: IInfluenciador[] = todosInfluenciadores.filter(filtrado => filtrado.email === emailId)
            filtrado.forEach(influenciador => {
                if ("id" in influenciador) setIdInfluenciador(influenciador.id)
                if ("nomeCompleto" in influenciador) setNomeCompleto(influenciador.nomeCompleto as string)
                if ("email" in influenciador) setEmail(influenciador.email as string)
                if ("tipoDePessoa" in influenciador) setTipoDePessoa(influenciador.tipoDePessoa);
                if ("redeSocialPrincipal" in influenciador) setRedeSocialPrincipal(influenciador.redeSocialPrincipal);
                if ("arrobaPrincipal" in influenciador) setArrobaPrincipal(influenciador.arrobaPrincipal);
                if ("quantidadeDeSeguidores" in influenciador) setQuantidadeDeSeguidoes(influenciador.quantidadeDeSeguidores);
                if ("categoria" in influenciador) setCategoria(influenciador.categoria);
                if ("estado" in influenciador) setEstado(influenciador.estado as string);
                if ("cidade" in influenciador) setCidade(influenciador.cidade as string);
                if ("data" in influenciador) setData(influenciador.data);
                if ("genero" in influenciador) setGenero(influenciador.genero);
                if ("telefone" in influenciador) setTelefone(influenciador.telefone as string);
                if ("senha" in influenciador) {
                    setSenha(influenciador.senha as string);
                    setConfirmarSenha(influenciador.senha as string);
                }
                //
                if ("sobre" in influenciador) setSobre(influenciador.sobre);
                if ("cep" in influenciador) setCep(influenciador.cep as string);
                if ("numero" in influenciador) setNumero(influenciador.numero as string);
                if ("complemento" in influenciador) setComplemento(influenciador.complemento as string);
                if ("logradouro" in influenciador) setLogradouro(influenciador.logradouro as string);
                if ("bairro" in influenciador) setBairro(influenciador.bairro as string);
                //
                if ("emailVerificado" in influenciador) setStatusEmail(influenciador.emailVerificado as boolean);

                // dados bancários
                if ("banco" in influenciador) setBanco(influenciador.banco);
                if ("agencia" in influenciador) setAgencia(influenciador.agencia);
                if ("conta" in influenciador) setConta(influenciador.conta);
                if ("tipoDeContaBancaria" in influenciador) setTipoDeContaBancaria(influenciador.tipoDeContaBancaria);
                if ("cpf" in influenciador) setCpf(influenciador.cpf);
                if ("cnpj" in influenciador) setCnpj(influenciador.cnpj);
                if ("codigoDoBanco" in influenciador) setCodigoDoBanco(influenciador.codigoDoBanco);

                setJaFoiRecusado(influenciador.jaRecusado)
            })
        }
        obterAnunciantes();
    }, [])

    async function editarInfluenciador(novosDados: any) {
        try {
            const influenciadorDocRef = doc(db, "influenciador", idInfluenciador);

            await updateDoc(influenciadorDocRef, novosDados);
            alert("Dados editados");
        } catch (error) {
            alert("Nao deu certo")
        }
    }

    function concluirCadastro(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        // Verifica se a senha criada é igual ao confirmar senha
        let verificaJaFoiRecusado = false;

        if (jaFoiRecusado) verificaJaFoiRecusado = true;

        if (senha !== confirmarSenha) {
            setSenhaDiferente(true);
            return
        }

        let statusEmailEnviar = false;
        if (verificado) {
            statusEmailEnviar = true
        }

        const influenciador = {
            nomeCompleto,
            email,
            tipoDePessoa,
            redeSocialPrincipal,
            arrobaPrincipal,
            quantidadeDeSeguidores,
            categoria,
            estado,
            cidade,
            data,
            genero,
            telefone,
            senha,
            redesSociais,
            arrobas,
            sobre,
            cep,
            numero,
            complemento,
            logradouro,
            bairro,
            emailVerificado: statusEmailEnviar,
            banco,
            agencia,
            conta,
            tipoDeContaBancaria,
            cpf,
            cnpj,
            codigoDoBanco,
            aceitaPix,
            editouAposRecusado: verificaJaFoiRecusado
        }

        editarInfluenciador(influenciador);
        console.log(influenciador);
        // navigate("/login");
    }
    // subir foto
    const uploadFoto = (event: any) => {
        event.preventDefault();
        const file = event.target[0]?.files[0];
        if (!file) return;

        const storageRef = ref(storage, `influenciador/${email}/fotoDoPerfil/foto`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            "state_changed",
            snapshot => {
            },
            error => {
                alert(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then(url => {
                    setFoto(url);
                    alert(`Arquivo enviado com sucesso!`);
                    navigate("/influenciador/inicio");
                })
            }
        )
    }
    // subir midia kit
    const uploadMidiaKit = (event: any) => {
        event.preventDefault();
        const file = event.target[0]?.files[0];
        if (!file) return;

        const storageRef = ref(storage, `influenciador/${email}/midiakit/midia`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            "state_changed",
            snapshot => {
            },
            error => {
                alert(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then(url => {
                    setMidiaKit(url);
                    alert(`Arquivo enviado com sucesso!`);
                    navigate("/influenciador/inicio");
                })
            }
        )
    }

    const [caminhoFoto, setCaminhoFoto] = useState([]);

    // Lista caminho, edita e fazer download da imagem
    useEffect(() => {
        // Verifica se o email está carregado antes de buscar a imagem
        if (email) {
            const fetchData = async () => {
                try {
                    const storage = getStorage();
                    const listRef = ref(storage, `gs://ism-teste.appspot.com/influenciador/${email}/fotoDoPerfil`);
                    const listaCaminhosPdfs: any = [];

                    const res = await listAll(listRef);
                    res.items.forEach((itemRef) => {
                        listaCaminhosPdfs.push(itemRef.fullPath);
                    });
                    setCaminhoFoto(listaCaminhosPdfs);

                    if (listaCaminhosPdfs.length > 0) {
                        const url = await getDownloadURL(ref(storage, listaCaminhosPdfs[listaCaminhosPdfs.length - 1]));
                        setDownloadFoto(url);
                    }
                } catch (error) {
                    console.error('Erro:', error);
                }
            };
            fetchData();
        }
    }, [email]);

    const [caminhoMidia, setCaminhoMidia] = useState([]);

    // Lista caminho, edita e fazer download do midia kit
    useEffect(() => {
        // Verifica se o email está carregado antes de buscar a imagem
        if (email) {
            const fetchData = async () => {
                try {
                    const storage = getStorage();
                    const listRef = ref(storage, `gs://ism-teste.appspot.com/influenciador/${email}/midiakit`);
                    const listaCaminhosPdfs: any = [];

                    const res = await listAll(listRef);
                    res.items.forEach((itemRef) => {
                        listaCaminhosPdfs.push(itemRef.fullPath);
                    });
                    setCaminhoFoto(listaCaminhosPdfs);

                    if (listaCaminhosPdfs.length > 0) {
                        const url = await getDownloadURL(ref(storage, listaCaminhosPdfs[listaCaminhosPdfs.length - 1]));
                        setDownloadMidiaKit(url);
                    }
                } catch (error) {
                    console.error('Erro:', error);
                }
            };
            fetchData();
        }
    }, [email]);

    function baixarPdf() {
        window.open(downloadMidiaKit);
    }

    async function editarStatus(novosDados: any) {
        try {
            const influenciadorDocRef = doc(db, "influenciador", idInfluenciador);

            await updateDoc(influenciadorDocRef, novosDados);
            alert("Dados editados");
        } catch (error) {
            alert("Nao deu certo")
        }
    }

    async function editarSenha(novosDados: any) {
        try {
            const influenciadorDocRef = doc(db, "influenciador", idInfluenciador);

            await updateDoc(influenciadorDocRef, novosDados);
            // alert("Dados editados");
        } catch (error) {
            console.log("Nao deu certo");

        }
    }

    function confirmarCliqueEmail() {
        if (verificado) {
            const anunciante = {
                emailVerificado: true
            }
            editarStatus(anunciante);
            alert("Email confirmado");
            navigate("/anunciante/inicio")
        } else {
            console.log("Não verificado");
        }
    }

    useEffect(() => {
        const emailConfirmado = () => {
            // console.log(auth.currentUser?.emailVerified);
            console.log("status email", statusEmail);

            if (auth.currentUser?.emailVerified) {
                console.log("verificado");
                setVerificado(true);

            } else {
                console.log("Não verificado");
                setVerificado(false);
            }
        }
        emailConfirmado();

    }, []);

    function mostraSenha() {
        setMostrarSenha(true);
        setSenhaExibida(senha);
    }

    function esconderSenha() {
        setMostrarSenha(false);
        setSenhaExibida("********")
    }

    function quererTrocarSenha() {
        setQuererMudarSenha(!quererMudarSenha)
    }

    const handleLogout = () => {
        autenticaStore.logout(); // Chame o método logout da AutenticaStore
    };

    return (
        <section className={styles.PerfilInfluenciador}>
            <h2> Perfil </h2>

            <section>
                <label> Email </label>
                <div>
                    <EmailIcon fontSize="large" />
                    <label> {emailId} </label>
                </div>

                {!verificado ? <button onClick={async () => {
                    const success = await sendEmailVerification();
                    if (success) {
                        alert('Email de verificação enviado');
                        navigate("/login")

                    }
                }}
                    className="btn btn-warning"> Verificar email </button> : ""}

                {statusEmail === false && verificado ? <button onClick={confirmarCliqueEmail} className="btn btn-info"> Confirmar </button> : ""}

                {statusEmail && verificado ? <p> Email verificado </p> : ""}

                {/* <button className="btn btn-primary" onClick={quererTrocarEmail}> Trocar email </button> */}

                {quererMudarEmail && <><label> Novo email </label>
                    <div>
                        <MarkEmailUnreadIcon fontSize="large" />
                        <input
                            type="text"
                            value={passwordDois}
                            onChange={(e) => setPasswordDois(e.target.value)}
                            placeholder="joao@gmail.com"
                            min={8}
                        />
                    </div>
                </>}

                <label> Senha atual </label>
                <div>
                    <PasswordOutlinedIcon fontSize="large" />
                    <label> {senhaExibida} </label>
                    {!mostrarSenha ? <VisibilityOffOutlinedIcon fontSize="small" onClick={mostraSenha} className={styles.ClickOlho} /> :
                        <VisibilityOutlinedIcon fontSize="small" onClick={esconderSenha} className={styles.ClickOlho}
                        />}

                </div>

                <button className="btn btn-primary" onClick={quererTrocarSenha}> Trocar senha </button>

                {quererMudarSenha && <>
                    <label> Nova senha </label>
                    <div>
                        <HttpsOutlinedIcon fontSize="large" />
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="********"
                            minLength={8}
                        />
                    </div>

                    {password.length < 8 && <p> Mínimo de 8 caractertes </p>}

                    <label> Repetir nova senha </label>
                    <div>
                        <HttpsOutlinedIcon fontSize="large" />
                        <input
                            type="password"
                            value={passwordDois}
                            onChange={(e) => setPasswordDois(e.target.value)}
                            placeholder="********"
                            min={8}
                        />
                    </div>

                    {passwordDois.length < 8 && <p> Mínimo de 8 caractertes </p>}

                    {senhasDiferentes && <p> Senhas diferentes! </p>}

                    <button className="btn btn-info" onClick={async () => {
                        if (password !== passwordDois) {
                            setSenhasDiferentes(true);
                        } else {
                            const success = await updatePassword(password);
                            if (success) {
                                const anunciante = {
                                    senha: password
                                }
                                editarSenha(anunciante);
                                alert('Senha alterada');
                                setSenhaExibida(password);
                                setSenhasDiferentes(false);
                            } else {
                                alert("Entre na plataforma novamente");
                                handleLogout();
                                navigate("/login")
                            }
                        }

                    }}
                        disabled={!password || !passwordDois || password.length < 8 || passwordDois.length < 8}

                    > Editar senha </button> </>}

            </section>
            <form onSubmit={concluirCadastro}>
                <h3> Caso queira editar seus dados: </h3>
                <CampoTexto
                    titulo="Nome completo"
                    valor={nomeCompleto}
                    controle={setNomeCompleto}
                    icone={<PersonIcon fontSize="large" />}
                    placeholder="João da Silva Pereira"
                    minLength={8}
                />
                {!nomeCompleto && <p> Preencha esse campo </p>}
                {nomeCompleto.length < 8 ? <p> Número mínimo de caracteres: 8 </p> : ""}

                {/* <CampoTexto
                    titulo="E-mail"
                    valor={email}
                    controle={setEmail}
                    icone={<EmailIcon fontSize="large" />}
                    placeholder="joaosilva@gmail.com"
                    type="email"
                    minLength={11}
                />
                {!email && <p> Preencha esse campo </p>}
                {email.length < 11 ? <p> Número mínimo de caracteres: 11 </p> : ""} */}

                <CampoEscolha
                    titulo="Tipo de pessoa"
                    lista={listaTipoDePessoa}
                    controle={setTipoDePessoa}
                    icone={<PersonSearchIcon fontSize="large" />}
                    valorPadrao={tipoDePessoa}
                />
                {tipoDePessoa.length === 0 ? <p> Preencha esse campo </p> : ""}
                <CampoEscolha
                    titulo="Rede social principal"
                    lista={listaRedesSociais}
                    controle={setRedeSocialPrincipal}
                    icone={<InstagramIcon fontSize="large" />}
                    valorPadrao={redeSocialPrincipal}
                />
                {redeSocialPrincipal.length === 0 ? <p> Preencha esse campo </p> : ""}

                <CampoTexto
                    titulo="Seu @ principal"
                    valor={arrobaPrincipal}
                    controle={setArrobaPrincipal}
                    icone={<AlternateEmailIcon fontSize="large" />}
                    placeholder="@joaosilva"
                    minLength={3}
                />
                {!arrobaPrincipal && <p> Preencha esse campo </p>}
                {arrobaPrincipal.length < 3 ? <p> Número mínimo de caracteres: 3 </p> : ""}
                <CampoEscolha
                    titulo="Quantidade de seguidores"
                    lista={listaQuantidadeDeSeguidores}
                    controle={setQuantidadeDeSeguidoes}
                    icone={<ScoreIcon fontSize="large" />}
                    valorPadrao={quantidadeDeSeguidores}
                />
                {quantidadeDeSeguidores.length === 0 ? <p> Preencha esse campo </p> : ""}

                <CampoEscolha
                    titulo="Categoria"
                    lista={listaCategorias}
                    controle={setCategoria}
                    icone={<CategoryIcon fontSize="large" />}
                    valorPadrao={categoria}
                />
                {categoria.length === 0 ? <p> Preencha esse campo </p> : ""}

                <CampoEscolha
                    titulo="Estado"
                    lista={nomesEstados}
                    controle={setEstado}
                    icone={<MapIcon fontSize="large" />}
                    valorPadrao={estado}
                />
                {estado.length === 0 ? <p> Preencha esse campo </p> : ""}

                <CampoEscolha
                    titulo="Cidade"
                    lista={nomesDasCidades}
                    controle={setCidade}
                    icone={<LocationOnIcon fontSize="large" />}
                    valorPadrao={cidade}
                />
                {cidade.length === 0 ? <p> Preencha esse campo </p> : ""}

                <CampoTexto
                    titulo="Data de nascimento"
                    valor={data}
                    controle={setData}
                    icone={<PersonIcon fontSize="large" />}
                    type="date"
                />
                {!data && <p> Preencha esse campo </p>}

                <CampoEscolha
                    titulo="Gênero"
                    lista={listaGeneros}
                    controle={setGenero}
                    icone={<SwitchAccountIcon fontSize="large" />}
                    valorPadrao={genero}
                />
                {genero.length === 0 ? <p> Preencha esse campo </p> : ""}

                <CampoTexto
                    titulo="Telefone"
                    valor={mascaraTelefone(telefone)}
                    controle={setTelefone}
                    icone={<SmartphoneOutlinedIcon fontSize="large" />}
                    placeholder="(99) 99999-9999"
                    minLength={15}
                />
                {!telefone && <p> Preencha esse campo </p>}
                {telefone.length < 15 ? <p> Número mínimo de caracteres: 15 </p> : ""}

                {/* <CampoTexto
                    titulo="Senha"
                    valor={senha}
                    controle={(valor) => setSenha(valor)}
                    icone={<LockIcon fontSize="large" />}
                    placeholder="********"
                    type="password"
                    minLength={8}
                />
                {!senha && <p> Preencha esse campo </p>}
                {senha.length < 8 ? <p> Número mínimo de caracteres: 8 </p> : ""}

                <CampoTexto
                    titulo="Confirme sua senha"
                    valor={confirmarSenha}
                    controle={(valor) => setConfirmarSenha(valor)}
                    icone={<LockIcon fontSize="large" />}
                    placeholder="********"
                    type="password"
                    minLength={8}
                />
                {!confirmarSenha && <p> Preencha esse campo </p>}
                {confirmarSenha.length < 8 ? <p> Número mínimo de caracteres: 8 </p> : ""}

                {senhaDiferente && <Alertas controle={setSenhaDiferente} mensagem="Senhas diferentes!" tipoMensagem="warning" />} */}

                <hr />

                <h3> Complete seu cadastro </h3>

                <AreaTextoMenor
                    titulo="Sobre você"
                    valor={sobre}
                    controle={setSobre}
                    colunas={30}
                    linhas={3}
                    placeholder="Sou eclética e animada..."
                    icone={<Person3OutlinedIcon fontSize="large" />}
                />

                {!sobre && <p> Preencha esse campo </p>}
                {sobre.length < 5 ? <p> Número mínimo de caracteres: 5 </p> : ""}

                <CampoTexto
                    titulo="CEP"
                    valor={mascaraCEP(cep)}
                    controle={setCep}
                    icone={<SignpostIcon fontSize="large" />}
                    placeholder="99999-999"
                    minLength={9}
                />

                {!cep && <p> Preencha esse campo </p>}
                {cep.length < 9 ? <p> Número mínimo de caracteres: 9 </p> : ""}

                <CampoTexto
                    titulo="Número"
                    valor={numero}
                    controle={setNumero}
                    icone={<SixtyFpsIcon fontSize="large" />}
                    placeholder="136"
                    minLength={1}
                />

                {!numero && <p> Preencha esse campo </p>}

                <CampoTexto
                    titulo="Complemento"
                    valor={complemento}
                    controle={setComplemento}
                    icone={<WrapTextIcon fontSize="large" />}
                    placeholder="apartamento 807"
                    minLength={3}
                />

                {!complemento && <p> Preencha esse campo </p>}
                {complemento.length < 3 ? <p> Número mínimo de caracteres: 3 </p> : ""}

                <CampoTexto
                    titulo="Logradouro"
                    valor={logradouro}
                    controle={setLogradouro}
                    icone={<EditRoadIcon fontSize="large" />}
                    placeholder="Rua José Maria"
                    minLength={5}
                />

                {!logradouro && <p> Preencha esse campo </p>}
                {logradouro.length < 5 ? <p> Número mínimo de caracteres: 5 </p> : ""}

                <CampoTexto
                    titulo="Bairro"
                    valor={bairro}
                    controle={setBairro}
                    icone={<MapsHomeWorkIcon fontSize="large" />}
                    placeholder="Centro"
                    minLength={3}
                />

                {!bairro && <p> Preencha esse campo </p>}
                {bairro.length < 3 ? <p> Número mínimo de caracteres: 3 </p> : ""}

                <hr />

                <h3> Seus dados bancários </h3>

                <CampoTexto
                    titulo="Banco"
                    valor={banco}
                    controle={setBanco}
                    icone={<AccountBalanceOutlinedIcon fontSize="large" />}
                    placeholder="Itaú"
                    minLength={2}
                />

                {!banco && <p> Preencha esse campo </p>}
                {banco.length < 2 ? <p> Número mínimo de caracteres: 2 </p> : ""}

                <CampoTexto
                    titulo="Agência"
                    valor={agencia}
                    controle={setAgencia}
                    icone={<CommentBankOutlinedIcon fontSize="large" />}
                    placeholder="00000"
                    minLength={2}
                />

                {!agencia && <p> Preencha esse campo </p>}
                {agencia.length < 2 ? <p> Número mínimo de caracteres: 2 </p> : ""}

                <CampoTexto
                    titulo="Conta"
                    valor={conta}
                    controle={setConta}
                    icone={<AddCardOutlinedIcon fontSize="large" />}
                    placeholder="00000000"
                    minLength={4}
                />

                {!conta && <p> Preencha esse campo </p>}
                {conta.length < 4 ? <p> Número mínimo de caracteres: 4 </p> : ""}

                <CampoEscolha
                    titulo="Tipo de conta"
                    lista={listaTipoDeContaBancario}
                    controle={setTipoDeContaBancaria}
                    icone={<SwitchAccountIcon fontSize="large" />}
                    valorPadrao={tipoDeContaBancaria ? tipoDeContaBancaria : "Corrente ou Poupança"}
                />
                {tipoDeContaBancaria.length === 0 ? <p> Preencha esse campo </p> : ""}

                {tipoDePessoa === "Pessoa física" ? <> <CampoTexto
                    titulo="CPF"
                    valor={mascaraCpf(cpf)}
                    controle={setCpf}
                    icone={<PinIcon fontSize="large" />}
                    placeholder="000.000.000-00"
                    minLength={14}
                />

                    {!cpf && <p> Preencha esse campo </p>}
                    {cpf.length < 14 ? <p> Número mínimo de caracteres: 14 </p> : ""} </> : <> <CampoTexto
                        titulo="CNPJ"
                        valor={mascaraCnpj(cnpj)}
                        controle={setCnpj}
                        icone={<PinIcon fontSize="large" />}
                        placeholder="99.999.999/9999-99"
                        minLength={17}
                    />

                    {!cnpj && <p> Preencha esse campo </p>}
                    {cnpj.length < 17 ? <p> Número mínimo de caracteres: 17 </p> : ""}</>}

                <CampoSwitch 
                    titulo={`Aceita receber pix nesse ${tipoDePessoa === "Pessoa física" ? "CPF" : "CNPJ"}`}
                    controle={setAceitaPix}
                    valor={aceitaPix}               
                />

                <CampoTexto
                    titulo="Código do banco"
                    valor={codigoDoBanco}
                    controle={setCodigoDoBanco}
                    icone={<MoneyIcon fontSize="large" />}
                    placeholder="0000"
                    minLength={1}
                />

                {!codigoDoBanco && <p> Preencha esse campo </p>}            

                <div className={styles.BotoesNavegação}>
                    <button className="btn btn-secondary" disabled={tipoDeContaBancaria.length === 0}>
                        Concluir
                    </button>
                </div>
            </form>

            <form onSubmit={uploadFoto}>
                <h3> Sua foto de perfil </h3>
                {/* <img src={downloadFoto} alt="foto de perfil" />               */}
                {downloadFoto ? <img src={downloadFoto} alt="foto de perfil" /> : <img src={pessoa} alt="foto de perfil" />}
                <CampoTexto
                    titulo="Insira sua foto"
                    valor={foto}
                    controle={setFoto}
                    type="file"
                    icone={<ImageSearchOutlinedIcon fontSize="large" />}
                />

                {/*{caminhoPdf.map(pdf => (
                    <p> {pdf} </p>
                ))}*/}

                {/* <img src={downloadFoto} alt="" /> */}

                <button className="btn btn-success">
                    Enviar
                </button>
            </form>

            <form onSubmit={uploadMidiaKit} className={styles.FormPdf}>
                <h3> Insira seu mídia kit </h3>

                <CampoTexto
                    titulo="Insira seu arquivo"
                    valor={midiaKit}
                    controle={setMidiaKit}
                    type="file"
                    icone={<FileCopyOutlinedIcon fontSize="large" />}
                />

                <button className="btn btn-success">
                    Enviar
                </button>

                <button onClick={baixarPdf} className="btn btn-primary"> Visualizar mídia kit </button>
            </form>
        </section>
    );
}