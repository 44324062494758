import aceitoStore from "../../../stores/aceito";
import UsuarioNaoAceitoInfluenciador from "../../UsuarioNaoAceito/UsuarioNaoAceitoInfluenciador";
import styles from "./Fatura.module.css";
import FiltroFaturas from "./FiltroFaturas";


export default function Fatura() {
    // verifica se usuário foi aceito na plataforma
    const statusPlataforma = aceitoStore.getStatus();

    return (
        <>
            {statusPlataforma === "aceito" ? <section className={styles.Fatura}>
                <h2> Fatura </h2>

                <FiltroFaturas />

            </section> : <UsuarioNaoAceitoInfluenciador />}

        </>
    )
}