import { Outlet } from "react-router-dom";
import MenuPainel from "../../../components/MenuPainel";
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
export default function PaginaBaseRotaPrivadaInfluenciador() {
    return(
        <>
            <MenuPainel
                // Início
                painel="Painel influenciador" 
                toInicio="/influenciador/inicio"
                // Campanhas
                camapanhasUm="Campanhas disponíveis"
                campanhasDois=""
                campanhasTres="Minhas campanhas"
                campanhasQuatro="Campanhas finalizadas"
                toCampanhaUm="/influenciador/campanhas-disponiveis"
                toCampanhaDois=""                
                // Redes sociais
                iconeRedesSociais={<AlternateEmailIcon fontSize="large"/>}
                textoRedesSocias="Redes sociais"                  
                // Tipo de conta
                ehAnunciante={false}   
            />
                <Outlet />
        </>
    )
}