import BotaoSubmit from "../BotaoSubmit";
import CampoEscolha from "../CampoEscolha";
import styles from "./FiltroPequeno.module.css";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import { useEffect, useState } from "react";
import TabelaDois from "../TabelaDois";
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import { listaDeStatusAtDes } from "../../utils/listaDeStatusAtDes";
import { useLocation } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore/lite";
import { db } from "../../db/banco";
import IChatPropostas from "../../types/ICharPropostas";
import autenticaStore from "../../stores/autentica.store";

interface FiltroPequenoProps {
    temSegundaTabela?: boolean,
    filtroPequeno?: boolean
}

export default function FiltroPequeno({ temSegundaTabela, filtroPequeno }: FiltroPequenoProps) {
    // obtem o email(serve como id) do anunciante
    const email = autenticaStore.getEmail();
    // recebe e controla as campanhas
    const [chatPropostaEspera, setChatPropostaEspera] = useState<IChatPropostas[]>([]);
    const [chatPropostaEsperaAux, setChatPropostaEsperaAux] = useState<IChatPropostas[]>([]);
    const [chatPropostasAceitas, setChatPropostasAceitas] = useState<IChatPropostas[]>([]);
    const [chatPropostasAceitasAux, setChatPropostasAceitasAux] = useState<IChatPropostas[]>([]);
    // Fake db
    const listaCampanhasFake = ['Riot', 'Cola-cola', 'Nintendo'];
    // listas
    const [listaEspera, setListaEspera] = useState([""]);
    const [listaOutros, setListaOutros] = useState([""]);
    // Filtro um
    const [campanhaCima, setCampanhaCima] = useState("");
    //Filtro dois
    const [campanhaBaixo, setCampanhaBaixo] = useState("");
    const [switchstatus, setSwitchStatus] = useState("");
    // Mudar o texto de acordo com o link
    const localizacao = useLocation();
    let texto = ""
    let to = ""
    if (localizacao.pathname === "/anunciante/aguardando-confirmacao") {
        texto = "Finalizar trabalho"
        to = "/anunciante/finalizar-trabalho"
    } else {
        texto = "Ver chat"
        to = "/anunciante/propostas-anunciante"
    }

    function filtrarCima(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        let resultadoFiltrado: IChatPropostas[] = [...chatPropostaEsperaAux]; // Cria uma cópia do array original

        if (campanhaCima !== "Todas") {
            resultadoFiltrado = resultadoFiltrado.filter(nome => nome.nomeCampanha === campanhaCima);
        } else {
            resultadoFiltrado = chatPropostaEsperaAux
        }
        setChatPropostaEspera(resultadoFiltrado)
        
    }

    function filtrarCBaixo(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        
        let resultadoFiltrado: IChatPropostas[] = [...chatPropostasAceitasAux]; // Cria uma cópia do array original

        if (campanhaBaixo !== "Todas") {
            resultadoFiltrado = resultadoFiltrado.filter(nome => nome.nomeCampanha === campanhaBaixo);
        } else {
            resultadoFiltrado = chatPropostaEsperaAux
        }
        setChatPropostasAceitas(resultadoFiltrado)
        
    }

    // banco de dados
    const useCollectionRef = collection(db, "chatPropostas");
    // puxar dados
    useEffect(() => {
        const obterChat = async () => {
            // pega o chat proposta certo
            const dataBD = await getDocs(useCollectionRef);
            const todosChamados: IChatPropostas[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id } as IChatPropostas))

            

            const somenteDesseAnunciante: IChatPropostas[] = todosChamados.filter(somente => somente.emailAnunciante === email);


            const emEsperaChamados: IChatPropostas[] = somenteDesseAnunciante.filter(emEspera => emEspera.statusProposta === "espera");
            setChatPropostaEspera(emEsperaChamados);
            setChatPropostaEsperaAux(emEsperaChamados)

            let lista: any = ["Todas"]
            emEsperaChamados.map(espera => {
                lista.push(espera.nomeCampanha);
            })
            setListaEspera(lista)

            const ativoChamados: IChatPropostas[] = somenteDesseAnunciante.filter(ativos => ativos.statusProposta !== "espera");
            setChatPropostasAceitas(ativoChamados)
            setChatPropostasAceitasAux(ativoChamados)
            let listaa: any = ["Todas"]
            ativoChamados.map(ativo => {
                listaa.push(ativo.nomeCampanha);
            })
            setListaOutros(listaa);
        }
        obterChat();

    }, []);


    return (
        <>
            <form className={styles.FiltroPequeno} onSubmit={filtrarCima}>
                <CampoEscolha
                    titulo="Campanhas dispóniveis"
                    lista={listaEspera}
                    icone={<CampaignOutlinedIcon fontSize="large" />}
                    controle={setCampanhaCima}
                    valorPadrao="Escolha a campanha"
                />
                <BotaoSubmit classe="btn btn-secondary">
                    <SearchOutlinedIcon fontSize="large" />
                    Pesquisar
                </BotaoSubmit>
            </form>

            <TabelaDois acao={texto} to={to} chatPropostas={chatPropostaEspera} />

            <>
                <h2> Influenciadores em campanha </h2>
                <form className={styles.FiltroPequeno} onSubmit={filtrarCBaixo}>
                    <CampoEscolha
                        titulo="Campanhas dispóniveis"
                        lista={listaOutros}
                        icone={<CampaignOutlinedIcon fontSize="large" />}
                        controle={setCampanhaBaixo}
                        valorPadrao="Escolha a campanha"
                    />
                    {/* <CampoEscolha
                        titulo="Status"
                        lista={listaDeStatusAtDes}
                        icone={<PauseCircleOutlineOutlinedIcon fontSize="large" />}
                        controle={setSwitchStatus}
                        valorPadrao="Determine o status"
                    /> */}

                    <BotaoSubmit classe="btn btn-secondary">
                        <SearchOutlinedIcon fontSize="large" />
                        Pesquisar
                    </BotaoSubmit>
                </form>

                <TabelaDois temStatus acao={texto} to={to} chatPropostas={chatPropostasAceitas} />
            </>
        </>
    )
}