import styles from "./Rodape.module.css";
import { Link } from "react-router-dom";
import icone from "./icone.png";
import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';
import ArticleIcon from '@mui/icons-material/Article';

export default function Rodape() {
    return (
        <footer className={styles.Rodape}>
            <img src={icone} alt="Pássaro, logo da ISM" />
            <ul>
                <li>
                    <Link to="/faq"> Faq </Link>                    
                </li>
                <li>
                    <Link to="/politica-e-privacidade"> Política & Privacidade </Link>
                </li>
                <li>
                    <Link to="/termos-de-uso"> Termos de uso </Link>
                </li>
            </ul>
            <div>
                <ul>
                    <a href="mailto:contato@influenciesuamarca.com.br">
                        <li>
                            <EmailIcon />
                            <address>  contato@influenciesuamarca.com.br  </address>
                        </li>
                    </a>

                    <a href="https://www.instagram.com/influenciesuamarca" target="_blank" rel="noreferrer">
                        <li>
                            <InstagramIcon />
                            <address> Instagram </address>
                        </li>
                    </a>
                    <li>
                        <ArticleIcon />
                        CNPJ: 15.369.584/0001-44
                    </li>
                </ul>
            </div>
        </footer>
    )
}