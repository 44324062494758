import styles from "./Remuneracao.module.css";
import { listaItemRemuneracao } from "./listaItemRemuneracao";

export default function Remuneracao() {
    return (
        <main className={styles.Remuneracao} id="remuneracao">
            <h2> Formas de remuneração </h2>
            {listaItemRemuneracao.map(itemRemuneracao => (
                <section key={itemRemuneracao.id}>
                    <div>
                        <img src={itemRemuneracao.image} alt={itemRemuneracao.alt} className={styles.LeftImg} />
                    </div>
                    <aside>
                        <h3> {itemRemuneracao.label} </h3>
                        <p> {itemRemuneracao.text} </p>
                    </aside>
                </section>
            ))}

        </main>
    );
}