import { useLocation, useNavigate } from "react-router-dom";
import styles from "./EstatisticasDeCampanha.module.css";
import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore/lite";
import { Pagination, Stack } from "@mui/material";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { TextField as T, Autocomplete } from '@mui/material';
import EventNoteIcon from '@mui/icons-material/EventNote';
import PersonIcon from '@mui/icons-material/Person';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ChoiceField from "../../../../../components/ChoiceField";
import { db } from "../../../../../db/banco";
import aceitoStore from "../../../../../stores/aceito";
import autenticaStore from "../../../../../stores/autentica.store";
import IAnunciante from "../../../../../types/IAnunciante";
import ICriarCampanha from "../../../../../types/ICriarCampanha";
import IInfluenciador from "../../../../../types/IInfluenciador";
import IPostback from "../../../../../types/IPostback";
import { listaFiltroEstatisticasDeCampanha, listaDeFiltroAgrupamentoEstatisticasDeCampanha } from "../../../../../utils/listas";
import { autocompleteStyles } from "../../../../../utils/styles";
import UsuarioNaoAceitoAnunciante from "../../../../UsuarioNaoAceito/UsuarioNaoAceitoAnunciante";
import TextField from "../../../../../components/TextField";

export default function EstatisticasDeCModerador() {
    // navitate
    const navitate = useNavigate();
    // verifica se usuário foi aceito na plataforma
    const statusPlataforma = aceitoStore.getStatus();
    // Pegar parametro da página
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const parametro = params.get("id"); // Parâmetro da página
    // recebe o anunciante
    const [anunciante, setAnunciante] = useState<IAnunciante[]>([]);
    // recebe a campanha
    const [campanha, setCampanha] = useState<ICriarCampanha[]>([]);
    // Recebe os postbacks
    const [postback, setPostback] = useState<IPostback[]>([]);
    const [postbackAux, setPostbackAux] = useState<IPostback[]>([]);
    const [postbackFull, setPostbackFull] = useState<IPostback[]>([]);
    const [postbackAgrupadoInflu, setPostbackAgrupadoInflu] = useState<IPostback[]>([]);
    // Recebe os influenciadores
    const [influenciadores, setInfluenciadores] = useState<IInfluenciador[]>([]);
    const email = autenticaStore.getEmail();
    // Total
    const [totalReal, setTotalReal] = useState(0);
    const [totalDolar, setTotalDolar] = useState(0);
    const [totalEuro, setTotalEuro] = useState(0);
    // Data
    const [mesAtual, setMesAtual] = useState(0);
    const [dataInicial, setDataInicial] = useState("");
    const [dataFinal, setDataFinal] = useState("");
    const [primeiroDiaMes, setPrimeiroDiaMes] = useState('');
    const [ultimoDiaMes, setUltimoDiaMes] = useState('');
    // Agrupar
    const [agrupar, setAgrupar] = useState("transação");
    // Filtro para agrupar
    const [filtroAgrupar, setFiltroAgrupar] = useState("");
    // Filtros
    const [filtrarPor, setFiltrarPor] = useState("");
    const [filtroPorInfluenciador, setFiltroPorInfluenciador] = useState("");
    // Abrir fechar filtros e filtro
    const [containerFiltro, setContainerFiltro] = useState(false);

    // banco de dados
    const useCollectionRefAnunciante = collection(db, "anunciante");
    // obtem o anunciante
    useEffect(() => {
        const obterUsuario = async () => {
            const dataBD = await getDocs(useCollectionRefAnunciante);
            const todosAnunciantes: IAnunciante[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as IAnunciante);
            const anuncianteEncontrado = todosAnunciantes.filter(achou => achou.email === email);
            setAnunciante(anuncianteEncontrado);
        };
        obterUsuario();
    }, [])

    // banco de dados
    const useCollectionRefCriarCampanha = collection(db, "criarCampanha");
    // obtem o anunciante
    useEffect(() => {
        const obterCampanha = async () => {
            const dataBD = await getDocs(useCollectionRefCriarCampanha);
            const todosAnunciantes: ICriarCampanha[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as ICriarCampanha);
            const campanhaEncontrada = todosAnunciantes.filter(achou => achou.codigoActionpay === parametro);
            setCampanha(campanhaEncontrada);
        };
        obterCampanha();
    }, [])

    // banco de dados
    const useCollectionRefPostack = collection(db, "postback");    

    const converterStringParaData = (dataStr: string): Date => {
        const [dia, mes, ano] = dataStr.split('/').map(Number);
        return new Date(ano, mes - 1, dia); // Subtraindo 1 do mês porque o objeto Date usa zero-index para meses
      };

    useEffect(() => {
        const obterPostacks = async () => {
            const dataBD = await getDocs(useCollectionRefPostack);
            const todosPostback: IPostback[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as IPostback);
            const postbacksEncontrados = todosPostback.filter(achou => achou.campanha === parametro);

            setPostbackFull(postbacksEncontrados);
            setPostbackAux(postbacksEncontrados)


            // if (mesAtual) {
            //     const postackDesseMes = postbacksEncontrados.filter(porEsseMes => porEsseMes.dataDoClickParametro[4] === String(mesAtual));
            //     setPostback(postackDesseMes);
            //     setPostbackAux(postackDesseMes);
            // }



            if (primeiroDiaMes && ultimoDiaMes) {
                const converterPrimeiro = converterStringParaData(primeiroDiaMes)
                const converterUltimo = converterStringParaData(ultimoDiaMes)
                const filtroAoAbrir: IPostback[] = postbacksEncontrados.filter(porData => {
                    const recebePor = porData.dataDoClickParametro;
                    const converterReceber = converterStringParaData(recebePor)
                    return converterReceber >= converterPrimeiro && converterReceber <= converterUltimo;
                })
                setPostback(filtroAoAbrir);
            }

            // Agrupar por influenciador

            const influSet = new Set<number>();
            const postbackFiltrado = postbacksEncontrados.filter(postback => {
                if (!influSet.has(postback.influ)) {
                    influSet.add(postback.influ);
                    return true;
                }
                return false;
            });

            setPostbackAgrupadoInflu(postbackFiltrado);

            

        };
        obterPostacks();
    }, [primeiroDiaMes, ultimoDiaMes]);

    // banco de dados
    const useCollectionRefInfluenciador = collection(db, "influenciador");
    // obtem o anunciante
    useEffect(() => {
        const obterPostacks = async () => {
            const dataBD = await getDocs(useCollectionRefInfluenciador);
            const todosInfluenciadores: IInfluenciador[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as IInfluenciador);

            setInfluenciadores(todosInfluenciadores);
        };
        obterPostacks();
    }, [])

    // Paginação
    const [pagina, setPagina] = useState(1);

    const handleChangePagina = (event: any, value: any) => {
        setPagina(value);
    };

    const linhasPorPagina = 15;
    const startIndex = (pagina - 1) * linhasPorPagina;
    const endIndex = startIndex + linhasPorPagina;
    const postbackPaginados = postback.slice(startIndex, endIndex);

    function irParaPerfilInfluenciador(id: string) {
        navitate(`/anunciante/perfil-influenciador?id=${id}`)
    }

    // Calcula o total nas 3 moedas
    useEffect(() => {        
        let recebeTotalReal = 0;
        let recebeTotalDolar = 0;
        let recebeTotalEuro = 0;
        if (postback.length > 0) {            
            postback.map(atributosPostack => {
                if (atributosPostack.moeda === 'real') {
                    recebeTotalReal += atributosPostack.valor;
                    setTotalReal(recebeTotalReal);
                }
                if (atributosPostack.moeda === 'dolar') {
                    recebeTotalDolar += atributosPostack.valor;
                    setTotalDolar(recebeTotalDolar);
                }
                if (atributosPostack.moeda === 'euro') {
                    recebeTotalEuro += atributosPostack.valor;
                    setTotalEuro(recebeTotalEuro);
                }
            })
        }
    }, [postback])

    // Função para agrupar os postbacks por influenciador
    const agruparPorInfluenciador = () => {
        // Inicializa um objeto para armazenar o resultado do agrupamento
        const result: { [key: number]: IPostback[] } = {};

        // Itera sobre o array postback e agrupa por influenciador
        postback.forEach((currentValue) => {
            const influenciador: number = currentValue.influ;

            // Inicializa o array se não existir para esse influenciador
            if (!result[influenciador]) {
                result[influenciador] = [];
            }

            // Adiciona o objeto ao array correspondente ao influenciador
            result[influenciador].push(currentValue);
        });

        // Retorna o resultado do agrupamento
        return result;
    };

    // Função para agrupar os postbacks por status
    const agruparPorStatus = () => {
        const result: { [key: string]: IPostback[] } = {};

        postback.forEach((currentValue) => {
            const status: string = currentValue.status;

            if (!result[status]) {
                result[status] = [];
            }

            result[status].push(currentValue);
        });

        return result;
    };

    // Função para atualizar o estado de postback com o resultado do agrupamento
    const atualizarPostback = (qualFiltro: string) => {
        if (qualFiltro === "influenciador") {
            const resultadoAgrupado = agruparPorInfluenciador();
            // Converte o objeto resultadoAgrupado em um array de postbacks
            const novosPostbacks = Object.values(resultadoAgrupado).flat();
            setPostback(novosPostbacks);
        } else if (qualFiltro === 'status') {
            const resultadoAgrupado = agruparPorStatus();
            const novosPostbacks = Object.values(resultadoAgrupado).flat();
            setPostback(novosPostbacks);
        }
    };

    // Datas
    useEffect(() => {
        // mês atual
        const dataDeHoje = new Date();
        const dataCompletaHoje = dataDeHoje.toLocaleDateString();

        const recebeMesAtual = dataDeHoje.getMonth() + 1;
        setMesAtual(recebeMesAtual);

        // Primeiro dia do mês
        const primeiroDia = new Date(dataDeHoje.getFullYear(), dataDeHoje.getMonth(), 1);
        const primeiroDiaFormatado = primeiroDia.toISOString().split('T')[0];

        const primeiroDiaAjeitado = primeiroDiaFormatado.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1');

        // Último dia do mês
        const ultimoDia = new Date(dataDeHoje.getFullYear(), dataDeHoje.getMonth() + 1, 0);
        const ultimoDiaFormatado = ultimoDia.toISOString().split('T')[0];

        const ultimoDiaAjeitado = ultimoDiaFormatado.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1');

        setPrimeiroDiaMes(primeiroDiaAjeitado);
        setUltimoDiaMes(ultimoDiaAjeitado);

        if (!dataInicial && !dataFinal) {
            setDataInicial(primeiroDiaFormatado);
            setDataFinal(ultimoDiaFormatado);
        }
    }, [])

    // Filtros

    function filtrar() {
        if (dataInicial && dataFinal) {
            const dataInicialConvertida = dataInicial.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1');
            const dataFinalConvertida = dataFinal.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1');

            let resultadoFiltrado: IPostback[] = [...postbackAux];

            


            if (filtroPorInfluenciador !== "") { // com influenciador
                const converteInicial = converterStringParaData(dataInicialConvertida);
                const converteFinal = converterStringParaData(dataFinalConvertida);
                resultadoFiltrado = resultadoFiltrado.filter((porData) => {
                    const dataDoClick = porData.dataDoClickParametro;
                    const converteClick = converterStringParaData(dataDoClick)
                    return converteClick >= converteInicial && converteClick <= converteFinal && porData.nomeInfluenciador === filtroPorInfluenciador;
                });
                setPostback(resultadoFiltrado);
                setPostbackFull(resultadoFiltrado)
            } else { // sem influenciador    
                const converteInicial = converterStringParaData(dataInicialConvertida);
                const converteFinal = converterStringParaData(dataFinalConvertida);
                const recebeResultado: IPostback[] = resultadoFiltrado = resultadoFiltrado.filter((porData) => {
                    const dataDoClick = porData.dataDoClickParametro;
                    const converteClick = converterStringParaData(dataDoClick)
                    return converteClick >= converteInicial && converteClick <= converteFinal;
                });
                

                setPostback(recebeResultado);
                setPostbackFull(recebeResultado)
            }

            setPostback(resultadoFiltrado);
            setPostbackFull(resultadoFiltrado)

        } else {
            alert("Confira os campos de dados, algum pode está em branco!")
        }
    }

    // Agrupar

    function mudarAgrupamento() {
        setAgrupar(filtroAgrupar)
    }

    // Controlar filtros

    function abrirFecharContainerFiltro() {
        setContainerFiltro(!containerFiltro);
    }

    // auto complete


    return (
        <>
            {statusPlataforma === "aceito" ? <><section className={styles.EstatisticasDeCampanha}>
                <h2> Estatísticas de campanha - Moderador </h2>
                {anunciante.map(anun => (
                    <h3> Olá, {anun.nomeCompleto}! </h3>
                ))}
                {campanha.map(cam => (
                    <h3> Aqui estão informações sobre sua campanha: {cam.nomeDaCampanha} </h3>
                ))}

                <h6> Filtros </h6>
                <aside>
                    <div>
                        <TextField
                            title=""
                            control={setDataInicial}
                            value={dataInicial ? dataInicial : primeiroDiaMes}
                            placeholder="25/10/2023"
                            type="date"
                            icon={<EventNoteIcon fontSize="large" />}
                        />

                        <div>
                            <TextField
                                title=""
                                control={setDataFinal}
                                value={dataFinal ? dataFinal : ultimoDiaMes}
                                placeholder="25/10/2023"
                                type="date"
                                icon={<EventNoteIcon fontSize="large" />}
                            />

                            <button className="btn btn-info" onClick={filtrar}> Filtrar </button>

                            <span>  <button className="btn btn-secondary" onClick={abrirFecharContainerFiltro}> <AddCircleIcon /> {!containerFiltro ? "Adicionar filtros" : "Esconder filtros"} </button> </span>
                        </div>
                    </div>

                </aside>

                {containerFiltro && <aside>
                    <div>
                        <ChoiceField
                            title=""
                            control={setFiltrarPor}
                            icon={<FilterAltIcon fontSize="large" />}
                            list={listaFiltroEstatisticasDeCampanha}
                            defaultValue="Escolha"
                        />

                        {filtrarPor === "influenciador" ?
                            <>
                                <Autocomplete
                                    sx={autocompleteStyles}
                                    freeSolo
                                    options={postbackAgrupadoInflu.map((option) => option.nomeInfluenciador)}
                                    onInputChange={(event, newInputValue) => {
                                        setFiltroPorInfluenciador(newInputValue);
                                    }}
                                    renderInput={(params) => (
                                        <T

                                            {...params}
                                            label="Nome do Influenciador"
                                            placeholder="Digite o nome do influenciador"
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    <>
                                                        <PersonIcon fontSize="large" />
                                                        {params.InputProps.startAdornment}
                                                    </>
                                                ),
                                            }}
                                        />
                                    )}
                                />

                            </> : ""}
                    </div>
                </aside>}

                <aside>
                    <div>
                        <ChoiceField
                            title="Agrupar por"
                            control={setFiltroAgrupar}
                            defaultValue="Escolha"
                            icon={<Diversity2Icon fontSize="large" />}
                            list={listaDeFiltroAgrupamentoEstatisticasDeCampanha}
                        />

                        <button className="btn btn-primary" onClick={mudarAgrupamento} disabled={!filtroAgrupar}> Agrupar </button>
                    </div>
                </aside>

                {postbackPaginados.length > 0 ?
                    <Stack spacing={2}>
                        {agrupar === "transação" ? <table className="table table-light table-striped text-center">
                            <thead>
                                <tr>
                                    <th scope="col" className="text-white"> ID da transação </th>
                                    <th scope="col" className="text-white"> Data/hora </th>
                                    <th scope="col" className="text-white"> Influenciador <ArrowDropDownIcon onClick={() => atualizarPostback("influenciador")} className="filtroInterno" /> </th>
                                    <th scope="col" className="text-white"> Valor </th>
                                    <th scope="col" className="text-white"> Status <ArrowDropDownIcon onClick={() => atualizarPostback("status")} className="filtroInterno" /> </th>
                                </tr>
                            </thead>
                            <tbody>
                                {postback.map((atributosPostack, index) => (
                                    <tr style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }} key={atributosPostack.transactionID}>
                                        <td> {atributosPostack.transactionID} </td>
                                        <td> {atributosPostack.dataDoClickParametro} às {atributosPostack.horaClick} </td>
                                        <td>
                                            {(() => {
                                                const influenciador = influenciadores.find(
                                                    influ => influ.codigoActionpayInfluenciador == atributosPostack.influ

                                                );
                                                return influenciador ? <> <span className={styles.NomeInfluenciador}> {influenciador.nomeCompleto} </span> <PersonIcon onClick={() => irParaPerfilInfluenciador(influenciador.id)} className={styles.IconeInfluenciador} /> </> : 'Nome não encontrado';
                                            })()}
                                        </td>
                                        <td> {atributosPostack.moeda === "real" ? "R$" : atributosPostack.moeda === "euro" ? "€" : "$"} {atributosPostack.valor.toFixed(2)} </td>
                                        <td > <span className={`${atributosPostack.status === "aprovado" ? "ativoMobile" : atributosPostack.status === "processando" ? "processando" : "desativadoMobile"}`}> {atributosPostack.status} </span> </td>
                                    </tr>
                                ))}

                            </tbody>
                        </table> : agrupar === "influenciador" ? <table className="table table-light table-striped text-center">
                            <thead>
                                <tr>
                                    <th scope="col" className="text-white"> Influenciador </th>
                                    <th scope="col" className="text-white"> Aprovado </th>
                                    <th scope="col" className="text-white"> Processando </th>
                                </tr>
                            </thead>
                            <tbody>
                                {postbackAgrupadoInflu.map((atributosPostack, index) => (
                                    <tr style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }} key={atributosPostack.transactionID}>
                                        <td className={styles.Influenciador}>
                                            {(() => {
                                                const influenciador = influenciadores.find(
                                                    influ => influ.codigoActionpayInfluenciador == atributosPostack.influ

                                                );
                                                return influenciador ? <> <span className={styles.NomeInfluenciador}> {influenciador.nomeCompleto} </span> <PersonIcon onClick={() => irParaPerfilInfluenciador(influenciador.id)} className={styles.IconeInfluenciador} /> </> : 'Nome não encontrado';
                                            })()}
                                        </td>
                                        <td>
                                            {(() => {
                                                const totalValor = postbackFull
                                                    .filter(post => post.influ === atributosPostack.influ && post.status === "aprovado" && post.moeda === "real")
                                                    .reduce((acc, postMap) => acc + postMap.valor, 0);

                                                if (totalValor !== 0) {
                                                    return <span>R$ {totalValor} </span>;
                                                } else {
                                                    return null; // Retorna null ou qualquer outro componente vazio se totalValor for zero
                                                }
                                            })()}

                                            {(() => {
                                                const totalValor = postbackFull
                                                    .filter(post => post.influ === atributosPostack.influ && post.status === "aprovado" && post.moeda === "dolar")
                                                    .reduce((acc, postMap) => acc + postMap.valor, 0);

                                                if (totalValor !== 0) {
                                                    return <span>$ {totalValor} </span>;
                                                } else {
                                                    return null; // Retorna null ou qualquer outro componente vazio se totalValor for zero
                                                }
                                            })()}

                                            {(() => {
                                                const totalValor = postbackFull
                                                    .filter(post => post.influ === atributosPostack.influ && post.status === "aprovado" && post.moeda === "euro")
                                                    .reduce((acc, postMap) => acc + postMap.valor, 0);

                                                if (totalValor !== 0) {
                                                    return <span>€ {totalValor} </span>;
                                                } else {
                                                    return null; // Retorna null ou qualquer outro componente vazio se totalValor for zero
                                                }
                                            })()}
                                        </td>
                                        <td>
                                            {(() => {
                                                const totalValor = postbackFull
                                                    .filter(post => post.influ === atributosPostack.influ && post.status === "processando" && post.moeda === "real")
                                                    .reduce((acc, postMap) => acc + postMap.valor, 0);

                                                if (totalValor !== 0) {
                                                    return <span>R$ {totalValor} </span>;
                                                } else {
                                                    return null; // Retorna null ou qualquer outro componente vazio se totalValor for zero
                                                }
                                            })()}

                                            {(() => {
                                                const totalValor = postbackFull
                                                    .filter(post => post.influ === atributosPostack.influ && post.status === "processando" && post.moeda === "dolar")
                                                    .reduce((acc, postMap) => acc + postMap.valor, 0);

                                                if (totalValor !== 0) {
                                                    return <span>$ {totalValor} </span>;
                                                } else {
                                                    return null; // Retorna null ou qualquer outro componente vazio se totalValor for zero
                                                }
                                            })()}

                                            {(() => {
                                                const totalValor = postbackFull
                                                    .filter(post => post.influ === atributosPostack.influ && post.status === "processando" && post.moeda === "euro")
                                                    .reduce((acc, postMap) => acc + postMap.valor, 0);

                                                if (totalValor !== 0) {
                                                    return <span>€ {totalValor} </span>;
                                                } else {
                                                    return null; // Retorna null ou qualquer outro componente vazio se totalValor for zero
                                                }
                                            })()}
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        </table> : ""}
                        <Pagination
                            count={Math.ceil(postback.length / linhasPorPagina)}
                            page={pagina}
                            onChange={handleChangePagina}
                        />

                    </Stack> :
                    <p> Nenhuma transação para essa campanha no momento. </p>}

                <h5> Total </h5>
                <Stack spacing={2}>

                    <table className="table table-light table-striped text-center">
                        <thead>
                            <tr>
                                <th scope="col" className="text-white"> Total em real </th>
                                <th scope="col" className="text-white"> Total em dólar </th>
                                <th scope="col" className="text-white"> Total em euro </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td> R$ {totalReal} </td>
                                <td> $ {totalDolar} </td>
                                <td> € {totalEuro} </td>
                            </tr>


                        </tbody>
                    </table>

                </Stack>

            </section >





            </> : <UsuarioNaoAceitoAnunciante />
            }
        </>
    )
}