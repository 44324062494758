import abracadabra from "./image/abracadabra.png";
import aliexpress from "./image/aliexpress.png";
import caneco from "./image/caneco.png";
import dabellehair from "./image/dabellehair.png";
import francisca from "./image/francisca.png";
import noshstudio from "./image/noshstudio.png";
import virtualjoias from "./image/virtualjoias.png";

export const listaImagensParceiros = [
    {
        id: 1,
        image: abracadabra,
        alt: "Logo da marca escrito Abra Cadabra"
    },
    {
        id: 2,
        image: aliexpress,
        alt: "Logo da marca escrito Alie Express"
    },
    {
        id: 3,
        image: caneco,
        alt: "Uma logo com uma caneca sorrindo"
    },
    {
        id: 4,
        image: dabellehair,
        alt: "Logo da marca escrito Dabelle Hair"
    },
    {
        id: 5,
        image: francisca,
        alt: "Logo da marca escrito Francisca"
    },
    {
        id: 6,
        image: noshstudio,
        alt: "Logo da marca escrito Nosh! Studio"
    },
    {
        id: 7,
        image: virtualjoias,
        alt: "Logo da marca escrito Virtual Joiais"
    },
]