import styles from "./InicioSuporte.module.css";

export default function InicioSuporte() {
    return (
        <section className={styles.InicioSuporte}>
            <h2> Início </h2>
            <p> Bem vindo de volta, Suporte! </p>

            <p> Painel de suporte com gráficos e demais dados, na versão 2.0 em breve </p>
        </section>
    )
}