import { useLocation, useNavigate } from "react-router-dom";
import styles from "./LeituraInfluenciador.module.css";
import { useEffect, useState } from "react";
import { collection, doc, getDocs, updateDoc } from "firebase/firestore/lite";
import { getDownloadURL, getStorage, listAll, ref } from "firebase/storage";
import EmailIcon from '@mui/icons-material/Email';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import InstagramIcon from '@mui/icons-material/Instagram';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import EightKIcon from '@mui/icons-material/EightK';
import CategoryIcon from '@mui/icons-material/Category';
import MapIcon from '@mui/icons-material/Map';
import ForkLeftIcon from '@mui/icons-material/ForkLeft';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PortraitIcon from '@mui/icons-material/Portrait';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import pessoa from "../../../../../Image/usuario.png";
import InfoIcon from '@mui/icons-material/Info';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import DirectionsIcon from '@mui/icons-material/Directions';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';

import DomainIcon from '@mui/icons-material/Domain';
import IAnunciante from "../../../../../types/IAnunciante";
import { db } from "../../../../../db/banco";
import axios from "axios";
import guardaPaginaAnterior from "../../../../../stores/paginaAnterior";

export default function LeituraAnuncianteMod() {    
    const navitate = useNavigate();
    // Pegar parametro da página
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const parametro = params.get("id");
    const isRecusado = params.get("tp"); 
    // pagina
    const paginaParametro = params.get("pg");
    // recebe e controla o influenciador
    const [anunciante, setAnunciante] = useState<IAnunciante[]>([]);
    const [email, setEmail] = useState("");
    const [statusEmail, setSstatusEmail] = useState(false);
    // puxar foto de perfil
    const [foto, setFoto] = useState("");
    const [downloadFoto, setDownloadFoto] = useState("");
    // Midia kit
    const [downloadMidiaKit, setDownloadMidiaKit] = useState("");
    // atualizar tabela
    const [atualiza, setAtualiza] = useState(false);

    // salva a Página
    useEffect(() => {
        if (paginaParametro) guardaPaginaAnterior.salvarPagina(paginaParametro, false);        
    }, [paginaParametro])

    // banco de dados
    const useCollectionRef = collection(db, "anunciante");
    // puxar dados
    useEffect(() => {
        const obterAnunciantes = async () => {
            const dataBD = await getDocs(useCollectionRef);
            const todosAnunciantes: IAnunciante[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as IAnunciante)
            const filtrado: IAnunciante[] = todosAnunciantes.filter(filtrado => filtrado.id === parametro);
            setAnunciante(filtrado);
            console.log("influenciador:", filtrado);
            filtrado.map(anun => {
                setEmail(anun.email);
                setSstatusEmail(anun.emailVerificado);
            })
        }
        obterAnunciantes();
    }, [])


    // aceitar/recusar anunciante
    async function aceitarAnunciante(id: string, email: string, nome: string) {
        const influenciador = {
            aceito: "aceito"
        }
        await editarAnunciante(id, influenciador);
        enviarEmail(email, nome);
        alert("Anunciante aceito");
    };

    async function recusarAnunciante(id: string, email: string, nome: string) {
        const influenciador = {
            aceito: "recusado",
            jaRecusado: true
        }
        await editarAnunciante(id, influenciador);
        enviarEmailRecusar(email, nome);
        alert("Anunciante recusado");
    };

    const enviarEmail = async (email: string, nome: string) => {
        try {
            const response = await axios.post(
                "https://us-central1-ism-teste.cloudfunctions.net/enviarEmail",
                {
                    destinatario: email,
                    assunto: "Seu cadastro foi aprovado!!",
                    mensagem: `Oii, ${nome} 💜\n\n` +

                        "Seu perfil foi aprovado pela nossa equipe!.\n\n" +

                        "Agora você está pronto para criar campanhas e negociar com influenciadores pela nossa\n" +
                        "plataforma.\n\n" +

                        "Vamos nessa!?"
                }
            );

            const { id } = response.data;
            return id;
        } catch (error) {
            console.log(error);
        }
    };

    const enviarEmailRecusar = async (email: string, nome: string) => {
        try {
            const response = await axios.post(
                "https://us-central1-ism-teste.cloudfunctions.net/enviarEmail",
                {
                    destinatario: email,
                    assunto: "Você tem uma nova mensagem de Influencie Sua Marca",
                    mensagem: "Poxa, não foi dessa vez.. 🙁\n\n" +

                        `Oi, ${nome}! \n\n` + +
                        "Amamos a sua marca, mas infelizmente a gente não consegue liberar o acesso à\n" +
                        "plataforma pra você nesse momento.\n\n" +

                        "Mas, não desanime! Dê uma olhadinha no nosso FAQ que temos várias informações\n" +
                        "importantes por lá.\n\n" +

                        "Ver FAQ: influenciesuamarca.com.br/faq\n\n" +

                        "Obrigado por querer influenciar conosco!\n" +
                        "Equipe ISM"
                }
            );

            const { id } = response.data;
            return id;
        } catch (error) {
            console.log(error);
        }
    };

    async function editarAnunciante(id: string, novosDados: any) {
        try {
            if (id) {
                const chamadoDocRef = doc(db, "anunciante", id);
                await updateDoc(chamadoDocRef, novosDados);                
                if (isRecusado !== "rec") navitate("/moderador/aceitar-usuarios");
                else navitate("/moderador/usuarios-recusados");
                setAtualiza(!atualiza);
            }
        } catch (error) {
            alert("Nao deu certo")
        }
    }
    return (
        <section className={styles.LeituraInfluenciador}>
            {anunciante.map(anun => (
                <>
                    <h2> Perfil de {anun.nomeCompleto} </h2> 
                    <div className="d-flex justify-content-center justify-content-md-start">
                        <button className="btn btn-success me-md-1 mb-1 mb-md-0" onClick={() => aceitarAnunciante(anun.id, anun.email, anun.nomeCompleto)}>Aceitar</button>
                        {isRecusado !== "rec" && <button className="btn btn-danger" onClick={() => recusarAnunciante(anun.id, anun.email, anun.nomeCompleto)}>Recusar</button>}
                    </div>
                </>
            ))}
            <form>
                {downloadFoto ? <img src={downloadFoto} alt="foto de perfil" /> : <img src={pessoa} alt="foto de perfil" />}
                {anunciante.map(anun => (
                    <>
                        <div>
                            <EmailIcon fontSize="large" />
                            <label> <strong> Email: </strong> {anun.email} </label>

                        </div>
                        {statusEmail === true ? <p> Email verificado </p> : <p> Email não verificado </p>}

                        <div>
                            <PersonSearchIcon fontSize="large" />
                            <label> <strong> CNPJ: </strong> {anun.cnpj} </label>
                        </div>

                        <div>
                            <DomainIcon fontSize="large" />
                            <label> <strong> Razão Social: </strong> {anun.razaoSocial} </label>
                        </div>

                        <div>
                            <SmartphoneIcon fontSize="large" />
                            <label> <strong> Telefone: </strong> {anun.telefone} </label>
                        </div>

                        <div>
                            <NorthEastIcon fontSize="large" />
                            <label> <strong> CEP: </strong> {anun.cep} </label>
                        </div>

                        <div>
                            <LooksOneIcon fontSize="large" />
                            <label> <strong> Número: </strong> {anun.numero} </label>
                        </div>

                        <div>
                            <EditLocationIcon fontSize="large" />
                            <label> <strong> Complemento: </strong> {anun.complemento} </label>
                        </div>

                        <div>
                            <DirectionsIcon fontSize="large" />
                            <label> <strong> Logradouro: </strong> {anun.logradouro} </label>
                        </div>

                        <div>
                            <MapsHomeWorkIcon fontSize="large" />
                            <label> <strong> Bairro: </strong> {anun.bairro} </label>
                        </div>

                        <div>
                            <MapIcon fontSize="large" />
                            <label> <strong> Estado: </strong> {anun.estado} </label>
                        </div>

                        <div>
                            <ForkLeftIcon fontSize="large" />
                            <label> <strong> Cidade: </strong> {anun.cidade} </label>
                        </div>
                    </>
                ))}
            </form>
        </section>
    )
}