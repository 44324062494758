import aceitoStore from "../../../stores/aceito";
import UsuarioNaoAceitoAnunciante from "../../UsuarioNaoAceito/UsuarioNaoAceitoAnunciante";
import EnvPropostasAnunciante from "./EnvPropostasAnunciante";
import styles from "./PropostasAnunciante.module.css";

export default function PropostasAnunciante() {
    // verifica se usuário foi aceito na plataforma
    const statusPlataforma = aceitoStore.getStatus(); 

    return(
        <>
            {statusPlataforma === "aceito" ? <section className={styles.PropostasAnunciante}>
            <h2> Propostas Anunciante </h2>

            <EnvPropostasAnunciante />
        </section> : <UsuarioNaoAceitoAnunciante />}
        </>
    )
}