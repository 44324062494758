import aceitoStore from "../../../stores/aceito";
import UsuarioNaoAceitoInfluenciador from "../../UsuarioNaoAceito/UsuarioNaoAceitoInfluenciador";
import styles from "./CampanhasFinalizadas.module.css";
import TabelaFinalizadas from "./TabelaFinalizadas";

export default function CampanhasFinalizadas() {
    // verifica se usuário foi aceito na plataforma
    const statusPlataforma = aceitoStore.getStatus()
    
    return (
        <>
            {statusPlataforma === "aceito" ? <section className={styles.CampanhasFinalizadas}>
            <h2> Campanhas finalizadas </h2>

            <TabelaFinalizadas />
        </section> : <UsuarioNaoAceitoInfluenciador />}
        </>
    );
}