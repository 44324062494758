import aceitoStore from "../../../stores/aceito";
import UsuarioNaoAceitoInfluenciador from "../../UsuarioNaoAceito/UsuarioNaoAceitoInfluenciador";
import EnviaSuaProposta from "./EnvieSuaProposta";
import styles from "./Propostas.module.css";

export default function Propostas() {
    // verifica se usuário foi aceito na plataforma
    const statusPlataforma = aceitoStore.getStatus();

    return(
       <>
        {statusPlataforma === "aceito" ?  <section className={styles.Propostas}>
            <h2> Propostas Influenciador </h2>

            <EnviaSuaProposta />
        </section> : <UsuarioNaoAceitoInfluenciador />}
       </>
    )
}