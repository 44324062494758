import ReactApexChart from "react-apexcharts";
import styles from "./GerenciamentoDeInfluenciadores.module.css";

interface GraficoProps {
    ativos: number,
    analise: number,
    finalizados: number
}

export default function GerenciamentoDeInfluenciadores({ativos, analise, finalizados}: GraficoProps) {
    const categorias = ['Ativos', 'Análise', 'Finalizados'];
    const valores = [ativos, analise, finalizados];

    const options = {
        labels: categorias.map((categoria, index) => `${categoria}: ${valores[index]}`),
        colors: ['#008FFB', '#00E396', '#FEB019'],
        legend: {
            show: true,
        },
    };
    const series = valores

    return (
        <div className={styles.GerenciamentoDeInfluenciadores}>
            <h3> Gerenciamento de influciadores(as) </h3>
            {/* <Grafico /> */}
            <ReactApexChart
                options={options}
                series={series}
                type="pie"
                width="300"
            />
            {/* <p> Total: 29 </p> */}
            <h3> Gráfico de influenciadores </h3>
        </div>
    )
}