import styles from "./CampoTexto.module.css";
import { ReactNode } from 'react';

interface CampoTextoProps {
    titulo?: string,
    valor: string | string[],
    controle: (value: string) => void,
    icone: ReactNode,
    placeholder?: string,
    type?: string,
    minLength?: number
}

export default function CampoTexto({ titulo, valor, controle, icone, placeholder, type,  minLength}: CampoTextoProps) {
    return (
        <div className={styles.CampoTexto}>
            <label> {titulo} </label>
            <div>
                {icone}
                <input
                    value={valor}
                    onChange={(e) => controle(e.target.value)}
                    placeholder={placeholder}
                    type={type}
                    required
                    minLength={minLength}
                />
            </div>
        </div>
    );
}