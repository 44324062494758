import { useEffect, useState } from "react";
import CampoTexto from "../../../components/CampoTexto";
import styles from "./RedesSociais.module.css";
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import CampoEscolha from "../../../components/CampoEscolha";
import { listaRedesSociais } from "../../../utils/listaRedesSociais";
import InstagramIcon from '@mui/icons-material/Instagram';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import BotaoSubmit from "../../../components/BotaoSubmit";
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import { collection, doc, getDocs, updateDoc } from "firebase/firestore/lite";
import { db } from "../../../db/banco";
import autenticaStore from "../../../stores/autentica.store";
import IInfluenciador from "../../../types/IInfluenciador";
import aceitoStore from "../../../stores/aceito";
import UsuarioNaoAceitoInfluenciador from "../../UsuarioNaoAceito/UsuarioNaoAceitoInfluenciador";

export default function RedesSociais() {
    // verifica se usuário foi aceito na plataforma
    const statusPlataforma = aceitoStore.getStatus();
    const email = autenticaStore.getEmail();
    const [idInfluenciador, setIdInfluenciador] = useState("")
    const [redeSocialPrincipal, setRedeSocialPrincipal] = useState("");
    const [arrobaPrincipal, setArrobaPrincipal] = useState("");
    // Controlar novos campos de rede social
    const [novaRedeSocial, setNovaRedeSocial] = useState(0);
    // Novas redes e @
    const [novaRedeUm, setNovaRedeUm] = useState("");
    const [novoArrobaUm, setNovoArrobaUm] = useState("")

    const [novaRedeDois, setNovaRedeDois] = useState("");
    const [novoArrobaDois, setNovoArrobaDois] = useState("");

    const [novaRedeTres, setNovaRedeTres] = useState("");
    const [novoArrobaTres, setNovoArrobaTres] = useState("");

    const [novaRedeQuatro, setNovaRedeQuatro] = useState("");
    const [novoArrobaQuatro, setNovoArrobaQuatro] = useState("");

    const [novaRedeCinco, setNovaRedeCinco] = useState("");
    const [novoArrobaCinco, setNovoArrobaCinco] = useState("");

    const [novaRedeSeis, setNovaRedeSeis] = useState("");
    const [novoArrobaSeis, setNovoArrobaSeis] = useState("");

    const [novaRedeSete, setNovaRedeSete] = useState("");
    const [novoArrobaSete, setNovoArrobaSete] = useState("");
    // Aux pare receber redes sociais e arrobas
    const [todasRedesSociais, setTodasRedesSociais] = useState("");
    const [todosArrobas, setTodosArrobas] = useState("");

    //
    const [influenciador, setInfluenciador] = useState<IInfluenciador[]>([]);

    // banco de dados
    const useCollectionRef = collection(db, "influenciador");

    // Puxa dados
    useEffect(() => {
        const obterInfluenciadores = async () => {
            try {
                
    
                const dataBD = await getDocs(useCollectionRef);
                const todoInfluenciadores = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as IInfluenciador);
                const filtrado = todoInfluenciadores.filter(filtrado => filtrado.email === email);
                setInfluenciador(filtrado);
    
                filtrado.forEach(influenciador => {
                    setIdInfluenciador(influenciador.id);
    
                    if ("redeSocialPrincipal" in influenciador) setRedeSocialPrincipal(influenciador.redeSocialPrincipal as string);
                    if ("arrobaPrincipal" in influenciador) setArrobaPrincipal(influenciador.arrobaPrincipal as string);
                    if ("redesSociais" in influenciador) {
                        setTodasRedesSociais(influenciador.redesSociais as any);
    
                        for (let i = 0; i < influenciador.redesSociais.length; i++) {
                            switch (i) {
                                case 0:
                                    setNovaRedeUm(influenciador.redesSociais[i]);
                                    break;
                                case 1:
                                    setNovaRedeDois(influenciador.redesSociais[i]);
                                    break;
                                case 2:
                                    setNovaRedeTres(influenciador.redesSociais[i]);
                                    break;
                                case 3:
                                    setNovaRedeQuatro(influenciador.redesSociais[i]);
                                    break;
                                case 4:
                                    setNovaRedeCinco(influenciador.redesSociais[i]);
                                    break;
                                case 5:
                                    setNovaRedeSeis(influenciador.redesSociais[i]);
                                    break;
                                case 6:
                                    setNovaRedeSete(influenciador.redesSociais[i]);
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
    
                    if ("arrobas" in influenciador) {
                        setTodosArrobas(influenciador.arrobas as any);
    
                        for (let i = 0; i < influenciador.arrobas.length; i++) {
                            switch (i) {
                                case 0:
                                    setNovoArrobaUm(influenciador.arrobas[i]);
                                    break;
                                case 1:
                                    setNovoArrobaDois(influenciador.arrobas[i]);
                                    break;
                                case 2:
                                    setNovoArrobaTres(influenciador.arrobas[i]);
                                    break;
                                case 3:
                                    setNovoArrobaQuatro(influenciador.arrobas[i]);
                                    break;
                                case 4:
                                    setNovoArrobaCinco(influenciador.arrobas[i]);
                                    break;
                                case 5:
                                    setNovoArrobaSeis(influenciador.arrobas[i]);
                                    break;
                                case 6:
                                    setNovoArrobaSete(influenciador.arrobas[i]);
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
    
                    for (let i = 0; i < influenciador.redesSociais.length; i++) {
                        if (influenciador.redesSociais[i]) {
                            setNovaRedeSocial(i + 1);
                        }
                    }
                });
            } catch (error) {
                // Trate o erro aqui, se necessário
                console.error("Ocorreu um erro ao obter influenciadores:", error);
            }
        };
    
        obterInfluenciadores();
    }, []);
    



    function criarNovaRedeSocial() {
        setNovaRedeSocial(novaRedeSocial + 1)
    }

    async function editarRedes(novosDados: any) {
        try {
            if (idInfluenciador) {
                const influenciadorDocRef = doc(db, "influenciador", idInfluenciador);

                await updateDoc(influenciadorDocRef, novosDados);
                alert("Dados editados");
            }
        } catch (error) {
            alert("Nao deu certo")
        }
    }

    function atualizarRedes(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();


        const influenciador = {
            redeSocialPrincipal,
            arrobaPrincipal,
            redesSociais: [novaRedeUm, novaRedeDois, novaRedeTres, novaRedeQuatro, novaRedeCinco, novaRedeSeis, novaRedeSete],
            arrobas: [novoArrobaUm, novoArrobaDois, novoArrobaTres, novoArrobaQuatro, novoArrobaCinco, novoArrobaSeis, novoArrobaSete],
        }

        editarRedes(influenciador)
    }

    return (
        <>
            {statusPlataforma === "aceito" ? <section className={styles.RedesSociais}>
            <h2> Redes sociais </h2>

            <form onSubmit={atualizarRedes}>
                <h3> Sua rede social principal </h3>
                <CampoEscolha
                    titulo="Seu link para sua rede principal"
                    lista={listaRedesSociais}
                    controle={setRedeSocialPrincipal}
                    icone={<InstagramIcon fontSize="large" />}
                    valorPadrao={redeSocialPrincipal}
                />
                <CampoTexto
                    titulo="Seu link principal"
                    valor={arrobaPrincipal}
                    controle={setArrobaPrincipal}
                    icone={<AlternateEmailIcon fontSize="large" />}
                    placeholder="@joaosilva"
                />



                {novaRedeSocial > 0 && <> <CampoEscolha
                    titulo="Rede social"
                    lista={listaRedesSociais}
                    controle={setNovaRedeUm}
                    icone={<InstagramIcon fontSize="large" />}
                    valorPadrao={novaRedeUm}
                />
                    <CampoTexto
                        titulo="Seu link"
                        valor={novoArrobaUm}
                        controle={setNovoArrobaUm}
                        icone={<AlternateEmailIcon fontSize="large" />}
                        placeholder="@joaosilva"
                    /> </>}

                {novaRedeSocial > 1 && <> <CampoEscolha
                    titulo="Rede social"
                    lista={listaRedesSociais}
                    controle={setNovaRedeDois}
                    icone={<InstagramIcon fontSize="large" />}
                    valorPadrao={novaRedeDois}
                />
                    <CampoTexto
                        titulo="Seu link"
                        valor={novoArrobaDois}
                        controle={setNovoArrobaDois}
                        icone={<AlternateEmailIcon fontSize="large" />}
                        placeholder="@joaosilva"
                    /> </>}

                {novaRedeSocial > 2 && <> <CampoEscolha
                    titulo="Rede social"
                    lista={listaRedesSociais}
                    controle={setNovaRedeTres}
                    icone={<InstagramIcon fontSize="large" />}
                    valorPadrao={novaRedeTres}
                />
                    <CampoTexto
                        titulo="Seu link"
                        valor={novoArrobaTres}
                        controle={setNovoArrobaTres}
                        icone={<AlternateEmailIcon fontSize="large" />}
                        placeholder="@joaosilva"
                    /> </>}

                {novaRedeSocial > 3 && <> <CampoEscolha
                    titulo="Rede social"
                    lista={listaRedesSociais}
                    controle={setNovaRedeQuatro}
                    icone={<InstagramIcon fontSize="large" />}
                    valorPadrao={novaRedeQuatro}
                />
                    <CampoTexto
                        titulo="Seu link"
                        valor={novoArrobaQuatro}
                        controle={setNovoArrobaQuatro}
                        icone={<AlternateEmailIcon fontSize="large" />}
                        placeholder="@joaosilva"
                    /> </>}

                {novaRedeSocial > 4 && <> <CampoEscolha
                    titulo="Rede social"
                    lista={listaRedesSociais}
                    controle={setNovaRedeCinco}
                    icone={<InstagramIcon fontSize="large" />}
                    valorPadrao={novaRedeCinco}
                />
                    <CampoTexto
                        titulo="Seu link"
                        valor={novoArrobaCinco}
                        controle={setNovoArrobaCinco}
                        icone={<AlternateEmailIcon fontSize="large" />}
                        placeholder="@joaosilva"
                    /> </>}

                {novaRedeSocial > 5 && <> <CampoEscolha
                    titulo="Rede social"
                    lista={listaRedesSociais}
                    controle={setNovaRedeSeis}
                    icone={<InstagramIcon fontSize="large" />}
                    valorPadrao={novaRedeSeis}
                />
                    <CampoTexto
                        titulo="Seu link"
                        valor={novoArrobaSeis}
                        controle={setNovoArrobaSeis}
                        icone={<AlternateEmailIcon fontSize="large" />}
                        placeholder="@joaosilva"
                    /> </>}

                {novaRedeSocial > 6 && <> <CampoEscolha
                    titulo="Rede social"
                    lista={listaRedesSociais}
                    controle={setNovaRedeSete}
                    icone={<InstagramIcon fontSize="large" />}
                    valorPadrao={novaRedeSete}
                />
                    <CampoTexto
                        titulo="Seu link"
                        valor={novoArrobaSete}
                        controle={setNovoArrobaSete}
                        icone={<AlternateEmailIcon fontSize="large" />}
                        placeholder="@joaosilva"
                    /> </>}

                <h3> Deseja adicionar nova rede social? </h3>
                
                <AddCircleOutlineOutlinedIcon fontSize="large" onClick={criarNovaRedeSocial} />

                <BotaoSubmit classe="btn btn-secondary">
                    <RefreshOutlinedIcon fontSize="medium" />
                    Atualizar
                </BotaoSubmit>
            </form>
        </section> : <UsuarioNaoAceitoInfluenciador />}
        </>
    )
}