import axios from "axios";

export const enviarIntrucoesCampanhaComPerformance = async (email: string, nomeCompleto: string) => {
    try {
        const response = await axios.post(
            "https://us-central1-ism-teste.cloudfunctions.net/enviarEmail",
            {
                destinatario: email,
                assunto: `Olá, ${nomeCompleto}!`,
                mensagem: "Instruções campanha performance/modelo híbrido"                    
            }
        );

        const { id } = response.data;
        return id;
    } catch (error) {
        console.log(error);
    }
};