import { useEffect, useState } from "react";
import "./TabelaDois.css";
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import { Link, useLocation, useNavigate } from "react-router-dom";
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import IChatPropostas from "../../types/ICharPropostas";

// primeira tabela

interface TabelaDoisProps {
    temStatus?: boolean,
    acao: string,
    to?: any,
    chatPropostas?: IChatPropostas[]
}

export default function TabelaDois({ temStatus, acao, to, chatPropostas }: TabelaDoisProps) {
    const navigate = useNavigate();
    const localizacao = useLocation();
    const [idChat, setIdChat] = useState("");    

    const [tabela, setTabela] = useState(true);

    console.log("na tabela", to);

    useEffect(() => {
        function largura() {
            if (window.innerWidth < 650) setTabela(false);
        }
        largura();
    }, [])

    useEffect(() => {
        console.log("dentro da tabela:", chatPropostas);
        chatPropostas?.map(cha => {
            setIdChat(cha.id);
        })
        console.log("id chat:", idChat);
        
        
    }, [])   

    

    return (
        <>            
            <h2> Resultado da pesquisa</h2>
            {tabela ? <table className="table table-light table-striped text-center ">
                <thead>
                    <tr>
                        {temStatus && <th scope="col" className="text-white">Status</th>}
                        <th scope="col" className="text-white">Campanha </th>
                        <th scope="col" className="text-white">Nome</th>
                        <th scope="col" className="text-white">Criado em:</th>
                        <th scope="col" className="text-white">Chat</th>
                    </tr>
                </thead>
                {chatPropostas?.map(cel => (                    
                    <tbody>
                        <tr>
                            {temStatus && <td> <p > {cel.statusProposta} </p> </td>}
                            <td> {cel.nomeCampanha} </td>
                            <td> <Link to={`/anunciante/perfil-influenciador?id=${cel.idInfluenciador}`} className="linkTabela"> {cel.nomeInfluenciador} </Link> </td>
                            <td> {cel.criadoEm.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1')} </td>
                            <td>
                                <div className="divAcoesTabela">
                                    {localizacao.pathname === "/anunciante/influenciadores-em-analise" ?
                                        <Link to={`/anunciante/chat-proposta?id=${cel.id}&tp=anun`}> <CommentOutlinedIcon /> {acao} </Link> :
                                        // <button className="btn btn-info" onClick={irParaChat}> <CommentOutlinedIcon /> </button>:
                                        <Link to={to}> <WorkOutlineOutlinedIcon /> {acao} </Link>
                                    }
                                </div>
                            </td>
                        </tr>
                    </tbody>
                ))}
            </table> : <div>
                {chatPropostas?.map(cel => (
                    <ul className="lista">
                        <li className="Itemlista"> . </li>
                        <li className="Itemlista"> <strong> Status: </strong> <span className={`${cel.statusProposta === "ativo" ? "ativoMobile" : "desativadoMobile"}`}> {cel.statusProposta} </span> </li>
                        <li className="Itemlista"> <strong> Campanha: </strong> {cel.nomeCampanha} </li>
                        <li className="Itemlista"> <strong> Nome: </strong> {cel.nomeInfluenciador} </li>
                        <li className="Itemlista"> <strong> Criado em: </strong> {cel.criadoEm.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1')} </li>
                        <li className="Itemlista">
                            <div className="divAcoes">
                                <strong> Ações: </strong>
                                <div className="ContainerIconeMobile">
                                    <Link to={to}> <CommentOutlinedIcon /> {acao} </Link>
                                </div>
                            </div>
                        </li>
                    </ul>
                ))}
            </div>}
        </>
    )
}