import { useNavigate } from "react-router-dom";
import styles from "./MeusChamadosAnunciante.module.css";
import { collection, getDocs } from "firebase/firestore/lite";
import { db } from "../../../db/banco";
import { useEffect, useState } from "react";
import autenticaStore from "../../../stores/autentica.store";
import IChamado from "../../../types/IChamado";
import aceitoStore from "../../../stores/aceito";
import UsuarioNaoAceitoAnunciante from "../../UsuarioNaoAceito/UsuarioNaoAceitoAnunciante";

export default function MeusChamadosAnunciante() {
    // verifica se usuário foi aceito na plataforma
    const statusPlataforma = aceitoStore.getStatus();
    // obtem o email(serve como id) do anunciante
    const email = autenticaStore.getEmail();
    const navigate = useNavigate();
    
    const [meusChamados, setMeusChamados] = useState<IChamado[]>([]);

    function irParaChat(id: any) {
        navigate(`/anunciante/chat-suporte?id=${id}`);
    }

    const useCollectionRef = collection(db, "abrirChamado");
    // puxar dados
    useEffect(() => {
        const obterChamados = async () => {
            const dataBD = await getDocs(useCollectionRef);
            const todosChamados: IChamado[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id } as IChamado));
            
            const filtradoPorPessoa = todosChamados.filter(chamado => chamado.emailQuemAbriu === email);

            console.log(filtradoPorPessoa);
            setMeusChamados(filtradoPorPessoa);
        }
        obterChamados();

    }, [])

    return (
        <>
            {statusPlataforma === "aceito" ? <section className={styles.MeusChamadosAnunciante}>
            <h2> Meus chamados </h2>

            <table className="table table-light table-striped text-center ">
                <thead>
                    <tr>
                        <th scope="col" className="text-white"> Tipo </th>
                        <th scope="col" className="text-white"> Assunto </th>
                        <th scope="col" className="text-white"> Status </th>
                        <th scope="col" className="text-white"> Ações </th>
                    </tr>
                </thead>

                {meusChamados.map(cel => (
                    <tbody>
                        <tr>
                            <td> {cel.ticket} </td>
                            <td> {cel.assunto} </td>
                            <td className={`${cel.status === "aberto" ? styles.aberto : styles.fechado}`}>
                                {cel.status}
                            </td>
                            <td> <button className="btn btn-primary" onClick={() => irParaChat(cel.id)}> Ver chat </button></td>
                        </tr>
                    </tbody>
                ))}
            </table>

        </section> : <UsuarioNaoAceitoAnunciante />}
        </>
    );
}