import styles from "./CardsPainel.module.css";

interface CardsAnuncianteProps {
    titulo: string,
    valor: number,
    imagem: string,
    dinheiro?: boolean
}

export default function CardsAnunciante({ titulo, valor, imagem, dinheiro }: CardsAnuncianteProps) {
    return (
        <div className={styles.CardsPainel}>
            <img src={imagem} alt="megafone"/>
                <div>
                    <p className="card-text">{titulo}: {dinheiro && "R$"}{valor}</p>
                </div>
        </div>
    );
}