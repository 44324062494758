import hibrido from "./image/hibrido.png";
import performance from "./image/performance.png";
import permuta from "./image/permuta.png";
import publipost from "./image/publipost.png";

export const listaItemRemuneracao = [
    {
        id: 1,
        image: publipost,
        label: 'Publipost',
        alt: 'Mulher mexendo no celular',
        text: 'É o modelo mais clássico e conhecido pelos influencers. Nesse formato o pagamento ao influenciador é realizado por post ou pacotes completos de publicações em uma ou mais mídias, com maior possibilidade de controle de conteúdo por parte do anunciante.',
    },
    {
        id: 2,
        image: performance,
        label: 'Performance',
        alt: 'Uma pessoa analisando dados',
        text: 'Campanhas de performance são aquelas em que o pagamento do influenciador é baseado nos resultados obtidos, como número de vendas, cliques em um link ou leads gerados. Essa modalidade é indicada para influenciadores que buscam aumentar sua renda e têm uma audiência engajada. É fundamental ter uma estratégia clara e objetivos definidos, além de escolher produtos ou serviços relevantes para sua audiência. O modelo de remuneração por performance motiva o influenciador a alcançar melhores resultados e permite que os anunciantes avaliem a eficácia de suas campanhas.'
    },
    {
        id: 3,
        image: hibrido,
        label: 'Modelo Híbrido',
        alt: 'Uma mulher usando o celular para trabalhar',
        text: 'O modelo híbrido é uma combinação dos formatos publipost e performance. Isso significa que o influenciador recebe tanto uma remuneração variável baseada nos resultados obtidos, quanto um cachê fixo por sua participação na campanha. Esse modelo oferece vantagens tanto para o influenciador quanto para o anunciante. O influenciador tem a segurança de receber um pagamento fixo, independentemente dos resultados, e ao mesmo tempo é incentivado a alcançar melhores resultados para aumentar sua remuneração variável.'
    },
    {
        id: 4,
        image: permuta,
        label: 'Permuta',
        alt: 'Uma mulher usando o notebook e mostrando roupas',
        text: 'Nessas campanhas, o influenciador recebe como incentivos para divulgação um ou mais produtos do anunciante que sejam de seu interesse. Esse formato confere uma maior autonomia aos influenciadores em relação ao conteúdo das divulgações.'
    }
];