import styles from "./Alertas.module.css";
import { Alert, AlertColor, Button } from "@mui/material";

interface AlertasProps {
    controle: (value: boolean) => void,
    mensagem: string,
    tipoMensagem: AlertColor
}

export default function Alertas({ controle, mensagem, tipoMensagem }: AlertasProps) {
    function teste() {
        controle(false)
    }
    return (
        <Alert
            className={styles.Alertas}
            severity={tipoMensagem}            
            // action={
            //     <Button color="inherit" size="small" onClick={teste}>
            //         ok.
            //     </Button>
            // }
        >
            { mensagem }
        </Alert>
    )
}