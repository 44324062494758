import styles from "./AreaTexto.module.css";
import { useState, useEffect } from "react";
import JoditEditor from "jodit-react";

interface AreaTextoProps {
    titulo: string,
    valor: string,
    controle: (value: any) => void
}

export default function AreaTexto({ titulo, valor, controle }: AreaTextoProps) {
    const [slidesPerView, setSliderPerView] = useState(400);   

    const configuracao = {
        readonly: false,
        height: 350,
        width: slidesPerView,
        enableDragAndDropFileToEditor: true,
        "uploader": {
            "insertImageAsBase64URI": true
        },
        placeholder: 'Descreva sua campanha ou insira sua arte aqui.',   
        removeButtons: ['image'],
        
    }

    useEffect(() => {
        function largura() {
            if (window.innerWidth < 1000) setSliderPerView(400);
            if (window.innerWidth < 810) setSliderPerView(350);
            if (window.innerWidth < 750) setSliderPerView(320);
        }
        largura();
    }, [])

    return (
        <div className={styles.AreaTexto}>
            <label> {titulo} </label>
            <JoditEditor
                value={valor}
                config={configuracao}
                onBlur={(conteudo) => controle(conteudo)}                                
            />
        </div>
    );
}