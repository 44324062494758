import { collection, getDocs } from "firebase/firestore/lite";
import styles from "./VerPerfilAnunciante.module.css";
import { db } from "../../../../../db/banco";
import { useEffect, useState } from "react";
import IAnunciante from "../../../../../types/IAnunciante";
import { Link, useNavigate } from "react-router-dom";


export default function VerPerfilAnunciante() {
    const navigate = useNavigate();
    // Anunciante
    const [anunciantes, setAnunciantes] = useState<IAnunciante[]>([]);    
    // puxar dados se for Anunciante
    const useCollectionRef = collection(db, "anunciante");
    useEffect(() => {
        const obterAnunciantes = async () => {
            const dataBD = await getDocs(useCollectionRef);
            const todosAnunciantes: IAnunciante[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id } as IAnunciante));
            setAnunciantes(todosAnunciantes);            
        }
        obterAnunciantes();

    }, [])

    function verPerfil(id: string) {
        navigate(`/suporte/ver-anunciante?id=${id}`)
    }

    function verCampanhas(id: string) {
        navigate(`/suporte/ver-campanhas?id=${id}`)
    }
    
    return (
        <section className={styles.VerPerfilAnunciante}>
            <h3> Lista de anunciantes </h3>

            <table className="table table-light table-striped text-center ">
                <thead>
                    <tr>
                        <th scope="col" className="text-white">Nome</th>
                        <th scope="col" className="text-white">Ações</th>
                    </tr>
                </thead>

                {anunciantes.map(cel => (
                    <tbody>
                        <tr>
                            <td> {cel.nomeCompleto} </td>
                            <td>
                                <button className="btn btn-primary m-lg-1" onClick={() => verPerfil(cel.id)}> Perfil </button>
                                <button className="btn btn-secondary" onClick={() => verCampanhas(cel.id)}> Campanhas </button>
                            </td>
                        </tr>
                    </tbody>
                ))}
            </table>

        </section>
    );
}