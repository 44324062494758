import { useNavigate } from "react-router-dom";
import styles from "./UsuarioNaoAceito.module.css";
import logo from "./logo.png";

export default function UsuarioNaoAceitoInfluenciador() {

    const navigate = useNavigate();

    function irParaPerfil() {
        navigate("/influenciador/perfil")
    }

    return(
        <section className={styles.UsuarioNaoAceito}>            
            <img src={logo} alt="Logo da ISM, sendo um pássaro"/>

            <p> Hmmm influenciador, você ainda não pode acessar essa página. 🙁 </p>
            <p> Seu perfil ainda não foi aprovado pela nossa equipe. </p>
            <hr/>
            <p> Mas não se preocupe!  </p>
            <hr/>
            <p> Nossa equipe trabalha rapidamente nas análises de perfil e daqui a alguns dias você terá </p>
            <p> sua resposta. </p>
            <hr/>
            <p> Vamos torcer!! 🤞 </p>
            <hr />

            <button className="btn btn-info" onClick={irParaPerfil}> Ir para perfil </button>            
        </section>
    )
}