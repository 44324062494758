import { useState, useEffect } from "react";
import CampoTexto from "../../../../components/CampoTexto";
import styles from "./InfluenciadorFormulario.module.css"
import BotaoSubmit from "../../../../components/BotaoSubmit";
import CampoEscolha from "../../../../components/CampoEscolha";
import { listaTipoDePessoa } from "../../../../utils/listaTipoDePessoa";
import { listaRedesSociais } from "../../../../utils/listaRedesSociais";
import { listaQuantidadeDeSeguidores } from "../../../../utils/listaQuantidadeDeSeguidores";
import { listaGeneros } from "../../../../utils/listaGerenos";
import { listaCategorias } from "../../../../utils/listaCategorias";
import { mascaraTelefone } from "../../../../utils/mascaras";
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import InstagramIcon from '@mui/icons-material/Instagram';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import ScoreIcon from '@mui/icons-material/Score';
import CategoryIcon from '@mui/icons-material/Category';
import MapIcon from '@mui/icons-material/Map';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import SmartphoneOutlinedIcon from '@mui/icons-material/SmartphoneOutlined';
import LockIcon from '@mui/icons-material/Lock';
import { estados, obterNomesDasCidadesPorEstadoNome } from "../../../../utils/apiIbge";
import Alertas from "../../../../components/Alertas";
// import { v4 as uuidv4 } from 'uuid';
import { addDoc, collection, getDocs } from "firebase/firestore/lite";
import { auth, db } from "../../../../db/banco";
import { useNavigate } from "react-router-dom";
import { useCreateUserWithEmailAndPassword } from 'react-firebase-hooks/auth';
import IInfluenciador from "../../../../types/IInfluenciador";
import { FlashOffRounded } from "@mui/icons-material";
import axios from "axios";

export default function InfluenciadorFormulario() {
    // Autenticar
    const [
        createUserWithEmailAndPassword,
        user,
        loading,
        error
    ] = useCreateUserWithEmailAndPassword(auth);
    // Define por padrao o tipo da conta
    const tipoDaConta = 'Influenciador';
    // Atributos
    const [nomeCompleto, setNomeCompleto] = useState("");
    const [email, setEmail] = useState("");
    const [tipoDePessoa, setTipoDePessoa] = useState("");
    const [redeSocialPrincipal, setRedeSocialPrincipal] = useState("");
    const [arrobaPrincipal, setArroba] = useState("");
    const [quantidadeDeSeguidores, setQuantidadeDeSeguidoes] = useState("");
    const [categoria, setCategoria] = useState("");
    const [estado, setEstado] = useState("");
    const [cidade, setCidade] = useState("");
    // aux para pegar as cidades
    const [nomesDasCidades, setNomesDasCidades] = useState<string[]>([]);
    // Restro dos atributos
    const [data, setData] = useState("");
    const [genero, setGenero] = useState("");
    const [telefone, setTelefone] = useState("");
    const [senha, setSenha] = useState("");
    const [confirmarSenha, setConfirmarSenha] = useState("");
    // Redes sociais e arrobas adicionais
    const [redesSociais, setRedesSociais] = useState([""]);
    const [arrobas, setArrobas] = useState([""]);
    // Controla se as senhas criadas são iguais
    const [senhaDiferente, setSenhaDiferente] = useState(false);
    // Usado para navegar entre rotas
     // Usado para navegar entre rotas
     const navigate = useNavigate();

    // banco de dados
    const useCollectionRef = collection(db, "influenciador");

    const [arrayEmail, setArrayEmail] = useState([""]);
    const [emailJaUsado, setEmailJaUsado] = useState(false)
    // verifica se o email já é cadastrado
    useEffect(() => {
        const obterUsuario = async () => {
            const dataBD = await getDocs(useCollectionRef);
            const todosAnunciantes: IInfluenciador[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as IInfluenciador);            
            const soEmails = todosAnunciantes.map(so => so.email);            
            setArrayEmail(soEmails)           
        };
        obterUsuario();
    }, [])

    const enviarEmail = async () => {
        try {
            const response = await axios.post(
                "https://us-central1-ism-teste.cloudfunctions.net/enviarEmail",
                {
                    destinatario: email,
                    assunto: `Boas vindas, ${nomeCompleto}!`,
                    mensagem: "Estamos mega felizes em poder contar com você para agitar o mercado de influência digital.\n" +
                    "Conte com a gente para influenciar sempre mais.\n" +
                    "Equipe ISM"                    
                }
            );

            const { id } = response.data;
            return id;
        } catch (error) {
            // console.log(error);
        }
    };

    async function cadastrar(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        // Verifica se a senha criada é igual ao confirmar senha
        if (senha !== confirmarSenha) {
            setSenhaDiferente(true);
            return;
        }
        // verifica o email, se já é cadastrado
        if (arrayEmail.includes(email)) {
            setEmailJaUsado(true)
            return;
        }        

        const influenciador = await addDoc(useCollectionRef, {            
            tipoDaConta,
            nomeCompleto,
            email,
            tipoDePessoa,
            redeSocialPrincipal,
            arrobaPrincipal,
            quantidadeDeSeguidores,
            categoria,
            estado,
            cidade,
            data,
            genero,
            telefone,
            senha, 
            redesSociais,
            arrobas,  
            aceito: "espera",
            emailVerificado: false                   
        })
        createUserWithEmailAndPassword(email, senha);
        enviarEmail();
        alert("Conta influenciador criada com sucesso!");        
        navigate("/login");
    }        
    // Puxa a lista de estados pela api do IBGE
    const nomesEstados = estados.map((estado) => estado.nome);
    nomesEstados.sort();
    // Puxa a lista das cidades com a api do IBGE, depois de obter o estado
    useEffect(() => {
        if (estado) {
            obterNomesDasCidadesPorEstadoNome(estado)
                .then(nomesDasCidades => {
                    
                    // Atualize o estado das cidades com os nomes obtidos
                    setCidade('');
                    setNomesDasCidades(nomesDasCidades);
                })
                .catch(error => {
                    console.error('Ocorreu um erro:', error);
                });
        }
    }, [estado]);

    return (
        // Colocar a função cadastrar
        <form className={styles.InfluenciadorFormulario} onSubmit={cadastrar}>
            <CampoTexto
                titulo="Nome completo"
                valor={nomeCompleto}
                controle={setNomeCompleto}
                icone={<PersonIcon fontSize="large" />}
                placeholder="João da Silva Pereira"
                minLength={8}
            />
            {!nomeCompleto && <p> Preencha esse campo </p>}   
            {nomeCompleto.length < 8 ? <p> Número mínimo de caracteres: 8 </p> : ""}

            <CampoTexto
                titulo="E-mail"
                valor={email}
                controle={setEmail}
                icone={<EmailIcon fontSize="large" />}
                placeholder="joaosilva@gmail.com"
                type="email"
                minLength={11}
            />
            {!email && <p> Preencha esse campo </p>}   
            {email.length < 11 ? <p> Número mínimo de caracteres: 11 </p> : ""}

            {emailJaUsado && <Alertas controle={setSenhaDiferente} mensagem="Email já utilizado" tipoMensagem="warning" />}

            <CampoEscolha
                titulo="Tipo de pessoa"
                lista={listaTipoDePessoa}
                controle={setTipoDePessoa}
                icone={<PersonSearchIcon fontSize="large" />}
                valorPadrao="Escolha seu perfil"
            />
            {tipoDePessoa.length === 0 ? <p> Preencha esse campo </p> : ""}  
            <CampoEscolha
                titulo="Rede social principal"
                lista={listaRedesSociais}
                controle={setRedeSocialPrincipal}
                icone={<InstagramIcon fontSize="large" />}
                valorPadrao="Escolha sua rede social"
            />
            {redeSocialPrincipal.length === 0 ? <p> Preencha esse campo </p> : ""}

            <CampoTexto
                titulo="Link para sua rede principal"
                valor={arrobaPrincipal}
                controle={setArroba}
                icone={<AlternateEmailIcon fontSize="large" />}
                placeholder="www.instagram.com/joao"
                minLength={5}
            />
            {!arrobaPrincipal && <p> Preencha esse campo </p>} 
            {arrobaPrincipal.length < 5 ? <p> Número mínimo de caracteres: 5 </p> : ""}
            <CampoEscolha
                titulo="Quantidade de seguidores"
                lista={listaQuantidadeDeSeguidores}
                controle={setQuantidadeDeSeguidoes}
                icone={<ScoreIcon fontSize="large" />}
                valorPadrao="Escolha a quantidade"
            />
            {quantidadeDeSeguidores.length === 0 ? <p> Preencha esse campo </p> : ""}

            <CampoEscolha
                titulo="Categoria"
                lista={listaCategorias}
                controle={setCategoria}
                icone={<CategoryIcon fontSize="large" />}
                valorPadrao="Escolha a categoria"
            />
            {categoria.length === 0 ? <p> Preencha esse campo </p> : ""}

            <CampoEscolha
                titulo="Estado"
                lista={nomesEstados}
                controle={setEstado}
                icone={<MapIcon fontSize="large" />}
                valorPadrao="Escolha seu estado"
            />
            {estado.length === 0 ? <p> Preencha esse campo </p> : ""}

            <CampoEscolha
                titulo="Cidade"
                lista={nomesDasCidades}
                controle={setCidade}
                icone={<LocationOnIcon fontSize="large" />}
                valorPadrao="Escolha sua cidade"
            />            
            {cidade.length === 0 ? <p> Preencha esse campo </p> : ""}

            <CampoTexto
                titulo="Data de nascimento"
                valor={data}
                controle={setData}
                icone={<PersonIcon fontSize="large" />}
                type="date"
            />
            {!data && <p> Preencha esse campo </p>} 

            <CampoEscolha
                titulo="Gênero"
                lista={listaGeneros}
                controle={setGenero}
                icone={<SwitchAccountIcon fontSize="large" />}
                valorPadrao="Escolha seu gênero"
            />
            {genero.length === 0 ? <p> Preencha esse campo </p> : ""}

            <CampoTexto
                titulo="Telefone"
                valor={mascaraTelefone(telefone)}
                controle={setTelefone}
                icone={<SmartphoneOutlinedIcon fontSize="large" />}
                placeholder="(99) 99999-9999"
                minLength={15}
            />
            {!telefone && <p> Preencha esse campo </p>}   
            {telefone.length < 15 ? <p> Número mínimo de caracteres: 15 </p> : ""}

            <CampoTexto
                titulo="Senha"
                valor={senha}
                controle={(valor) => setSenha(valor)}
                icone={<LockIcon fontSize="large" />}
                placeholder="********"
                type="password"
                minLength={8}
            />
            {!senha && <p> Preencha esse campo </p>}   
            {senha.length < 8 ? <p> Número mínimo de caracteres: 8 </p> : ""}

            <CampoTexto
                titulo="Confirme sua senha"
                valor={confirmarSenha}
                controle={(valor) => setConfirmarSenha(valor)}
                icone={<LockIcon fontSize="large" />}
                placeholder="********"
                type="password"
                minLength={8}
            />
            {!confirmarSenha && <p> Preencha esse campo </p>}   
            {confirmarSenha.length < 8 ? <p> Número mínimo de caracteres: 8 </p> : ""}
            

            {senhaDiferente && <Alertas controle={setSenhaDiferente} mensagem="Senhas diferentes!" tipoMensagem="warning" />}

            <div className={styles.BotoesNavegação}>
                <BotaoSubmit classe="btn btn-outline-secondary btn-lg">
                    Cadastrar
                </BotaoSubmit>
            </div>
        </form>
    );
}