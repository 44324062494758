import { useEffect, useState } from "react";
import styles from "./TabelaFinalizadas.module.css";
import TypeSpecimenOutlinedIcon from '@mui/icons-material/TypeSpecimenOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { collection, getDocs } from "firebase/firestore/lite";
import ICriarCampanha from "../../../../types/ICriarCampanha";
import { db } from "../../../../db/banco";
import CampoEscolha from "../../../../components/CampoEscolha";
import { listaTipoDeCampanha } from "../../../../utils/tipoDeCampanha";
import BotaoSubmit from "../../../../components/BotaoSubmit";
import IChatPropostas from "../../../../types/ICharPropostas";
import DadosCampInflu from "../../MinhasCampanhasInfluenciador/TabelaMinhasCampInflu/DadosCampInflu";
import autenticaStore from "../../../../stores/autentica.store";
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';

interface FiltroInfluenciadorProps {
    qual?: string;
}

export default function TabelaFinalizadas({ qual }: FiltroInfluenciadorProps) {
    const email = autenticaStore.getEmail();
    const [nome, setNome] = useState("");
    const [tipoDeCampanha, setTipoDeCampanha] = useState("");
    const [ordenar, setOrdenar] = useState("");
    // Recebe as campanhas
    const [campanhasFeitas, setCampanhasFeitas] = useState<IChatPropostas[]>([]);
    const [campanhasFeitasAux, setCampanhasFeitasAux] = useState<IChatPropostas[]>([]);
    
    const [nomeCampanha, setNomeCampanha] = useState("");
    const [listaFinalizadas, setListaFinalizadas] = useState([""]);

    // banco de dados
    const useCollectionRef = collection(db, "chatPropostas");

    // puxar dados
    useEffect(() => {
        const obterCampanhas = async () => {
            const dataBD = await getDocs(useCollectionRef);
            const todasCampanhas: IChatPropostas[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as IChatPropostas)
            const campanhasDesseInf: IChatPropostas[] = todasCampanhas.filter(desse => desse.emailInfluenciador === email);
            const somenteFinalizadas: IChatPropostas[] = campanhasDesseInf.filter(aceitas => aceitas.statusProposta === "feito");


            
            
            setCampanhasFeitas(somenteFinalizadas);
            setCampanhasFeitasAux(somenteFinalizadas);

            let lista: any = ["Todas"];
            somenteFinalizadas.map(fin => {
                lista.push(fin.nomeCampanha);
            })
            setListaFinalizadas(lista);
        }
        obterCampanhas();
    }, [])

    function filtrar(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        let resultadoFiltrado: IChatPropostas[] = [...campanhasFeitasAux]; // Cria uma cópia do array original

        if (nomeCampanha !== "Todas") {
            resultadoFiltrado = resultadoFiltrado.filter(nome => nome.nomeCampanha === nomeCampanha);
        } else {
            resultadoFiltrado = campanhasFeitasAux
        }
        setCampanhasFeitas(resultadoFiltrado)
        
        
    }


    return (
        <>
            <form className={styles.TabelaFinalizadas} onSubmit={filtrar}>
                {/* <CampoTexto
                    titulo="Nome da campanha?"
                    valor={nome}
                    controle={setNome}
                    icone={<CreateOutlinedIcon fontSize="large" />}
                    placeholder="Riot Games"
                /> */}
                {/* <CampoEscolha
                    titulo="Tipo de campanha"
                    lista={listaTipoDeCampanha}
                    icone={<TypeSpecimenOutlinedIcon fontSize="large" />}
                    controle={setTipoDeCampanha}
                    valorPadrao="Selecione entre os tipos"
                /> */}
                <CampoEscolha
                    titulo="Campanhas finalizadas"
                    lista={listaFinalizadas}
                    icone={<CampaignOutlinedIcon fontSize="large" />}
                    controle={setNomeCampanha}
                    valorPadrao="Escolha a campanha"
                />
                {/* <CampoEscolha
                    titulo="Ordenar por"
                    lista={listaOrdenar}
                    icone={<AlignHorizontalLeftOutlinedIcon fontSize="large" />}
                    controle={setOrdenar}
                    valorPadrao="Como prefere organizar?"
                /> */}

                <BotaoSubmit classe="btn btn-secondary">
                    <SearchOutlinedIcon fontSize="large" />
                    Pesquisar
                </BotaoSubmit>
            </form>

            <DadosCampInflu campanhas={campanhasFeitas} />

        </>
    );
}