import styles from "./TabelaAguardandoConfirmacao.module.css"
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import { useEffect, useState } from "react";

import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';

import { useLocation } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore/lite";
import autenticaStore from "../../../../stores/autentica.store";
import IChatPropostas from "../../../../types/ICharPropostas";
import { db } from "../../../../db/banco";
import CampoEscolha from "../../../../components/CampoEscolha";
import BotaoSubmit from "../../../../components/BotaoSubmit";
import DadosTabelaAguardandoConfirmacao from "./DadosTabelaAguardandoConfirmacao";
import { listaTiposDeCampanha } from "../../../../utils/listas";


interface TabelaAguardandoConfirmacaoProps {
    temSegundaTabela?: boolean,
    filtroPequeno?: boolean
}

export default function TabelaAguardandoConfirmacao({ temSegundaTabela, filtroPequeno }: TabelaAguardandoConfirmacaoProps) {
    // obtem o email(serve como id) do anunciante
    const email = autenticaStore.getEmail();
    // recebe e controla as campanhas
    const [chatPropostasTrabalho, setChatPropostasTrabalho] = useState<IChatPropostas[]>([]);
    const [chatPropostasTrabalhoAux, setChatPropostasTrabalhoAux] = useState<IChatPropostas[]>([]);
    const [listaChatTrabalho, setListaChatTrabalho] = useState([""]);   
    
    // Filtro um
    const [nomeCampanha, setNomeCampanha] = useState("");

    function filtro(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        let resultadoFiltrado: IChatPropostas[] = [...chatPropostasTrabalhoAux]; // Cria uma cópia do array original

        if (nomeCampanha !== "Todas") {
            resultadoFiltrado = resultadoFiltrado.filter(nome => nome.nomeCampanha === nomeCampanha);
        } else {
            resultadoFiltrado = chatPropostasTrabalhoAux
        }
        setChatPropostasTrabalho(resultadoFiltrado)
             
    }
    // banco de dados
    const useCollectionRef = collection(db, "chatPropostas");
    // puxar dados
    useEffect(() => {
        const obterChat = async () => {
            // pega o chat proposta certo
            const dataBD = await getDocs(useCollectionRef);
            const todosChamados: IChatPropostas[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id } as IChatPropostas))
           
            

            const somenteDesseAnunciante: IChatPropostas[] = todosChamados.filter(somente => somente.emailAnunciante === email);  

            const ativosChamados: IChatPropostas[] = somenteDesseAnunciante.filter(emEspera => emEspera.statusProposta === "aceito" && emEspera.tipoCampanha === listaTiposDeCampanha[0] || emEspera.tipoCampanha === listaTiposDeCampanha[1]);
            setChatPropostasTrabalho(ativosChamados);
            setChatPropostasTrabalhoAux(ativosChamados);
            let lista: any = ["Todas"]
            ativosChamados.map(nomes => {
                
                lista.push(nomes.nomeCampanha);                
            })
            
            setListaChatTrabalho(lista);              
        }
        obterChat();

    }, []);

    return (
        <>  
            
            <form className={styles.TabelaAguardandoConfirmacao} onSubmit={filtro}>
                <CampoEscolha
                    titulo="Campanhas dispóniveis"
                    lista={listaChatTrabalho}
                    icone={<CampaignOutlinedIcon fontSize="large" />}
                    controle={setNomeCampanha}
                    valorPadrao="Escolha a campanha"
                />
                <BotaoSubmit classe="btn btn-secondary">
                    <SearchOutlinedIcon fontSize="large" />
                    Pesquisar
                </BotaoSubmit>
            </form>

            <DadosTabelaAguardandoConfirmacao chatPropostas={chatPropostasTrabalho}/>           
        </>
    )
}