import aceitoStore from "../../../stores/aceito";
import UsuarioNaoAceitoInfluenciador from "../../UsuarioNaoAceito/UsuarioNaoAceitoInfluenciador";
import styles from "./MinhasCampanhasInfluenciador.module.css";
import TabelaMinhasCampInflu from "./TabelaMinhasCampInflu";

export default function MinhasCampanhasInfluenciador() {
    // verifica se usuário foi aceito na plataforma
    const statusPlataforma = aceitoStore.getStatus();

    return (
       <>
        {statusPlataforma === "aceito" ?  <section className={styles.MinhasCampanhasInfluenciador}>
            <h2> Minhas campanhas </h2>

            <TabelaMinhasCampInflu />
        </section> : <UsuarioNaoAceitoInfluenciador />}
       </>
    );
}