import { getDownloadURL, getStorage, listAll, ref, uploadBytesResumable } from "firebase/storage";
import styles from "./EnviarTrabalho.module.css";
import { db, storage } from "../../../db/banco";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import autenticaStore from "../../../stores/autentica.store";
import CampoTexto from "../../../components/CampoTexto";
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import axios from "axios";
import { addDoc, collection, doc, getDocs, updateDoc } from "firebase/firestore/lite";
import ICriarCampanha from "../../../types/ICriarCampanha";
import aceitoStore from "../../../stores/aceito";
import UsuarioNaoAceitoInfluenciador from "../../UsuarioNaoAceito/UsuarioNaoAceitoInfluenciador";
import TextField from "../../../components/TextField";
import AddLinkIcon from '@mui/icons-material/AddLink';
import IChatPropostas from "../../../types/ICharPropostas";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

export default function EnviarTrabalho() {
    // verifica se usuário foi aceito na plataforma
    const statusPlataforma = aceitoStore.getStatus();
    const email = autenticaStore.getEmail();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const parametro = params.get("id"); // Parâmetro da página
    const nomeInfluenciadorParametro = params.get("nomeInfluenciador");
    // Usado para navegar entre rotas
    const navigate = useNavigate();
    const [comprovanteTrabalho, setComprovanteTrabalho] = useState("");
    // Anunciante da campanha
    const [nomeAnunciante, setNomeAnunciante] = useState("");
    const [emailAnunciante, setEmailAnunciante] = useState("");
    // dados para notificação
    const [nomeCampanha, setNomeCampanha] = useState("");
    const [caminhoPdf, setCaminhoPdf] = useState<string[]>([""]);
    // link do trabalho
    const [linkTrabalhoUm, setLinkTrabalhoUm] = useState("");
    const [linkTrabalhoDois, setLinkTrabalhoDois] = useState("");
    const [linkTrabalhoTres, setLinkTrabalhoTres] = useState("");
    const [linkTrabalhoQuatro, setLinkTrabalhoQuatro] = useState("");
    const [linkTrabalhoCinco, setLinkTrabalhoCinco] = useState("");

    const [idChat, setIdChat] = useState("");
    const [linksTrabalhoJaEnviados, setLinksTrabalhoJaEnviados] = useState([""]);
    // Adicionar mais links
    const [contador, setContador] = useState(1);
    // atualiza
    const [atualiza, setAtualiza] = useState(false);

    // puxar dados se for Anunciante
    const useCollectionRef = collection(db, "criarCampanha");
    useEffect(() => {
        const obterCampanhas = async () => {
            const dataBD = await getDocs(useCollectionRef);
            const todasCampanhas: ICriarCampanha[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id } as ICriarCampanha));
            const somenteEssaCampanha = todasCampanhas.filter(somente => somente.id === parametro);

            somenteEssaCampanha.map(somente => {
                setNomeAnunciante(somente.nomeAnunciante);
                setEmailAnunciante(somente.anuncianteEmail);
                setNomeCampanha(somente.nomeDaCampanha);
            });
        }
        obterCampanhas();
        


    }, [])

    useEffect(() => {
        const storage = getStorage();
        const listRef = ref(storage, `gs://ism-teste.appspot.com/influenciador/${email}/${parametro}/comprovantesCampanha`);
        const listaCaminhosPdfs: any = [];

        listAll(listRef)
            .then((res => {
                res.items.forEach((itemRef => {
                    listaCaminhosPdfs.push(itemRef.fullPath);
                    setCaminhoPdf(listaCaminhosPdfs);
                }))
            }));
    }, []);

    function baixarPdf(url: any) {
        window.open(url);
    }

    function pegaCaminho(caminho: string) {
        
        const caminhoEncontrado = caminho;
        criaDownload(caminhoEncontrado);
    }

    function criaDownload(caminhoRecebido: any) {
        const storage = getStorage();

        getDownloadURL(ref(storage, caminhoRecebido))
            .then((url) => {
                const xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.open('GET', url);
                xhr.send();
                baixarPdf(url);
            });
    }

    const enviarEmail = async () => {
        try {
            const response = await axios.post(
                "https://us-central1-ism-teste.cloudfunctions.net/enviarEmail",
                {
                    destinatario: emailAnunciante,
                    assunto: "Oii, anunciante! Temos um recado importante!",
                    mensagem: `Oii, ${nomeAnunciante}\n\n` +

                        "Um influenciador sinalizou na plataforma que realizou a divulgação proposta na campanha.\n\n" +

                        "Precisamos que você confirme na plataforma que o influenciador realmente realizou a\n" +
                        "divulgação, tudo bem? Para a liberação do saque ao influenciador.\n\n" +

                        "Se em até 7 dias úteis você não der o “ok” na plataforma ou não entrar em contato conosco\n" +
                        "para reportar algum problema, o “ok” será dado automaticamente pelo sistema e o saque\n" +
                        "será liberado para o influenciador.\n\n" +

                        "Ficamos felizes por ter você com a gente! 💜\n\n" +

                        "Equipe ISM"
                }
            );

            const { id } = response.data;
            return id;
        } catch (error) {
            // console.log(error);
        }
    };

    const enviarEmailInfluenciador = async () => {
        criarNovaNotificacao();
        try {
            const response = await axios.post(
                "https://us-central1-ism-teste.cloudfunctions.net/enviarEmail",
                {
                    destinatario: email,
                    assunto: "Parabéns, influenciador! 🎉",
                    mensagem: `Oii, ${nomeInfluenciadorParametro}\n\n` +

                        "Parabéns por ter participado e finalizado a campanha. Estamos muito felizes de ter você\n" +
                        "com a gente 💜\n\n" +

                        "Estamos apenas aguardando o “ok” do anunciante para que seu saldo fique disponível para\n" +
                        "saque na plataforma.\n\n" +

                        "Parabéns mais uma vez!! 🎉\n\n" +

                        "Equipe ISM"
                }
            );

            const { id } = response.data;
            return id;
        } catch (error) {
            // console.log(error);
        }
    };
    // banco de dados
    const useCollectionRefNotificacao = collection(db, "notificacao");

    async function criarNovaNotificacao() {
        const notificacao = await addDoc(useCollectionRefNotificacao, {
            mensagem: `Um influenciador enviou comprovante de campanha finalizada, na campanha ${nomeCampanha}`,
            visto: false,
            tipoDoUsuarioQueVaiReceberNotificacao: "anunciante",
            emailDeQuemVaiReceberNotificacao: `${emailAnunciante}`,
            data: new Date().toLocaleDateString(),
            link: "https://influenciesuamarca.com.br/anunciante/aguardando-confirmacao"
        })
    }

    const uploadMidiaKit = (event: any) => {
        event.preventDefault();
        enviarEmail();
        enviarEmailInfluenciador();
        const file = event.target[0]?.files[0];
        if (!file) return;

        const storageRef = ref(storage, `influenciador/${email}/${parametro}/comprovantesCampanha/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            "state_changed",
            snapshot => {
            },
            error => {
                alert(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then(url => {
                    setComprovanteTrabalho(url);
                    alert(`Arquivo enviado com sucesso!`);
                    navigate("/influenciador/minhas-campanhas");
                })
            }
        )
    }

    // banco de dados
    const useCollectionChatRef = collection(db, "chatPropostas");
    // puxar dados
    useEffect(() => {
        const obterChat = async () => {
            try {
                // pega o chat proposta certo
                const dataBD = await getDocs(useCollectionChatRef);
                const todosChamados = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id } as IChatPropostas));
                const filtrado = todosChamados.filter(chamado => chamado.idCampanha === parametro);

                filtrado.map(pegarIdChat => {
                    setIdChat(pegarIdChat.id);
                    setLinksTrabalhoJaEnviados(pegarIdChat.linksTrabalho);
                })

            } catch (error) {
                console.error("Erro ao obter chat propostas:", error);
            }
        };

        obterChat();

    }, [parametro, atualiza]);


    async function editarChamado(novosDados: any) {
        try {
            if (idChat) {
                const chamadoDocRef = doc(db, "chatPropostas", idChat);

                await updateDoc(chamadoDocRef, novosDados);
                setAtualiza(!atualiza)
                alert("Link enviado");
            }
        } catch (error) {
            alert("Nao deu certo")
        }
    }

    async function enviarLinkTrabalho(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        const chat = {
            linksTrabalho: [linkTrabalhoUm, linkTrabalhoDois, linkTrabalhoTres, linkTrabalhoQuatro, linkTrabalhoCinco]
        }
        await editarChamado(chat)
    }

    function adicionarMaisCampoDeLink() {
        if (contador !== 5) {
            setContador(contador + 1);
        } else {
            alert("Número máximo de links atingido");
        }
    }

    return (
        <>
            {statusPlataforma === "aceito" ? <section className={styles.EnviarTrabalho}>
                <h2> Enviar comprovante de trabalho </h2>
                <h3> Aqui você pode enviar prints que comprovem o seu trabalho feito. </h3>

                <form onSubmit={uploadMidiaKit} className={styles.FormPdf}>


                    <CampoTexto
                        titulo="Insira seu print"
                        valor={comprovanteTrabalho}
                        controle={setComprovanteTrabalho}
                        type="file"
                        icone={<FileCopyOutlinedIcon fontSize="large" />}
                    />

                    <button className={styles.botaoEnviar}>
                        Enviar
                    </button>

                    {/* <button onClick={baixarPdf} className="btn btn-primary"> Visualizar mídia kit </button> */}
                </form>
                <h3> Comprovantes enviados </h3>
                <ul>
                    {caminhoPdf.map(pdf => {
                        const partes = pdf.split('/comprovantesCampanha/');
                        const nomeArquivo = partes.length > 1 ? partes[1] : pdf; // Pega a parte após '/comprovantesCampanha/' ou usa o caminho completo se não encontrar

                        return (
                            <li onClick={() => pegaCaminho(pdf)} key={pdf}>
                                <FileDownloadIcon fontSize="medium" className={styles.Icone} />
                                <label>{nomeArquivo}</label>
                            </li>
                        );
                    })}
                </ul>

                <h3> Aqui você pode enviar os links que comprovem o seu trabalho feito. </h3>

                <form onSubmit={enviarLinkTrabalho}>
                    {contador === 1 && <TextField
                        title="Link do trabalho"
                        control={setLinkTrabalhoUm}
                        value={linkTrabalhoUm}
                        placeholder="https://www.instagram.com/p/BVFDKLJd/"
                        icon={<AddLinkIcon fontSize="large" />}
                    />}

                    {contador === 2 && <>
                        <TextField
                            title="Link do trabalho"
                            control={setLinkTrabalhoUm}
                            value={linkTrabalhoUm}
                            placeholder="https://www.instagram.com/p/BVFDKLJd/"
                            icon={<AddLinkIcon fontSize="large" />}
                        />

                        <TextField
                            title="Link do trabalho"
                            control={setLinkTrabalhoDois}
                            value={linkTrabalhoDois}
                            placeholder="https://www.instagram.com/p/BVFDKLJd/"
                            icon={<AddLinkIcon fontSize="large" />}
                        />
                    </>}

                    {contador === 3 && <>
                        <TextField
                            title="Link do trabalho"
                            control={setLinkTrabalhoUm}
                            value={linkTrabalhoUm}
                            placeholder="https://www.instagram.com/p/BVFDKLJd/"
                            icon={<AddLinkIcon fontSize="large" />}
                        />

                        <TextField
                            title="Link do trabalho"
                            control={setLinkTrabalhoDois}
                            value={linkTrabalhoDois}
                            placeholder="https://www.instagram.com/p/BVFDKLJd/"
                            icon={<AddLinkIcon fontSize="large" />}
                        />

                        <TextField
                            title="Link do trabalho"
                            control={setLinkTrabalhoTres}
                            value={linkTrabalhoTres}
                            placeholder="https://www.instagram.com/p/BVFDKLJd/"
                            icon={<AddLinkIcon fontSize="large" />}
                        />
                    </>}

                    {contador === 4 && <>
                        <TextField
                            title="Link do trabalho"
                            control={setLinkTrabalhoUm}
                            value={linkTrabalhoUm}
                            placeholder="https://www.instagram.com/p/BVFDKLJd/"
                            icon={<AddLinkIcon fontSize="large" />}
                        />

                        <TextField
                            title="Link do trabalho"
                            control={setLinkTrabalhoDois}
                            value={linkTrabalhoDois}
                            placeholder="https://www.instagram.com/p/BVFDKLJd/"
                            icon={<AddLinkIcon fontSize="large" />}
                        />

                        <TextField
                            title="Link do trabalho"
                            control={setLinkTrabalhoTres}
                            value={linkTrabalhoTres}
                            placeholder="https://www.instagram.com/p/BVFDKLJd/"
                            icon={<AddLinkIcon fontSize="large" />}
                        />

                        <TextField
                            title="Link do trabalho"
                            control={setLinkTrabalhoQuatro}
                            value={linkTrabalhoQuatro}
                            placeholder="https://www.instagram.com/p/BVFDKLJd/"
                            icon={<AddLinkIcon fontSize="large" />}
                        />
                    </>}

                    {contador === 5 && <>
                        <TextField
                            title="Link do trabalho"
                            control={setLinkTrabalhoUm}
                            value={linkTrabalhoUm}
                            placeholder="https://www.instagram.com/p/BVFDKLJd/"
                            icon={<AddLinkIcon fontSize="large" />}
                        />

                        <TextField
                            title="Link do trabalho"
                            control={setLinkTrabalhoDois}
                            value={linkTrabalhoDois}
                            placeholder="https://www.instagram.com/p/BVFDKLJd/"
                            icon={<AddLinkIcon fontSize="large" />}
                        />

                        <TextField
                            title="Link do trabalho"
                            control={setLinkTrabalhoTres}
                            value={linkTrabalhoTres}
                            placeholder="https://www.instagram.com/p/BVFDKLJd/"
                            icon={<AddLinkIcon fontSize="large" />}
                        />

                        <TextField
                            title="Link do trabalho"
                            control={setLinkTrabalhoQuatro}
                            value={linkTrabalhoQuatro}
                            placeholder="https://www.instagram.com/p/BVFDKLJd/"
                            icon={<AddLinkIcon fontSize="large" />}
                        />

                        <TextField
                            title="Link do trabalho"
                            control={setLinkTrabalhoCinco}
                            value={linkTrabalhoCinco}
                            placeholder="https://www.instagram.com/p/BVFDKLJd/"
                            icon={<AddLinkIcon fontSize="large" />}
                        />
                    </>}

                    <div className={styles.ContainerBotaoAdicionarLink} onClick={adicionarMaisCampoDeLink}>
                        <AddCircleOutlineOutlinedIcon fontSize="large" />
                        Adicionar outro link

                    </div>

                    <button className={styles.botaoEnviar}> enviar </button>

                </form>

                <h3> Links enviados </h3>

                {linksTrabalhoJaEnviados.length > 0 ?
                    <>
                        {linksTrabalhoJaEnviados.filter(link => link).map(link => (
                            <h5 key={link}> <a href={link} target="_blank" rel="noopener noreferrer">
                                {link}
                            </a></h5>
                        ))}
                    </> : <h5> Nenhum link enviado até o momento </h5>}


            </section> : <UsuarioNaoAceitoInfluenciador />}
        </>
    );
}