import styles from "./AreaTextoMenor.module.css";
import { ReactNode } from 'react';

interface AreaTextoMenorrops {
    titulo?: string,
    valor: string,
    controle: (value: string) => void,
    icone: ReactNode,
    placeholder?: string,
    linhas: number,
    colunas: number   
}

export default function AreaTextoMenor({ titulo, valor, controle, icone, placeholder, linhas, colunas }: AreaTextoMenorrops) {
    return(
        <div className={styles.AreaTextoMenor}>
            <label> {titulo} </label>
            <div>
                {icone}
                <textarea
                    value={valor}
                    onChange={(e) => controle(e.target.value)}
                    placeholder={placeholder}                    
                    required
                    rows={linhas}
                    cols={colunas}
                />
            </div>
        </div>
    )
}