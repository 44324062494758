import styles from "./TextField.module.css";
import { ReactNode } from 'react';

interface TextFieldProps {
    title?: string,
    value: string | string[],
    control: (value: string) => void,
    icon: ReactNode,
    placeholder?: string,
    type?: string,
    minLength?: number
    requiredField?: boolean
}

export default function TextField({ title, value, control, icon, placeholder, type,  minLength, requiredField}: TextFieldProps) {
    return (
        <div className={styles.TextField}>
            <label> {title} </label>
            <div>
                {icon}
                <input
                    value={value}
                    onChange={(e) => control(e.target.value)}
                    placeholder={placeholder}
                    type={type}                    
                    minLength={minLength}
                    required={requiredField}
                />
            </div>
        </div>
    );
}