import styles from "./ChoiceField.module.css";
import { ReactNode } from 'react';

interface ChoiceFieldProps {
    title: string,
    control: (value: string) => void 
    list: string[]
    icon: ReactNode,
    defaultValue?: string,
    hasTodas?: boolean,
    requiredField?: boolean
}

export default function ChoiceField({ title, control, list, icon, defaultValue, hasTodas, requiredField }: ChoiceFieldProps) {
    return (
        <div className={styles.ChoiceField}>
            <label htmlFor={title}> {title} </label>
            <div>
                {icon}
                <select onChange={(e) => control(e.target.value)} id={title} required={requiredField}>
                    <option disabled selected> {defaultValue} </option>
                    {list.map(item => (
                        <option key={item}> {item} </option>
                    ))}
                    {hasTodas && <option> Todas </option>}
                </select>
            </div>
        </div>
    );
}