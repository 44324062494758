import styles from "./VerPerfil.module.css";
import { useState } from "react";
import VerPerfilInfluenciador from "./VerPerfilInfluenciador";
import VerPerfilAnunciante from "./VerPerfilAnunciante";


export default function VerPerfil() {
    // Controla qual tipo de conta sera criada
    const [qualUsuario, setQualUsuario] = useState(true); // true é para influenciador
    // controla o botao clicado
    const [botaoAnunciante, setBotaoAnunciante] = useState("btn btn-outline-secondary");
    const [botaoInfluenciador, setBotaoInfluenciador] = useState("btn btn-dark");

    // Funcao que muda o formulario para Anunciante
    function mudaParaAnunciante() {
        setQualUsuario(false);
        setBotaoAnunciante("btn btn-dark");
        setBotaoInfluenciador("btn btn-outline-secondary");
    }
    // Funcao que muda o formulario para Influenciador
    function mudaParaInfluenciador() {
        setQualUsuario(true);
        setBotaoInfluenciador("btn btn-dark");
        setBotaoAnunciante("btn btn-outline-secondary");
    }


    return (
        <section className={styles.VerPerfil}>
            <h2> Ver usuários </h2>

            <p> Escolha o tipo de usuário que deseja visualizar. </p>

            <div>
                <button type="button" className={botaoInfluenciador} onClick={mudaParaInfluenciador}> Influenciador </button>
                <button type="button" className={botaoAnunciante} onClick={mudaParaAnunciante}> Anunciante </button>
            </div>

            {qualUsuario ? <VerPerfilInfluenciador /> : <VerPerfilAnunciante />}
        </section>
    );
}