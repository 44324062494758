import EstatisticaDaCampanha from "./EstatisticaDaCampanha";
import styles from "./FinalizarTrabalho.module.css";
import InfluAcabaramTrabalho from "./InfluAcabaramTrabalho";

export default function FinalizarTrabalho() {
    return(
        <section className={styles.FinalizarTrabalho}>
            <h2> Finalizar trabalho </h2>

            <EstatisticaDaCampanha />

            <InfluAcabaramTrabalho />
        </section>
    );
}