import { getDownloadURL, getStorage, listAll, ref } from "firebase/storage";
import styles from "./EnviarTrabalho.module.css";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import autenticaStore from "../../../../stores/autentica.store";

export default function VerNotaFiscal() {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const emailParametro = params.get("id");
    const nomeCampanhaParametro = params.get("nome");
    // Usado para navegar entre rotas
    const [comprovanteTrabalho, setComprovanteTrabalho] = useState("");
    const [downloadMidiaKit, setDownloadMidiaKit] = useState("");

    const [caminhoPdf, setCaminhoPdf] = useState<string[]>([""]);

    

    useEffect(() => {
        const storage = getStorage();
        const listRef = ref(storage, `gs://ism-teste.appspot.com/influenciador/${emailParametro}/notaFiscal/${nomeCampanhaParametro}`);
        const listaCaminhosPdfs: any = [];

        listAll(listRef)
            .then((res => {
                res.items.forEach((itemRef => {
                    listaCaminhosPdfs.push(itemRef.fullPath);
                    setCaminhoPdf(listaCaminhosPdfs);
                }))
            }));
    }, []);

    function baixarPdf(url: any) {
        window.open(url);
    }

    function pegaCaminho(caminho: string) {
        
        const caminhoEncontrado = caminho;
        criaDownload(caminhoEncontrado);
    }

    function criaDownload(caminhoRecebido: any) {
        const storage = getStorage();

        getDownloadURL(ref(storage, caminhoRecebido))
            .then((url) => {
                const xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.open('GET', url);
                xhr.send();
                baixarPdf(url);
            });
    }
    
    return (
        <section className={styles.EnviarTrabalho}>            
            <h2> Notas fiscais enviadas </h2>
            <ul>
                {caminhoPdf.map(pdf => {
                    const indiceNotaFiscal = pdf.indexOf("/notaFiscal/");
                    const nomeArquivo = indiceNotaFiscal !== -1 ? pdf.substring(indiceNotaFiscal + 12) : pdf;

                    return (
                        <li onClick={() => pegaCaminho(pdf)} key={pdf}>
                            <FileDownloadIcon fontSize="medium" className={styles.Icone} />
                            <label>{nomeArquivo}</label>
                        </li>
                    );
                })}
            </ul>
        </section>
    );
}