import styles from "./Sobre.module.css";
import logo from "./logo.png";

export default function Sobre() {
    return(
        <section className={styles.Sobre}>
            <img src={logo} alt="Logo da ISM, sendo um pássaro"/>
            <div>
                <h2> Sobre o ISM </h2>
                <p> Somos especialistas em marketing de influência e temos como
                    propósito criar o match perfeito entre as marcas e influenciadores ideais. </p>
                <p> Os influenciadores digitais estão virando cada vez mais autoridades em assuntos
                    do seu nicho, tornando-se uma das principais fontes para tomada de decisão do consumidor. </p>
                <p> Não deixe de  <strong> influenciar sua marca! </strong></p>
            </div>     
        </section>
    )
}