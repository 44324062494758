import { useLocation, useNavigate } from "react-router-dom";
import styles from "./EditarCampanhas.module.css";
import { collection, getDocs } from "firebase/firestore/lite";
import { useState, useEffect } from "react";
import { db } from "../../../../../../db/banco";
import IAnunciante from "../../../../../../types/IAnunciante";
import ICriarCampanha from "../../../../../../types/ICriarCampanha";
import guardaPaginaAnterior from "../../../../../../stores/paginaAnterior";

export default function VerCampanhasModerador() {
     // Pegar parametro da página
     const location = useLocation();
     const params = new URLSearchParams(location.search);
     const parametro = params.get("id"); // Parâmetro da página
     // pagina
    const paginaParametro = params.get("pg");
    const navigate = useNavigate();
    // Anunciante
    const [campanhas, setCampanhas] = useState<ICriarCampanha[]>([]);
    const [emailAnunciante, setEmailAnunciante] = useState("");

    // salva a Página
    useEffect(() => {        
        if (paginaParametro) guardaPaginaAnterior.salvarPagina(paginaParametro, false);        
    }, [paginaParametro])
    
    // puxar dados se for Anunciante
    const useCollectionRef = collection(db, "criarCampanha");
    useEffect(() => {
        const obterAnunciantes = async () => {
            const dataBD = await getDocs(useCollectionRef);
            const todasCampanhas: ICriarCampanha[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id } as ICriarCampanha));
            const somenteDesseAnunciante = todasCampanhas.filter(somente => somente.anuncianteEmail === emailAnunciante);            
            setCampanhas(somenteDesseAnunciante);  
            
                      
        }
        obterAnunciantes();

    }, [emailAnunciante])

    // puxar dados se for Anunciante
    const useCollectionReff = collection(db, "anunciante");
    useEffect(() => {
        const obterAnunciantes = async () => {
            const dataBD = await getDocs(useCollectionReff);
            const todosAnunciantes: IAnunciante[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id } as IAnunciante));
            const esseAnunciante: IAnunciante[] = todosAnunciantes.filter(esse => esse.id === parametro);
           esseAnunciante.map(esse => {
            setEmailAnunciante(esse.email);
           })                     
        }
        obterAnunciantes();

    }, [])

    function verCampanha(id: string) {
        navigate(`/moderador/editar-campanha?id=${id}`)
    } 
    return (
        <section className={styles.EditarCampanhas}>
            <h3> Lista de Campanhas do anunciante: </h3>

            <table className="table table-light table-striped text-center ">
                <thead>
                    <tr>
                        <th scope="col" className="text-white">Nome</th>
                        <th scope="col" className="text-white">Ações</th>
                    </tr>
                </thead>

                {campanhas.map(cel => (
                    <tbody>
                        <tr>
                            <td> {cel.nomeDaCampanha} </td>
                            <td>
                                <button className="btn btn-primary m-lg-1" onClick={() => verCampanha(cel.id)}> Ver campanha </button>
                            </td>
                        </tr>
                    </tbody>
                ))}
            </table>
        </section>
    );
}