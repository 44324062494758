import styles from "./PerfilAnunciante.module.css";
import { useState, useEffect } from "react";
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import SmartphoneOutlinedIcon from '@mui/icons-material/SmartphoneOutlined';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import LockClockOutlinedIcon from '@mui/icons-material/LockClockOutlined';
import SignpostIcon from '@mui/icons-material/Signpost';
import SixtyFpsIcon from '@mui/icons-material/SixtyFps';
import WrapTextIcon from '@mui/icons-material/WrapText';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import MapIcon from '@mui/icons-material/Map';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useLocation, useNavigate } from "react-router-dom";
import { collection, doc, getDocs, updateDoc } from "firebase/firestore/lite";
import autenticaStore from "../../../../stores/autentica.store";
import { estados, obterNomesDasCidadesPorEstadoNome } from "../../../../utils/apiIbge";
import BotaoSubmit from "../../../../components/BotaoSubmit";
import CampoEscolha from "../../../../components/CampoEscolha";
import { db } from "../../../../db/banco";
import IAnunciante from "../../../../types/IAnunciante";
import CampoTexto from "../../../../components/CampoTexto";
import { mascaraCnpj, mascaraTelefone, mascaraCEP } from "../../../../utils/mascaras";
import guardaPaginaAnterior from "../../../../stores/paginaAnterior";
import TextField from "../../../../components/TextField";
import ChoiceField from "../../../../components/ChoiceField";

export default function EditarPerfilAnunciante() {
    // Pegar parametro da página
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const emailId = params.get("id"); // Parâmetro da página
    // pagina 
    const paginaParametro = params.get("pg");
    // obter o id
    const [idAnunciante, setIdAnunciante] = useState("")
    // Dados já cadastrados
    const [nomeCompleto, setNomeCompleto] = useState("");
    const [email, setEmail] = useState("");
    const [cnpj, setCnpj] = useState("");
    const [razaoSocial, setRazaoSocial] = useState("");
    const [telefone, setTelefone] = useState("");
    const [senha, setSenha] = useState("");
    const [confirmarSenha, setConfirmarSenha] = useState("");
    // Novos dados
    const [cep, setCep] = useState("");
    const [numero, setNumero] = useState("");
    const [complemento, setComplemento] = useState("");
    const [logradouro, setLogradouro] = useState("");
    const [bairro, setBairro] = useState("");
    const [estado, setEstado] = useState("");
    const [cidade, setCidade] = useState("");
    // aux para pegar as cidades
    const [nomesDasCidades, setNomesDasCidades] = useState<string[]>([]);
    // Controla se as senhas criadas são iguais
    const [senhaDiferente, setSenhaDiferente] = useState(false);
    // Puxa a lista de estados pela api do IBGE
    const nomesEstados = estados.map((estado) => estado.nome);
    nomesEstados.sort();

    // salva a Página
    useEffect(() => {
        if (paginaParametro) guardaPaginaAnterior.salvarPagina(paginaParametro, false);
    }, [paginaParametro])

    useEffect(() => {
        if (estado) {
            obterNomesDasCidadesPorEstadoNome(estado)
                .then(nomesDasCidades => {
                    // console.log(nomesDasCidades);
                    // Atualize o estado das cidades com os nomes obtidos
                    setCidade('');
                    setNomesDasCidades(nomesDasCidades);
                })
                .catch(error => {
                    console.error('Ocorreu um erro:', error);
                });
        }
    }, [estado]);

    // banco de dados
    const useCollectionRef = collection(db, "anunciante");
    // puxar dados
    useEffect(() => {
        const obterAnunciantes = async () => {
            const dataBD = await getDocs(useCollectionRef);
            const todosAnunciantes: IAnunciante[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as IAnunciante)
            const filtrado: IAnunciante[] = todosAnunciantes.filter(filtrado => filtrado.email === emailId)
            filtrado.forEach(anunciante => {
                if ("id" in anunciante) setIdAnunciante(anunciante.id)
                if ("nomeCompleto" in anunciante) setNomeCompleto(anunciante.nomeCompleto as string)
                if ("email" in anunciante) setEmail(anunciante.email as string)
                if ("cnpj" in anunciante) setCnpj(anunciante.cnpj as string)
                if ("razaoSocial" in anunciante) setRazaoSocial(anunciante.razaoSocial as string);
                if ("telefone" in anunciante) setTelefone(anunciante.telefone as string);
                if ("senha" in anunciante) {
                    setSenha(anunciante.senha as string);
                    setConfirmarSenha(anunciante.senha as string);
                }
                if ("cep" in anunciante) setCep(anunciante.cep as string);
                if ("numero" in anunciante) setNumero(anunciante.numero as string);
                if ("complemento" in anunciante) setComplemento(anunciante.complemento as string);
                if ("logradouro" in anunciante) setLogradouro(anunciante.logradouro as string);
                if ("bairro" in anunciante) setBairro(anunciante.bairro as string);
                if ("estado" in anunciante) setEstado(anunciante.estado as string);
                if ("cidade" in anunciante) setCidade(anunciante.cidade as string);
            })
        }
        obterAnunciantes();
    }, [])

    async function editarAnunciante(novosDados: any) {
        try {
            const anuncianteDocRef = doc(db, "anunciante", idAnunciante);

            await updateDoc(anuncianteDocRef, novosDados);
            autenticaStore.login({ email: email, token: senha });
            alert("Dados editados");
        } catch (error) {
            alert("Nao deu certo")
        }
    }

    function concluirCadastro(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        // Verifica se a senha criada é igual ao confirmar senha
        if (senha !== confirmarSenha) {
            setSenhaDiferente(true);
            return
        }
        const anunciante = {
            nomeCompleto,
            email,
            cnpj,
            razaoSocial,
            telefone,
            senha,
            cep,
            numero,
            complemento,
            logradouro,
            bairro,
            estado,
            cidade
        }

        editarAnunciante(anunciante);
        console.log(anunciante);
        // navigate("/anunciante/inicio");
    }

    return (
        <section className={styles.PerfilAnunciante}>
            <h2> Editar perfil do anunciante {nomeCompleto} </h2>

            <form onSubmit={concluirCadastro}>
                <h3> Dados cadastrais do anunciante: </h3>

                <TextField
                    title="Nome completo"
                    value={nomeCompleto}
                    control={setNomeCompleto}
                    icon={<PersonIcon fontSize="large" />}
                    placeholder="João da Silva Pereira"
                />

                <TextField
                    title="CNPJ"
                    value={mascaraCnpj(cnpj)}
                    control={setCnpj}
                    icon={<AssignmentOutlinedIcon fontSize="large" />}
                    placeholder="99.999.999/9999-99"
                />

                <TextField
                    title="Razão social"
                    value={razaoSocial}
                    control={setRazaoSocial}
                    icon={<DriveFileRenameOutlineOutlinedIcon fontSize="large" />}
                    placeholder="Padaria pão doce LTDA"
                />

                <TextField
                    title="Telefone"
                    value={mascaraTelefone(telefone)}
                    control={setTelefone}
                    icon={<SmartphoneOutlinedIcon fontSize="large" />}
                    placeholder="(99) 99999-9999"
                />

                <hr />

                <h3> Dados pós cadastro </h3>

                <TextField
                    title="CEP"
                    value={mascaraCEP(cep)}
                    control={setCep}
                    icon={<SignpostIcon fontSize="large" />}
                    placeholder="99999-999"
                />

                <TextField
                    title="Número"
                    value={numero}
                    control={setNumero}
                    icon={<SixtyFpsIcon fontSize="large" />}
                    placeholder="99999-136"
                />

                <TextField
                    title="Complemento"
                    value={complemento}
                    control={setComplemento}
                    icon={<WrapTextIcon fontSize="large" />}
                    placeholder="99999-apartamento 807"
                />               

                <TextField
                    title="Logradouro"
                    value={logradouro}
                    control={setLogradouro}
                    icon={<EditRoadIcon fontSize="large" />}
                    placeholder="Rua José Maria"
                />

                <TextField
                    title="Bairro"
                    value={bairro}
                    control={setBairro}
                    icon={<MapsHomeWorkIcon fontSize="large" />}
                    placeholder="Centro"
                />

                <ChoiceField
                    title="Estado"
                    list={nomesEstados}
                    control={setEstado}
                    icon={<MapIcon fontSize="large" />}
                    defaultValue={estado ? estado : "Selecione seu estado"}
                />                

                <ChoiceField
                    title="Cidade"
                    list={nomesDasCidades}
                    control={setCidade}
                    icon={<LocationOnIcon fontSize="large" />}
                    defaultValue={cidade ? cidade : "Selecione sua cidade"}
                />

                <div className={styles.BotoesNavegação}>
                    <BotaoSubmit classe="btn btn-secondary">
                        Editar
                    </BotaoSubmit>
                </div>
            </form>
        </section>
    )
}