import { action, makeAutoObservable, observable } from "mobx";

interface IUsuario {
    email: string,
    token: string,
}

class AutenticaStore {
    estaAutenticado = false;
    usuario: IUsuario = {email: "", token: ""};

    constructor() {
        makeAutoObservable(this, {
            estaAutenticado: observable,
            usuario: observable,
            login: action,
            logout: action,
        });
        
        // Tente restaurar o estado de autenticação do localStorage
        const storedUser = localStorage.getItem("usuario");
        if (storedUser) {
            const parsedUser: IUsuario = JSON.parse(storedUser);
            this.login(parsedUser);
        }
    }

    login({email, token} :IUsuario) {
        this.estaAutenticado = true;
        this.usuario = {email, token};

        // Salvar o estado de autenticação no localStorage
        localStorage.setItem("usuario", JSON.stringify(this.usuario));
    }
    logout() {
        this.estaAutenticado = false;
        this.usuario = {email: "", token: ""};

        // Limpar o estado de autenticação do localStorage
        localStorage.removeItem("usuario");
    }    
    getEmail(): string {
        return this.usuario.email;
    }
}

const autenticaStore = new AutenticaStore();

export default autenticaStore;
