import styles from "./Planos.module.css";
import MemoryIcon from '@mui/icons-material/Memory';
import SensorsIcon from '@mui/icons-material/Sensors';
import logoColorido from "./logoColorido.png";

export default function Planos() {
    return (
        <section className={styles.Planos} id="planos">
            <h2> plano ism </h2>
            <h3> Plano para anunciantes </h3>
            <div className={styles.Card}>
                <div className={styles.firstDiv}>
                    <label className={styles.Label}> ism </label>
                    <span className={styles.Sensor}> <SensorsIcon /> </span>
                </div>
                <div className={styles.secondDiv}>
                    <span className={styles.Chip}>
                        <MemoryIcon />
                    </span>
                    <span>
                        <p> Dois meses grátis </p>
                        <p> R$119,00 </p>
                    </span>
                </div>
                <div className={styles.thirdDiv}>
                    <img src={logoColorido} alt="Pássaro, logo da ism" />
                </div>
            </div>
        </section>
    );
}