import { useLocation } from "react-router-dom";
import styles from "./LeituraInfluenciador.module.css";
import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore/lite";
import IInfluenciador from "../../../types/IInfluenciador";
import { db } from "../../../db/banco";
import { getDownloadURL, getStorage, listAll, ref } from "firebase/storage";
import EmailIcon from '@mui/icons-material/Email';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import InstagramIcon from '@mui/icons-material/Instagram';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import EightKIcon from '@mui/icons-material/EightK';
import CategoryIcon from '@mui/icons-material/Category';
import MapIcon from '@mui/icons-material/Map';
import ForkLeftIcon from '@mui/icons-material/ForkLeft';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PortraitIcon from '@mui/icons-material/Portrait';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import pessoa from "../../../Image/usuario.png";
import InfoIcon from '@mui/icons-material/Info';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import DirectionsIcon from '@mui/icons-material/Directions';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import IAnunciante from "../../../types/IAnunciante";
import DomainIcon from '@mui/icons-material/Domain';

export default function LeituraAnunciante() {
    // Pegar parametro da página
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const parametro = params.get("id");
    // recebe e controla o influenciador
    const [anunciante, setAnunciante] = useState<IAnunciante[]>([]);
    const [email, setEmail] = useState("");
    // puxar foto de perfil
    const [foto, setFoto] = useState("");
    const [downloadFoto, setDownloadFoto] = useState("");
    // Midia kit
    const [downloadMidiaKit, setDownloadMidiaKit] = useState("");

    // banco de dados
    const useCollectionRef = collection(db, "anunciante");
    // puxar dados
    useEffect(() => {
        const obterAnunciantes = async () => {
            const dataBD = await getDocs(useCollectionRef);
            const todosAnunciantes: IAnunciante[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as IAnunciante)
            const filtrado: IAnunciante[] = todosAnunciantes.filter(filtrado => filtrado.id === parametro);
            setAnunciante(filtrado);
            console.log("influenciador:", filtrado);
            filtrado.map(anun => {
                setEmail(anun.email);
            })
        }
        obterAnunciantes();
    }, [])    

    function baixarPdf() {
        window.open(downloadMidiaKit);
    }
    return (
        <section className={styles.LeituraInfluenciador}>
            {anunciante.map(anun => (
                <h2> Perfil de {anun.nomeCompleto} </h2>
            ))}
            <form>
                {downloadFoto ? <img src={downloadFoto} alt="foto de perfil" /> : <img src={pessoa} alt="foto de perfil" />}
                {anunciante.map(anun => (
                    <>
                        <div>
                            <EmailIcon fontSize="large" />
                            <label> <strong> Email: </strong> {anun.email} </label>
                        </div>

                        <div>
                            <PersonSearchIcon fontSize="large" />
                            <label> <strong> CNPJ: </strong> {anun.cnpj} </label>
                        </div>

                        <div>
                            <DomainIcon fontSize="large" />
                            <label> <strong> Razão Social: </strong> {anun.razaoSocial} </label>
                        </div>

                        <div>
                            <SmartphoneIcon fontSize="large" />
                            <label> <strong> Telefone: </strong> {anun.telefone} </label>
                        </div>

                        <div>
                            <NorthEastIcon fontSize="large" />
                            <label> <strong> CEP: </strong> {anun.cep} </label>
                        </div>

                        <div>
                            <LooksOneIcon fontSize="large" />
                            <label> <strong> Número: </strong> {anun.numero} </label>
                        </div>

                        <div>
                            <EditLocationIcon fontSize="large" />
                            <label> <strong> Complemento: </strong> {anun.complemento} </label>
                        </div>

                        <div>
                            <DirectionsIcon fontSize="large" />
                            <label> <strong> Logradouro: </strong> {anun.logradouro} </label>
                        </div>

                        <div>
                            <MapsHomeWorkIcon fontSize="large" />
                            <label> <strong> Bairro: </strong> {anun.bairro} </label>
                        </div>
                        
                        <div>
                            <MapIcon fontSize="large" />
                            <label> <strong> Estado: </strong> {anun.estado} </label>
                        </div>

                        <div>
                            <ForkLeftIcon fontSize="large" />
                            <label> <strong> Cidade: </strong> {anun.cidade} </label>
                        </div>
                    </>
                ))}
            </form>            
        </section>
    )
}