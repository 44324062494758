import { useEffect, useState } from "react";
import "./DadosTabelaAguardandoConfirmacao.css"
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import { Link, useNavigate } from "react-router-dom";
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import IChatPropostas from "../../../../../types/ICharPropostas";

interface DadosTabelaAguardandoConfirmacaoProps {
    temStatus?: boolean,
    acao?: string,
    to?: any,
    chatPropostas?: IChatPropostas[]
}

export default function DadosTabelaAguardandoConfirmacao({ temStatus, acao, to, chatPropostas }: DadosTabelaAguardandoConfirmacaoProps) {
    const navigate = useNavigate();    
    const [idChat, setIdChat] = useState("");    

    const [tabela, setTabela] = useState(true);    

    useEffect(() => {
        function largura() {
            if (window.innerWidth < 650) setTabela(false);
        }
        largura();
    }, [])

    useEffect(() => {
        console.log("dentro da tabela:", chatPropostas);
        chatPropostas?.map(cha => {
            setIdChat(cha.id);
        })
        console.log("id chat:", idChat);


    }, [])

    function irParaChat() {
        navigate(`/anunciante/propostas-anunciante?id=${idChat}`)
    }

    return (
        <>

            <h2> Resultado da pesquisa</h2>
            {tabela ? <table className="table table-light table-striped text-center ">
                <thead>
                    <tr>
                        {/* <th scope="col" className="text-white">Status</th> */}
                        <th scope="col" className="text-white">Campanha </th>
                        <th scope="col" className="text-white">Nome</th>
                        <th scope="col" className="text-white">Criado em:</th>
                        <th scope="col" className="text-white">Ação</th>
                    </tr>
                </thead>
                {chatPropostas?.map(cel => (
                    <tbody>
                        <tr>
                            {/* <td> <p > {cel.statusProposta} </p> </td> */}
                            <td> {cel.nomeCampanha} </td>
                            <td> {cel.nomeInfluenciador} </td>
                            <td> {cel.criadoEm.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1')} </td>
                            <td>
                                <div className="divAcoesTabela">
                                    <Link to={`/anunciante/finalizar-trabalho-influenciador?id=${cel.id}`}> <WorkOutlineOutlinedIcon /> Finalizar trabalho </Link>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                ))}
            </table> : <div>
                {chatPropostas?.map(cel => (
                    <ul className="lista">
                        <li className="Itemlista"> . </li>
                        {/* <li className="Itemlista"> <strong> Status: </strong> <span className={`${cel.status === "ativo" ? "ativoMobile" : "desativadoMobile"}`}> {cel.status} </span> </li> */}
                        <li className="Itemlista"> <strong> Campanha: </strong> {cel.nomeCampanha} </li>
                        <li className="Itemlista"> <strong> Nome: </strong> {cel.nomeInfluenciador} </li>
                        <li className="Itemlista"> <strong> Criado em: </strong> {cel.criadoEm.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1')} </li>
                        <li className="Itemlista">
                            <div className="divAcoes">
                                <strong> Ações: </strong>
                                <div className="ContainerIconeMobile">
                                <Link to={`/anunciante/finalizar-trabalho-influenciador?id=${cel.id}`}> <WorkOutlineOutlinedIcon /> Finalizar trabalho </Link>
                                </div>
                            </div>
                        </li>
                    </ul>
                ))}
            </div>}
        </>
    )
}