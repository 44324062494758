import { useLocation } from "react-router-dom";
import styles from "./LeituraInfluenciador.module.css";
import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore/lite";
import { getDownloadURL, getStorage, listAll, ref } from "firebase/storage";
import EmailIcon from '@mui/icons-material/Email';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import InstagramIcon from '@mui/icons-material/Instagram';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import EightKIcon from '@mui/icons-material/EightK';
import CategoryIcon from '@mui/icons-material/Category';
import MapIcon from '@mui/icons-material/Map';
import ForkLeftIcon from '@mui/icons-material/ForkLeft';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PortraitIcon from '@mui/icons-material/Portrait';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import pessoa from "../../../../../Image/usuario.png";
import InfoIcon from '@mui/icons-material/Info';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import DirectionsIcon from '@mui/icons-material/Directions';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import CommentBankOutlinedIcon from '@mui/icons-material/CommentBankOutlined';
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import PinIcon from '@mui/icons-material/Pin';
import MoneyIcon from '@mui/icons-material/Money';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import { db } from "../../../../../db/banco";
import IInfluenciador from "../../../../../types/IInfluenciador";

export default function LeituraInfluenciadorFinanceiro() {
    // Pegar parametro da página
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const parametro = params.get("id");
    // recebe e controla o influenciador
    const [influenciador, setInfluenciador] = useState<IInfluenciador[]>([]);
    const [email, setEmail] = useState("");
    // puxar foto de perfil
    const [foto, setFoto] = useState("");
    const [downloadFoto, setDownloadFoto] = useState("");
    // Midia kit
    const [downloadMidiaKit, setDownloadMidiaKit] = useState("");

    // banco de dados
    const useCollectionRef = collection(db, "influenciador");
    // puxar dados
    useEffect(() => {
        const obterAnunciantes = async () => {
            const dataBD = await getDocs(useCollectionRef);
            const todosInfluenciadores: IInfluenciador[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as IInfluenciador)
            const filtrado: IInfluenciador[] = todosInfluenciadores.filter(filtrado => filtrado.id === parametro);
            setInfluenciador(filtrado);
            
            filtrado.map(fil => {
                setEmail(fil.email);
            })
        }
        obterAnunciantes();
    }, [])

    // Lista caminho, edita e fazer download da imagem
    useEffect(() => {
        // Verifica se o email está carregado antes de buscar a imagem
        if (email) {
            const fetchData = async () => {
                try {
                    const storage = getStorage();
                    const listRef = ref(storage, `gs://ism-teste.appspot.com/influenciador/${email}/fotoDoPerfil`);
                    const listaCaminhosPdfs: any = [];

                    const res = await listAll(listRef);
                    res.items.forEach((itemRef) => {
                        listaCaminhosPdfs.push(itemRef.fullPath);
                    });


                    if (listaCaminhosPdfs.length > 0) {
                        const url = await getDownloadURL(ref(storage, listaCaminhosPdfs[listaCaminhosPdfs.length - 1]));
                        setDownloadFoto(url);
                    }
                } catch (error) {
                    console.error('Erro:', error);
                }
            };
            fetchData();
        }
    }, [email]);

    // Lista caminho, edita e fazer download do midia kit
    useEffect(() => {
        // Verifica se o email está carregado antes de buscar a imagem
        if (email) {
            const fetchData = async () => {
                try {
                    const storage = getStorage();
                    const listRef = ref(storage, `gs://ism-teste.appspot.com/influenciador/${email}/midiakit`);
                    const listaCaminhosPdfs: any = [];

                    const res = await listAll(listRef);
                    res.items.forEach((itemRef) => {
                        listaCaminhosPdfs.push(itemRef.fullPath);
                    });


                    if (listaCaminhosPdfs.length > 0) {
                        const url = await getDownloadURL(ref(storage, listaCaminhosPdfs[listaCaminhosPdfs.length - 1]));
                        setDownloadMidiaKit(url);
                    }
                } catch (error) {
                    console.error('Erro:', error);
                }
            };
            fetchData();
        }
    }, [email]);

    function baixarPdf() {
        window.open(downloadMidiaKit);
    }
    return (
        <section className={styles.LeituraInfluenciador}>
            {influenciador.map(influ => (
                <h2> Perfil de {influ.nomeCompleto} </h2>
            ))}
            <form>
                {downloadFoto ? <img src={downloadFoto} alt="foto de perfil" /> : <img src={pessoa} alt="foto de perfil" />}
                {influenciador.map(inf => (
                    <>
                        <div>
                            <EmailIcon fontSize="large" />
                            <label> <strong> Email: </strong> {inf.email} </label>
                        </div>

                        <div>
                            <PersonSearchIcon fontSize="large" />
                            <label> <strong> Tipo de pessoa: </strong> {inf.tipoDePessoa} </label>
                        </div>

                        <div>
                            <InstagramIcon fontSize="large" />
                            <label> <strong> Rede Social Principal: </strong> {inf.redeSocialPrincipal} </label>
                        </div>

                        <div>
                            <AlternateEmailIcon fontSize="large" />
                            <label> <strong> @ principal: </strong> {inf.arrobaPrincipal} </label>
                        </div>

                        <div>
                            <EightKIcon fontSize="large" />
                            <label> <strong> Quantidade de seguidores: </strong> {inf.quantidadeDeSeguidores} </label>
                        </div>

                        <div>
                            <CategoryIcon fontSize="large" />
                            <label> <strong> Categoria: </strong> {inf.categoria} </label>
                        </div>

                        <div>
                            <MapIcon fontSize="large" />
                            <label> <strong> Estado: </strong> {inf.estado} </label>
                        </div>

                        <div>
                            <ForkLeftIcon fontSize="large" />
                            <label> <strong> Cidade: </strong> {inf.cidade} </label>
                        </div>

                        <div>
                            <DateRangeIcon fontSize="large" />
                            <label> <strong> Data de nascimento: </strong> {inf.data.split("-").reverse().join("/")} </label>
                        </div>

                        <div>
                            <PortraitIcon fontSize="large" />
                            <label> <strong> Gênero: </strong> {inf.genero} </label>
                        </div>

                        <div>
                            <SmartphoneIcon fontSize="large" />
                            <label> <strong> Telefone: </strong> {inf.telefone} </label>
                        </div>

                        <div>
                            <InfoIcon fontSize="large" />
                            <label> <strong> Sobre: </strong> {inf.sobre} </label>
                        </div>

                        <div>
                            <NorthEastIcon fontSize="large" />
                            <label> <strong> CEP: </strong> {inf.cep} </label>
                        </div>

                        <div>
                            <LooksOneIcon fontSize="large" />
                            <label> <strong> Número: </strong> {inf.numero} </label>
                        </div>

                        <div>
                            <EditLocationIcon fontSize="large" />
                            <label> <strong> Complemento: </strong> {inf.complemento} </label>
                        </div>

                        <div>
                            <DirectionsIcon fontSize="large" />
                            <label> <strong> Logradouro: </strong> {inf.logradouro} </label>
                        </div>

                        <div>
                            <MapsHomeWorkIcon fontSize="large" />
                            <label> <strong> Bairro: </strong> {inf.bairro} </label>
                        </div>

                        <div>
                            <AccountBalanceOutlinedIcon fontSize="large" />
                            <label> <strong> Banco: </strong> {inf.banco} </label>
                        </div>

                        <div>
                            <CommentBankOutlinedIcon fontSize="large" />
                            <label> <strong> Agência: </strong> {inf.agencia} </label>
                        </div>

                        <div>
                            <AddCardOutlinedIcon fontSize="large" />
                            <label> <strong> Conta: </strong> {inf.conta} </label>
                        </div>

                        <div>
                            <SwitchAccountIcon fontSize="large" />
                            <label> <strong> Tipo de conta: </strong> {inf.tipoDeContaBancaria} </label>
                        </div>

                        {inf.tipoDePessoa === "Pessoa física" ? <div>
                            <PinIcon fontSize="large" />
                            <label> <strong> CPF: </strong> {inf.cpf} </label>
                        </div> : <div>
                            <PinIcon fontSize="large" />
                            <label> <strong> CNPJ: </strong> {inf.cnpj} </label>
                        </div>}

                        <div>
                            <MoneyIcon fontSize="large" />
                            <label> <strong> Código do banco: </strong> {inf.codigoDoBanco} </label>
                        </div>


                    </>
                ))}
            </form>
            <div className={styles.MidiaKit}>
                <button onClick={baixarPdf} className="btn btn-primary"> Visualizar mídia kit </button>
            </div>
        </section>
    )
}