import styles from "./EstatisticaDaCampanha.module.css";
import { Link } from "react-router-dom";
import IosShareIcon from '@mui/icons-material/IosShare';

export default function EstatisticaDaCampanha() {
    const mockDescricao = 'Campanha de lancamento da Jinx da Riot Games';
    return (
        <div className={styles.EstatisticaDaCampanha}>            
            <h3> Estatística da campanha </h3>
            <Link to=""> <span> Ver campanha <IosShareIcon fontSize="small" /> </span><br /></Link>

            <label> Descrição: {mockDescricao} </label>

            <p> Os influenciadores irão receber uma máquina de café + Cupom nominal + 5,6%
                de todas as vendas geradas no link personalizado
            </p>

            <ul>
                <li><strong> Encerra inscrição:</strong> 15/07/2022</li>
                <li><strong> Quantidade de influenciadores, total:</strong> 4</li>
                <li><strong> Quantidade de influenciadores aptos a serrem marcador:</strong> 1 </li>
                <li><strong> Valor total atualmente </strong> Permuta </li>
            </ul><br />

            <hr /><br />

            <h3> Influenciadores nesta Campanha </h3>

            <p> Você agora tem acesso a cada influenciador ativo nesta campanha. Marque se
                o influenciador fez o trabalho proposto.
            </p>

            <h4> <strong> OBS:  Você está vendo APENAS influenciadores: </strong></h4>

            <ul>
                <li> *Ativos </li>
                <li> *Que marcaram que já fizeram o trabalho </li>
            </ul>

            <p> Ao marcar que o influenciador fez o trabalho e clicar no botão TRABALHO FEITO,
                o influenciador será avisado e poderá fazer o pedido pra o resgate de seu pagamento.
            </p>
        </div>
    );
}