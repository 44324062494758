import styles from "./PerfilInfluenciador.module.css"
import { useState, useEffect } from "react";
import PersonIcon from '@mui/icons-material/Person';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import InstagramIcon from '@mui/icons-material/Instagram';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import ScoreIcon from '@mui/icons-material/Score';
import CategoryIcon from '@mui/icons-material/Category';
import MapIcon from '@mui/icons-material/Map';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import SmartphoneOutlinedIcon from '@mui/icons-material/SmartphoneOutlined';
import Person3OutlinedIcon from '@mui/icons-material/Person3Outlined';
import SignpostIcon from '@mui/icons-material/Signpost';
import SixtyFpsIcon from '@mui/icons-material/SixtyFps';
import WrapTextIcon from '@mui/icons-material/WrapText';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import ImageSearchOutlinedIcon from '@mui/icons-material/ImageSearchOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { collection, doc, getDocs, updateDoc } from "firebase/firestore/lite";
import { useLocation, useNavigate } from "react-router-dom";
import { getDownloadURL, getStorage, listAll, ref, uploadBytesResumable } from "firebase/storage";
import pessoa from "./pessoa.png";
import { estados, obterNomesDasCidadesPorEstadoNome } from "../../../../utils/apiIbge";
import { db, storage } from "../../../../db/banco";
import IInfluenciador from "../../../../types/IInfluenciador";
import CampoEscolha from "../../../../components/CampoEscolha";
import { listaTipoDePessoa } from "../../../../utils/listaTipoDePessoa";
import { listaRedesSociais } from "../../../../utils/listaRedesSociais";
import { listaQuantidadeDeSeguidores } from "../../../../utils/listaQuantidadeDeSeguidores";
import { listaCategorias } from "../../../../utils/listaCategorias";
import { listaGeneros } from "../../../../utils/listaGerenos";
import { mascaraCEP, mascaraCnpj, mascaraCpf, mascaraTelefone } from "../../../../utils/mascaras";
import AreaTextoMenor from "../../../../components/AreaTextoMenor";
import BotaoSubmit from "../../../../components/BotaoSubmit";
import CampoTexto from "../../../../components/CampoTexto";
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import CommentBankOutlinedIcon from '@mui/icons-material/CommentBankOutlined';
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import PinIcon from '@mui/icons-material/Pin';
import MoneyIcon from '@mui/icons-material/Money';
import guardaPaginaAnterior from "../../../../stores/paginaAnterior";
import TextField from "../../../../components/TextField";
import ChoiceField from "../../../../components/ChoiceField";
import SmallTextArea from "../../../../components/SmallTextArea";

export default function EditarPerfilInfluenciador() {
    // Pegar parametro da página
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const emailId = params.get("id"); // Parâmetro da página
    // pagina
    const paginaParametro = params.get("pg");
    // obter o id
    const [idInfluenciador, setIdInfluenciador] = useState("")
    // Atributos já cadastrados
    const [nomeCompleto, setNomeCompleto] = useState("");
    const [email, setEmail] = useState("");
    const [tipoDePessoa, setTipoDePessoa] = useState("");
    const [redeSocialPrincipal, setRedeSocialPrincipal] = useState("");
    const [arrobaPrincipal, setArrobaPrincipal] = useState("");
    const [quantidadeDeSeguidores, setQuantidadeDeSeguidoes] = useState("");
    const [categoria, setCategoria] = useState("");
    const [estado, setEstado] = useState("");
    const [cidade, setCidade] = useState("");
    // aux para pegar as cidades
    const [nomesDasCidades, setNomesDasCidades] = useState<string[]>([]);
    // Restro dos atributos
    const [data, setData] = useState("");
    const [genero, setGenero] = useState("");
    const [telefone, setTelefone] = useState("");
    const [senha, setSenha] = useState("");
    const [confirmarSenha, setConfirmarSenha] = useState("");
    // Controla se as senhas criadas são iguais
    const [senhaDiferente, setSenhaDiferente] = useState(false);
    // Usado para navegar entre rotas
    // Atributos pós cadastros
    const [sobre, setSobre] = useState("");
    const [cep, setCep] = useState("");
    const [numero, setNumero] = useState("");
    const [complemento, setComplemento] = useState("");
    const [logradouro, setLogradouro] = useState("");
    const [bairro, setBairro] = useState("");
    // Redes sociais e arrobas adicionais
    const [redesSociais, setRedesSociais] = useState([""]);
    const [arrobas, setArrobas] = useState([""]);

    // imagens

    const [progress, setProgress] = useState(0);
    const [foto, setFoto] = useState("");
    const [downloadFoto, setDownloadFoto] = useState("");
    // MidiaKit
    const [midiaKit, setMidiaKit] = useState("");
    const [downloadMidiaKit, setDownloadMidiaKit] = useState("");
    // dados bancários
    const [banco, setBanco] = useState("");
    const [agencia, setAgencia] = useState("");
    const [conta, setConta] = useState("");
    const [tipoDeContaBancaria, setTipoDeContaBancaria] = useState("");
    const listaTipoDeContaBancario = ["Corrente", "Poupança"];
    const [cpf, setCpf] = useState("");
    const [cnpj, setCnpj] = useState("");
    const [codigoDoBanco, setCodigoDoBanco] = useState("");

    // Usado para navegar entre rotas
    const navigate = useNavigate();

    // salva a Página
    useEffect(() => {
        if (paginaParametro) guardaPaginaAnterior.salvarPagina(paginaParametro, true);
    }, [paginaParametro]);

    // Puxa a lista de estados pela api do IBGE
    const nomesEstados = estados.map((estado) => estado.nome);
    nomesEstados.sort();

    useEffect(() => {
        if (estado) {
            obterNomesDasCidadesPorEstadoNome(estado)
                .then(nomesDasCidades => {
                    // console.log(nomesDasCidades);
                    // Atualize o estado das cidades com os nomes obtidos
                    setCidade('');
                    setNomesDasCidades(nomesDasCidades);
                })
                .catch(error => {
                    console.error('Ocorreu um erro:', error);
                });
        }
    }, [estado]);

    // banco de dados
    const useCollectionRef = collection(db, "influenciador");
    // puxar dados
    useEffect(() => {
        const obterAnunciantes = async () => {
            const dataBD = await getDocs(useCollectionRef);
            const todosInfluenciadores: IInfluenciador[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as IInfluenciador)
            const filtrado: IInfluenciador[] = todosInfluenciadores.filter(filtrado => filtrado.email === emailId)
            console.log("editar perfil:", filtrado);

            filtrado.forEach(influenciador => {
                if ("id" in influenciador) setIdInfluenciador(influenciador.id)
                if ("nomeCompleto" in influenciador) setNomeCompleto(influenciador.nomeCompleto as string)
                if ("email" in influenciador) setEmail(influenciador.email as string)
                if ("tipoDePessoa" in influenciador) setTipoDePessoa(influenciador.tipoDePessoa);
                if ("redeSocialPrincipal" in influenciador) setRedeSocialPrincipal(influenciador.redeSocialPrincipal);
                if ("arrobaPrincipal" in influenciador) setArrobaPrincipal(influenciador.arrobaPrincipal);
                if ("quantidadeDeSeguidores" in influenciador) setQuantidadeDeSeguidoes(influenciador.quantidadeDeSeguidores);
                if ("categoria" in influenciador) setCategoria(influenciador.categoria);
                if ("estado" in influenciador) setEstado(influenciador.estado as string);
                if ("cidade" in influenciador) setCidade(influenciador.cidade as string);
                if ("data" in influenciador) setData(influenciador.data);
                if ("genero" in influenciador) setGenero(influenciador.genero);
                if ("telefone" in influenciador) setTelefone(influenciador.telefone as string);
                if ("senha" in influenciador) {
                    setSenha(influenciador.senha as string);
                    setConfirmarSenha(influenciador.senha as string);
                }
                //
                if ("sobre" in influenciador) setSobre(influenciador.sobre);
                if ("cep" in influenciador) setCep(influenciador.cep as string);
                if ("numero" in influenciador) setNumero(influenciador.numero as string);
                if ("complemento" in influenciador) setComplemento(influenciador.complemento as string);
                if ("logradouro" in influenciador) setLogradouro(influenciador.logradouro as string);
                if ("bairro" in influenciador) setBairro(influenciador.bairro as string);
                // dados bancários
                if ("banco" in influenciador) setBanco(influenciador.banco);
                if ("agencia" in influenciador) setAgencia(influenciador.agencia);
                if ("conta" in influenciador) setConta(influenciador.conta);
                if ("tipoDeContaBancaria" in influenciador) setTipoDeContaBancaria(influenciador.tipoDeContaBancaria);
                if ("cpf" in influenciador) setCpf(influenciador.cpf);
                if ("cnpj" in influenciador) setCnpj(influenciador.cnpj);
                if ("codigoDoBanco" in influenciador) setCodigoDoBanco(influenciador.codigoDoBanco);

            })
        }
        obterAnunciantes();
    }, [])

    async function editarInfluenciador(novosDados: any) {
        try {
            const influenciadorDocRef = doc(db, "influenciador", idInfluenciador);

            await updateDoc(influenciadorDocRef, novosDados);
            alert("Dados editados");
        } catch (error) {
            alert("Nao deu certo")
        }
    }

    function concluirCadastro(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        // Verifica se a senha criada é igual ao confirmar senha
        if (senha !== confirmarSenha) {
            setSenhaDiferente(true);
            return
        }
        const influenciador = {
            nomeCompleto,
            email,
            tipoDePessoa,
            redeSocialPrincipal,
            arrobaPrincipal,
            quantidadeDeSeguidores,
            categoria,
            estado,
            cidade,
            data,
            genero,
            telefone,
            senha,
            redesSociais,
            arrobas,
            sobre,
            cep,
            numero,
            complemento,
            logradouro,
            bairro,
            banco,
            agencia,
            conta,
            tipoDeContaBancaria,
            cpf,
            cnpj,
            codigoDoBanco
        }

        editarInfluenciador(influenciador);
        console.log(influenciador);
        // navigate("/login");
    }
    // subir foto
    const uploadFoto = (event: any) => {
        event.preventDefault();
        const file = event.target[0]?.files[0];
        if (!file) return;

        const storageRef = ref(storage, `influenciador/${email}/fotoDoPerfil/foto`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            "state_changed",
            snapshot => {
            },
            error => {
                alert(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then(url => {
                    setFoto(url);
                    alert(`Arquivo enviado com sucesso!`);
                    navigate("/moderador/inicio");
                })
            }
        )
    }
    // subir midia kit
    const uploadMidiaKit = (event: any) => {
        event.preventDefault();
        const file = event.target[0]?.files[0];
        if (!file) return;

        const storageRef = ref(storage, `influenciador/${email}/midiakit/midia`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            "state_changed",
            snapshot => {
            },
            error => {
                alert(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then(url => {
                    setMidiaKit(url);
                    alert(`Arquivo enviado com sucesso!`);
                    navigate("/moderador/inicio");
                })
            }
        )
    }

    const [caminhoFoto, setCaminhoFoto] = useState([]);

    // Lista caminho, edita e fazer download da imagem
    useEffect(() => {
        // Verifica se o email está carregado antes de buscar a imagem
        if (email) {
            const fetchData = async () => {
                try {
                    const storage = getStorage();
                    const listRef = ref(storage, `gs://ism-teste.appspot.com/influenciador/${email}/fotoDoPerfil`);
                    const listaCaminhosPdfs: any = [];

                    const res = await listAll(listRef);
                    res.items.forEach((itemRef) => {
                        listaCaminhosPdfs.push(itemRef.fullPath);
                    });
                    setCaminhoFoto(listaCaminhosPdfs);

                    if (listaCaminhosPdfs.length > 0) {
                        const url = await getDownloadURL(ref(storage, listaCaminhosPdfs[listaCaminhosPdfs.length - 1]));
                        setDownloadFoto(url);
                    }
                } catch (error) {
                    console.error('Erro:', error);
                }
            };
            fetchData();
        }
    }, [email]);

    const [caminhoMidia, setCaminhoMidia] = useState([]);

    // Lista caminho, edita e fazer download do midia kit
    useEffect(() => {
        // Verifica se o email está carregado antes de buscar a imagem
        if (email) {
            const fetchData = async () => {
                try {
                    const storage = getStorage();
                    const listRef = ref(storage, `gs://ism-teste.appspot.com/influenciador/${email}/midiakit`);
                    const listaCaminhosPdfs: any = [];

                    const res = await listAll(listRef);
                    res.items.forEach((itemRef) => {
                        listaCaminhosPdfs.push(itemRef.fullPath);
                    });
                    setCaminhoFoto(listaCaminhosPdfs);

                    if (listaCaminhosPdfs.length > 0) {
                        const url = await getDownloadURL(ref(storage, listaCaminhosPdfs[listaCaminhosPdfs.length - 1]));
                        setDownloadMidiaKit(url);
                    }
                } catch (error) {
                    console.error('Erro:', error);
                }
            };
            fetchData();
        }
    }, [email]);

    function baixarPdf() {
        window.open(downloadMidiaKit);
    }

    return (
        <section className={styles.PerfilInfluenciador}>
            <h2> Editar perfil do influenciador {nomeCompleto} </h2>

            <form onSubmit={concluirCadastro}>
                <h3> Dados cadastrais do influenciador: </h3>

                <TextField
                    title="Nome completo"
                    value={nomeCompleto}
                    control={setNomeCompleto}
                    icon={<PersonIcon fontSize="large" />}
                    placeholder="João da Silva Pereira"
                />

                <ChoiceField
                    title="Tipo de pessoa"
                    list={listaTipoDePessoa}
                    control={setTipoDePessoa}
                    icon={<PersonSearchIcon fontSize="large" />}
                    defaultValue={tipoDePessoa ? tipoDePessoa : "Escolha seu perfil"}
                />

                <ChoiceField
                    title="Rede social principal"
                    list={listaRedesSociais}
                    control={setRedeSocialPrincipal}
                    icon={<InstagramIcon fontSize="large" />}
                    defaultValue={redeSocialPrincipal ? redeSocialPrincipal : "Escolha sua rede social"}
                />

                <TextField
                    title="Seu @ principal"
                    value={arrobaPrincipal}
                    control={setArrobaPrincipal}
                    icon={<AlternateEmailIcon fontSize="large" />}
                    placeholder="@joaosilva"
                />

                <ChoiceField
                    title="Quantidade de seguidores"
                    list={listaQuantidadeDeSeguidores}
                    control={setQuantidadeDeSeguidoes}
                    icon={<ScoreIcon fontSize="large" />}
                    defaultValue={quantidadeDeSeguidores ? quantidadeDeSeguidores : "Escolha a quantidade"}
                />
                
                <ChoiceField
                    title="Categoria"
                    list={listaCategorias}
                    control={setCategoria}
                    icon={<CategoryIcon fontSize="large" />}
                    defaultValue={categoria ? categoria : "Escolha a categoria"}
                />

                <ChoiceField
                    title="Estado"
                    list={nomesEstados}
                    control={setEstado}
                    icon={<MapIcon fontSize="large" />}
                    defaultValue={estado ? estado : "Escolha seu estado"}
                />

                <ChoiceField
                    title="Cidade"
                    list={nomesDasCidades}
                    control={setCidade}
                    icon={<LocationOnIcon fontSize="large" />}
                    defaultValue={cidade ? cidade : "Escolha sua cidade"}
                />

                <TextField
                    title="Data de nascimento"
                    value={data}
                    control={setData}
                    icon={<PersonIcon fontSize="large" />}
                    type="date"
                />

                <ChoiceField
                    title="Gênero"
                    list={listaGeneros}
                    control={setGenero}
                    icon={<SwitchAccountIcon fontSize="large" />}
                    defaultValue={genero ? genero : "Escolha seu gênero"}                    
                />

                <TextField
                    title="Telefone"
                    value={mascaraTelefone(telefone)}
                    control={setTelefone}
                    icon={<SmartphoneOutlinedIcon fontSize="large" />}
                    placeholder="(99) 99999-9999"
                />

                <hr />

                <h3> Dados pós cadastro </h3>

                <SmallTextArea
                    title="Sobre você"
                    value={sobre}
                    control={setSobre}
                    collumns={30}
                    lines={3}
                    placeholder="ou eclética e animada..."
                    icon={<Person3OutlinedIcon fontSize="large" />}
                />

                <TextField
                    title="CEP"
                    value={mascaraCEP(cep)}
                    control={setCep}
                    icon={<SignpostIcon fontSize="large" />}
                    placeholder="99999-999"
                />

                <TextField
                    title="Número"
                    value={numero}
                    control={setNumero}
                    icon={<SixtyFpsIcon fontSize="large" />}
                    placeholder="136"
                />

                <TextField
                    title="Complemento"
                    value={complemento}
                    control={setComplemento}
                    icon={<WrapTextIcon fontSize="large" />}
                    placeholder="apartamento 807"
                />

                <TextField
                    title="Logradouro"
                    value={logradouro}
                    control={setLogradouro}
                    icon={<EditRoadIcon fontSize="large" />}
                    placeholder="Rua José Maria"
                />

                <TextField
                    title="Bairro"
                    value={bairro}
                    control={setBairro}
                    icon={<MapsHomeWorkIcon fontSize="large" />}
                    placeholder="Rua José Maria"
                />

                <hr />

                <h3> Dados bancários </h3>

                <TextField
                    title="Banco"
                    value={banco}
                    control={setBanco}
                    icon={<AccountBalanceOutlinedIcon fontSize="large" />}
                    placeholder="Itaú"
                />

                <TextField
                    title="Agência"
                    value={agencia}
                    control={setAgencia}
                    icon={<CommentBankOutlinedIcon fontSize="large" />}
                    placeholder="00000"
                />

                <TextField
                    title="Conta"
                    value={conta}
                    control={setConta}
                    icon={<AddCardOutlinedIcon fontSize="large" />}
                    placeholder="00000000"
                />

                <ChoiceField
                    title="Tipo de conta"
                    list={listaTipoDeContaBancario}
                    control={setTipoDeContaBancaria}
                    icon={<SwitchAccountIcon fontSize="large" />}
                    defaultValue={tipoDeContaBancaria ? tipoDeContaBancaria : "Corrente ou Poupança"}
                />

                {tipoDePessoa === "Pessoa física" ? <TextField
                    title="CPF"
                    value={mascaraCpf(cpf)}
                    control={setCpf}
                    icon={<PinIcon fontSize="large" />}
                    placeholder="000.000.000-00"
                />


                    : <TextField
                        title="CNPJ"
                        value={mascaraCnpj(cnpj)}
                        control={setCnpj}
                        icon={<PinIcon fontSize="large" />}
                        placeholder="99.999.999/9999-99"
                    />
                }                

                <TextField
                    title="Código do banco"
                    value={codigoDoBanco}
                    control={setCodigoDoBanco}
                    icon={<MoneyIcon fontSize="large" />}
                    placeholder="0000"
                />

                <div className={styles.BotoesNavegação}>
                    <BotaoSubmit classe="btn btn-secondary">
                        Editar
                    </BotaoSubmit>
                </div>
            </form>

            <form onSubmit={uploadFoto}>
                <h3> Sua foto de perfil </h3>
                {/* <img src={downloadFoto} alt="foto de perfil" />               */}
                {downloadFoto ? <img src={downloadFoto} alt="foto de perfil" /> : <img src={pessoa} alt="foto de perfil" />}
                <CampoTexto
                    titulo="Insira sua foto"
                    valor={foto}
                    controle={setFoto}
                    type="file"
                    icone={<ImageSearchOutlinedIcon fontSize="large" />}
                />

                {/*{caminhoPdf.map(pdf => (
                    <p> {pdf} </p>
                ))}*/}

                {/* <img src={downloadFoto} alt="" /> */}

                <button className="btn btn-success">
                    Enviar
                </button>
            </form>

            <form onSubmit={uploadMidiaKit} className={styles.FormPdf}>
                <h3> Insira seu mídia kit </h3>

                <CampoTexto
                    titulo="Insira seu arquivo"
                    valor={midiaKit}
                    controle={setMidiaKit}
                    type="file"
                    icone={<FileCopyOutlinedIcon fontSize="large" />}
                />

                <button className="btn btn-success">
                    Enviar
                </button>

                <button onClick={baixarPdf} className="btn btn-primary"> Visualizar mídia kit </button>
            </form>
        </section>
    );
}