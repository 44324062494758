import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import IChatPropostas from '../../../../../types/ICharPropostas';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import TipoCampanha from '../../../../Anunciante/EstatisticasDeCampanha/TipoCampanha';
import { listaTiposDeCampanha } from '../../../../../utils/listas';
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';

interface TabelaInfluenciadorProps {
    campanhas?: IChatPropostas[],
    tem?: boolean
}

export default function DadosCampInflu({campanhas, tem}: TabelaInfluenciadorProps) {
    const [tabela, setTabela] = useState(true);     

    useEffect(() => {
        function largura() {
            if (window.innerWidth < 650) setTabela(false);
        }
        largura();
    }, [])    

    return (
        <>
            <h2> Resultado da pesquisa </h2>
            {tabela ? <table className="table table-light table-striped text-center ">
                <thead>
                    <tr>
                        <th scope="col" className="text-white"> Nome </th>
                        <th scope="col" className="text-white"> Tipo </th>
                        {/* <th scope="col" className="text-white"> Payout </th> */}
                        <th scope="col" className="text-white"> Criado em </th>                        
                        <th scope="col" className="text-white"> Detalhes </th>
                    </tr>
                </thead>
                {campanhas?.map(cel => (
                    
                    <tbody>
                        <tr>
                            <td> <p> {cel.nomeCampanha} </p> </td>
                            <td> <p> {cel.tipoCampanha} </p> </td>
                            {/* <td> R$250,00 </td> */}
                            <td>  {cel.criadoEm.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1')} </td>                            
                            <td>
                                <div className="divAcoesTabela">                                    
                                    <Link to={`/influenciador/detalhes-campanha?id=${cel.idCampanha}`}> <ArrowForwardOutlinedIcon /> </Link>                                    
                                    {tem && 
                                        <>  
                                            {cel.tipoCampanha === listaTiposDeCampanha[2] || cel.tipoCampanha === listaTiposDeCampanha[3] 
                                                || cel.tipoCampanha === listaTiposDeCampanha[4] ? <Link to={`/influenciador/estatisticas-de-campanha?id=${cel.codigoActionpay}`}> <EqualizerOutlinedIcon style={{ color: 'orange' }}/> </Link> : 
                                                <Link to={`/influenciador/enviar-trabalho?id=${cel.idCampanha}`} > 
                                                    <CheckCircleOutlineIcon style={{ color: 'green' }} /> 
                                                </Link>}
                                        </>
                                    }  
                                </div>
                            </td>
                        </tr>
                    </tbody>
                ))}
            </table> : <div>
                {campanhas?.map(cel => (
                    <ul className="lista">
                        <li className="Itemlista"> . </li>                        
                        <li className="Itemlista"> <strong> Nome: </strong> {cel.nomeCampanha}  </li>
                        <li className="Itemlista"> <strong> Tipo: </strong> {cel.tipoCampanha}  </li>
                        {/* <li className="Itemlista"> <strong> Payout: </strong> R$250,00 </li> */}
                        <li className="Itemlista"> <strong> Termina: </strong> {cel.criadoEm.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1')} </li>                        
                        <li className="Itemlista">
                            <div className="divAcoes">
                                <strong> Detalhes: </strong>
                                <Link to={`/influenciador/detalhes-campanha?id=${cel.idCampanha}`}> <ArrowForwardOutlinedIcon /> </Link>                      
                                {tem && <Link to={`/influenciador/enviar-trabalho?id=${cel.idCampanha}`} > <CheckCircleOutlineIcon style={{ color: 'green' }} /> </Link>}                      
                            </div>
                        </li>
                    </ul>
                ))}
            </div>}

        </>
    )
}