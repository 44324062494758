import styles from "./AnuncianteFormulario.module.css";
import { useEffect, useState } from "react";
import CampoTexto from "../../../../components/CampoTexto";
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import SmartphoneOutlinedIcon from '@mui/icons-material/SmartphoneOutlined';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import LockClockOutlinedIcon from '@mui/icons-material/LockClockOutlined';
import { mascaraCnpj, mascaraTelefone } from "../../../../utils/mascaras";
import BotaoSubmit from "../../../../components/BotaoSubmit";
import { useNavigate } from "react-router-dom";
import Alertas from "../../../../components/Alertas";
import PlanoISM from "../../../../components/PlanoISM";
// import { v4 as uuidv4 } from 'uuid';
import { addDoc, collection, getDocs } from "firebase/firestore/lite";
import { auth, db } from "../../../../db/banco";
import { useCreateUserWithEmailAndPassword } from 'react-firebase-hooks/auth';
import IAnunciante from "../../../../types/IAnunciante";
import axios from "axios";

export default function AnuncianteFormulario() {
    // Autenticar
    const [
        createUserWithEmailAndPassword,
        user,
        loading,
        error
    ] = useCreateUserWithEmailAndPassword(auth);
    // Define por padrao o tipo da conta
    const tipoDaConta = 'Anunciante';
    // atributos
    const [nomeCompleto, setNomeCompleto] = useState("");
    const [email, setEmail] = useState("");
    const [cnpj, setCnpj] = useState("");
    const [razaoSocial, setRazaoSocial] = useState("");
    const [telefone, setTelefone] = useState("");
    const [senha, setSenha] = useState("");
    const [confirmarSenha, setConfirmarSenha] = useState("");
    // Só tem um plano e salva como id 1
    const planoISM = 1
    // Controla se as senhas criadas são iguais
    const [senhaDiferente, setSenhaDiferente] = useState(false);
    // Usado para navegar entre rotas
    const navigate = useNavigate();

    // banco de dados
    const useCollectionRef = collection(db, "anunciante");

    const [arrayEmail, setArrayEmail] = useState([""]);
    const [emailJaUsado, setEmailJaUsado] = useState(false)
    // verifica se o email já é cadastrado
    useEffect(() => {
        const obterUsuario = async () => {
            const dataBD = await getDocs(useCollectionRef);
            const todosAnunciantes: IAnunciante[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as IAnunciante);
            const soEmails = todosAnunciantes.map(so => so.email);            
            setArrayEmail(soEmails)
        };
        obterUsuario();
    }, [])

    const enviarEmail = async () => {
        try {
            const response = await axios.post(
                "https://us-central1-ism-teste.cloudfunctions.net/enviarEmail",
                {
                    destinatario: email,
                    assunto: `Boas vindas, ${nomeCompleto}!`,
                    mensagem: "Estamos mega felizes em poder contar com você para agitar o mercado de influência digital.\n" +
                    "Conte com a gente para influenciar sempre mais.\n" +
                    "Equipe ISM"                    
                }
            );

            const { id } = response.data;
            return id;
        } catch (error) {
            // console.log(error);
        }
    };

    async function cadastrar(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        // Verifica se a senha criada é igual ao confirmar senha
        if (senha !== confirmarSenha) {
            setSenhaDiferente(true);
            return
        }
        // verifica o email, se já é cadastrado
        if (arrayEmail.includes(email)) {
            setEmailJaUsado(true)
            return;
        }

        const anunciante = await addDoc(useCollectionRef, {
            tipoDaConta,
            nomeCompleto,
            email,
            cnpj,
            razaoSocial,
            telefone,
            senha,
            planoISM,
            aceito: "espera",
            saldo: 0,
            emailVerificado: false
        })
        createUserWithEmailAndPassword(email, senha);
        
        enviarEmail();
        alert("Conta anunciante criada com sucesso!");
        navigate("/login");
    }

    return (
        // Colocar a função cadastrar
        <form className={styles.AnuncianteFormulario} onSubmit={cadastrar}>
            <CampoTexto
                titulo="Nome completo"
                valor={nomeCompleto}
                controle={setNomeCompleto}
                icone={<PersonIcon fontSize="large" />}
                placeholder="João da Silva Pereira"
                minLength={8}
            />
            {!nomeCompleto && <p> Preencha esse campo </p>}
            {nomeCompleto.length < 8 ? <p> Número mínimo de caracteres: 8 </p> : ""}

            <CampoTexto
                titulo="E-mail"
                valor={email}
                controle={setEmail}
                icone={<EmailIcon fontSize="large" />}
                placeholder="joaosilva@gmail.com"
                type="email"
                minLength={11}
            />
            {!email && <p> Preencha esse campo </p>}
            {email.length < 11 ? <p> Número mínimo de caracteres: 11 </p> : ""}

            {emailJaUsado && <Alertas controle={setSenhaDiferente} mensagem="Email já utilizado" tipoMensagem="warning" />}

            <CampoTexto
                titulo="CNPJ"
                valor={mascaraCnpj(cnpj)}
                controle={setCnpj}
                icone={<AssignmentOutlinedIcon fontSize="large" />}
                placeholder="99.999.999/9999-99"
                minLength={17}
            />
            {!cnpj && <p> Preencha esse campo </p>}
            {cnpj.length < 17 ? <p> Número mínimo de caracteres: 17 </p> : ""}

            <CampoTexto
                titulo="Razão social"
                valor={razaoSocial}
                controle={setRazaoSocial}
                icone={<DriveFileRenameOutlineOutlinedIcon fontSize="large" />}
                placeholder="Padaria pão doce LTDA"
                minLength={3}
            />
            {!razaoSocial && <p> Preencha esse campo </p>}
            {razaoSocial.length < 3 ? <p> Número mínimo de caracteres: 3 </p> : ""}

            <CampoTexto
                titulo="Telefone"
                valor={mascaraTelefone(telefone)}
                controle={setTelefone}
                icone={<SmartphoneOutlinedIcon fontSize="large" />}
                placeholder="(99) 99999-9999"
            />
            {!telefone && <p> Preencha esse campo </p>}
            {telefone.length < 14 ? <p> Número mínimo de caracteres: 14 </p> : ""}

            <CampoTexto
                titulo="Senha"
                valor={senha}
                controle={setSenha}
                icone={<HttpsOutlinedIcon fontSize="large" />}
                placeholder="********"
                type="password"
                minLength={8}
            />
            {!senha && <p> Preencha esse campo </p>}
            {senha.length < 8 ? <p> Número mínimo de caracteres: 8 </p> : ""}

            <CampoTexto
                titulo="Confirmar senha"
                valor={confirmarSenha}
                controle={setConfirmarSenha}
                icone={<LockClockOutlinedIcon fontSize="large" />}
                placeholder="********"
                type="password"
                minLength={8}
            />
            {!confirmarSenha && <p> Preencha esse campo </p>}
            {confirmarSenha.length < 8 ? <p> Número mínimo de caracteres: 8 </p> : ""}

            {senhaDiferente && <Alertas controle={setSenhaDiferente} mensagem="Senhas diferentes" tipoMensagem="warning" />}

            <PlanoISM />

            <div className={styles.BotoesNavegação}>
                <BotaoSubmit classe="btn btn-outline-secondary btn-lg">
                    Cadastrar
                </BotaoSubmit>
            </div>

        </form>
    );
}

