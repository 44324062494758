import { useNavigate } from "react-router-dom";
import styles from "./MeusChamadosSuporte.module.css";
import { collection, getDocs } from "firebase/firestore/lite";

import { useEffect, useState } from "react";
import { db } from "../../../../db/banco";
import IChamado from "../../../../types/IChamado";

export default function MeusChamadosSuporte() {
    const navigate = useNavigate();
  
    const [meusChamados, setMeusChamados] = useState<IChamado[]>([]);

    function irParaChat(id: any) {
        navigate(`/suporte/chat-usuario?id=${id}`);
    }

    const useCollectionRef = collection(db, "abrirChamado");
    // puxar dados
    useEffect(() => {
        const obterChamados = async () => {
            const dataBD = await getDocs(useCollectionRef);
            const todosChamados: IChamado[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id } as IChamado))
            const filtrado = todosChamados.filter(chamado => chamado.ticket === "Suporte/Dúvida");            
            setMeusChamados(filtrado);
        }
        obterChamados();

    }, [])

    function VerPerfil(id: any) {
        navigate(`/suporte/ver-perfil?idDeQuemAbriu=${id}`);
    }

    return (
        <section className={styles.MeusChamadosSuporte}>
            <h2> Meus chamados: Suporte </h2>

            <table className="table table-light table-striped text-center ">
                <thead>
                    <tr>
                        <th scope="col" className="text-white"> Nome </th>
                        <th scope="col" className="text-white"> Assunto </th>
                        <th scope="col" className="text-white"> Status </th>
                        <th scope="col" className="text-white"> Ações </th>
                    </tr>
                </thead>

                {meusChamados.map(cel => (
                    <tbody>
                        <tr>
                            <td> {cel.nomeDeQuemAbriu} </td>
                            <td> {cel.assunto} </td>
                            <td className={`${cel.status === "aberto" ? styles.aberto : styles.fechado}`}>
                                {cel.status}
                            </td>
                            <td> <button className="btn btn-primary" onClick={() => irParaChat(cel.id)}> Ver chat </button></td>
                        </tr>
                    </tbody>
                ))}
            </table>

        </section>
    );
}