import styles from "./CardVantagens.module.css";
import badge from "./badge.png";

interface PropsCardVantagens {
    titulo: string,
    descricao: string,
    parafragoUm: string,
    parafragoDois: string,
    parafragoTres: string
}

export default function CardVantagens({titulo, descricao, parafragoUm, parafragoDois, parafragoTres} : PropsCardVantagens) {
    // Alt em comum em todas as imagens aqui
    const alt = "Pássaro, logo da ISM";
    return(
        <a 
            href="https://influenciesuamarca.com.br/como-funciona-anunciar-no-ism" 
            target="_blank" 
            rel="noreferrer"
        >
            <aside className={styles.CardVantagens}>
                <h2> {titulo} </h2>
                <h3> {descricao} </h3>

                <ul>
                    <li>
                        <img src={badge} alt={alt} />
                        <p> {parafragoUm} </p>
                    </li>
                    <li>
                        <img src={badge} alt={alt} />
                        <p> {parafragoDois} </p>
                    </li>
                    <li>
                        <img src={badge} alt={alt} />
                        <p> {parafragoTres} </p>
                    </li>
                </ul>

                <span> Quero conhecer mais! </span>
            </aside>
        </a>
    );
}