import AnuncianteFormulario from "./AnuncianteFormulario";
import styles from "./Cadastrar.module.css";
import { useState } from "react";
import InfluenciadorFormulario from "./InfluenciadorFormulario";

export default function Cadastrar() {
    // Controla qual tipo de conta sera criada
    const [qualUsuario, setQualUsuario] = useState(true); // true é para influenciador
    // controla o botao clicado
    const [botaoAnunciante, setBotaoAnunciante] = useState("btn btn-outline-secondary");
    const [botaoInfluenciador, setBotaoInfluenciador] = useState("btn btn-dark");

    // Funcao que muda o formulario para Anunciante
    function mudaParaAnunciante() {
        setQualUsuario(false);
        setBotaoAnunciante("btn btn-dark");
        setBotaoInfluenciador("btn btn-outline-secondary");
    }
    // Funcao que muda o formulario para Influenciador
    function mudaParaInfluenciador() {
        setQualUsuario(true);
        setBotaoInfluenciador("btn btn-dark");
        setBotaoAnunciante("btn btn-outline-secondary");
    }
    // Ao abrir a pagina, ir sempre para o topo
    window.scrollTo(0,0);

    return (
        <section className={styles.Cadastrar}>
            <nav>
                <h2> Qual conta, deseja criar? </h2>

                <div>
                    <button type="button" className={botaoInfluenciador} onClick={mudaParaInfluenciador}> Influenciador </button>
                    <button type="button" className={botaoAnunciante} onClick={mudaParaAnunciante}> Anunciante </button>                    
                </div>
            </nav>
            {qualUsuario ? <InfluenciadorFormulario /> : <AnuncianteFormulario />}
        </section>
    );
}