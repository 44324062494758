import { useEffect, useState } from "react";
import IInfluenciador from "../../../../types/IInfluenciador";
import styles from "./TabelaParaCopiar.module.css";
import { collection, getDocs } from "firebase/firestore/lite";
import { db } from "../../../../db/banco";

export default function TabelaParaCopiar() {
    // recebe e controla os influenciadores
    const [influenciadores, setInfluenciadores] = useState<IInfluenciador[]>([]);
    
    const useCollectionRefInfluenciador = collection(db, "influenciador");
    // obtem o influenciador
    useEffect(() => {
        const obterInfluenciadores = async () => {
            const dataBD = await getDocs(useCollectionRefInfluenciador);
            const todosInfluenciadores: IInfluenciador[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as IInfluenciador);
            const analiseInfluenciaodes: IInfluenciador[] = todosInfluenciadores.filter(analise => analise.aceito === "aceito");
            setInfluenciadores(analiseInfluenciaodes);            
            console.log(influenciadores);
        };
        obterInfluenciadores();
    }, []);    

    return (
        <section className={styles.TabelaParaCopiar}>
            <h2> Tela somente do desenvolvedor, para copiar dados dos influenciadores. </h2>

            <div className="table-responsive">
                    <table className="table table-light table-striped text-center">
                        <thead>
                            <tr>
                                <th scope="col" className="text-white"> Nome </th>
                                <th scope="col" className="text-white d-none d-sm-table-cell"> E-mail </th>
                                <th scope="col" className="text-white d-none d-sm-table-cell"> Telefone </th>
                                <th scope="col" className="text-white d-none d-sm-table-cell"> Nicho </th>                                
                            </tr>
                        </thead>
                        <tbody>
                            {influenciadores.map((cel, index) => (
                                <tr key={cel.id} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
                                    <td className="text-wrap" style={{ wordBreak: 'normal' }}>
                                        {cel.nomeCompleto}
                                    </td>
                                    <td className="text-wrap d-none d-sm-table-cell" style={{ wordBreak: 'normal' }}>
                                        {cel.email}
                                    </td>
                                    <td className="text-wrap d-none d-sm-table-cell" style={{ wordBreak: 'normal' }}>
                                        {cel.telefone}
                                    </td>
                                    <td className="text-wrap d-none d-sm-table-cell" style={{ wordBreak: 'normal' }}>
                                        {cel.categoria}
                                    </td>                                    
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

        </section>
    );
}