import FiltroInfluenciador from "../../../components/FiltroInfluenciador";
import aceitoStore from "../../../stores/aceito";
import UsuarioNaoAceitoInfluenciador from "../../UsuarioNaoAceito/UsuarioNaoAceitoInfluenciador";
import styles from "./CampanhasDisponiveis.module.css";

export default function CampanhasDisponiveis() {
    // verifica se usuário foi aceito na plataforma
    const statusPlataforma = aceitoStore.getStatus();

    return (
        <>
            {statusPlataforma === "aceito" ? <section className={styles.CampanhasDisponiveis}>
                <h2> Campanhas disponíveis </h2>

                <FiltroInfluenciador qual="disponiveis" />
            </section> : <UsuarioNaoAceitoInfluenciador />}
        </>
    );
}