import { useEffect, useState } from "react";
import styles from "./EnviaSuaProposta.module.css";
import AreaTextoMenor from "../../../../components/AreaTextoMenor";
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import BotaoSubmit from "../../../../components/BotaoSubmit";
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import IChatPropostas from "../../../../types/ICharPropostas";
import { addDoc, collection, doc, getDocs, updateDoc } from "firebase/firestore/lite";
import { db } from "../../../../db/banco";
import { useLocation } from "react-router-dom";
import usuario from "../../../../Image/usuario.png";
import anunciante from "../../../../Image/anunciante.png";


export default function EnviaSuaProposta() {
    // Pegar parametro da página
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const parametro = params.get("id");
    // Recebe e controla o chat proposta
    const [chatPropostas, setChatPropostas] = useState<IChatPropostas[]>([]);
    // Mensagem
    const [novaMensagem, setNovaMensagem] = useState("");
    // Mensagens existentes
    const [aux, setAux] = useState([""]);
    // enviar e atualizar
    const [atualizar, setAtualizar] = useState(true);
    // banco de dados
    const useCollectionRef = collection(db, "chatPropostas");
    // puxar dados
    useEffect(() => {
        const obterChat = async () => {
            try {
                // pega o chat proposta certo
                const dataBD = await getDocs(useCollectionRef);
                const todosChamados = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id } as IChatPropostas));
                const filtrado = todosChamados.filter(chamado => chamado.id === parametro);
                setChatPropostas(filtrado);
                

                // pega as mensagens já existentes e coloca numa variável auxiliar
                const mensagensJaTem = filtrado.map(men => men?.conversaLadoInfluenciador);
                
                setAux(mensagensJaTem[0]);
            } catch (error) {
                console.error("Erro ao obter chat propostas:", error);
            }
        };

        obterChat();
        // Atualize os dados a cada 5 segundos
        const intervalId = setInterval(obterChat, 2000);

        // Limpar o intervalo quando o componente for desmontado
        return () => clearInterval(intervalId);
    }, [parametro, atualizar]);


    async function editarChamado(novosDados: any) {
        try {
            if (parametro) {
                const chamadoDocRef = doc(db, "chatPropostas", parametro);

                await updateDoc(chamadoDocRef, novosDados);
                // alert("Dados editados");
            }
        } catch (error) {
            alert("Nao deu certo")
        }
    }

    async function enviarMensagem(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        const conversaLadoInfluenciador = [...aux, novaMensagem];

        const conversa = {
            conversaLadoInfluenciador
        }
        await editarChamado(conversa)
        
        setAtualizar(!atualizar);
        setNovaMensagem("");
    }

    return (
        <section className={styles.EnviaSuaProposta}>
            {chatPropostas.map(valorCombinado => (
                <>
                    <h3> Chat com o anunciante da campanha: {valorCombinado.nomeCampanha} </h3>
                    <h3> Valor combinado até o momento: R${valorCombinado.valorCombinado},00 </h3>
                </>
            ))}

            <aside className={styles.Chat}>
                <div className={styles.LadoUsuario}>
                    <img src={anunciante} alt="suporte" />

                    <ul className={styles.MensagemUsuario}>
                        {chatPropostas.map(mensa => (
                            mensa.conversaAnunciante?.map(cadaMensagem => (
                                <li> {cadaMensagem} </li>
                            ))
                        ))}
                    </ul>
                </div>

                <div className={styles.LadoUsuario}>
                    <ul className={styles.PrimeiraMensagem}>
                        {chatPropostas.map(mensa => (
                            mensa.conversaLadoInfluenciador?.map(cadaMensagem => (
                                <li> {cadaMensagem} </li>
                            ))
                        ))}
                    </ul>

                    <img src={usuario} alt="usuário" />


                </div>

            </aside>
            <form onSubmit={enviarMensagem}>
                {/* <CampoTexto
                    valor={mascaraDinheiro(valor)}
                    controle={setValor}
                    titulo="Valor"
                    placeholder="R$100,00"
                    icone={<PaidOutlinedIcon fontSize="large" />}
                /> */}
                <AreaTextoMenor
                    titulo="Digite sua proposta"
                    icone={<CurrencyExchangeOutlinedIcon fontSize="large" />}
                    valor={novaMensagem}
                    controle={setNovaMensagem}
                    placeholder="Gostaria de pedir 10% a mais nessa campanha."
                    linhas={2}
                    colunas={30}
                />

                <BotaoSubmit classe="btn btn-secondary">
                    <SendOutlinedIcon fontSize="large" />
                    Enviar
                </BotaoSubmit>
            </form>

            {/* <div className={styles.ContainerConfirmarCampanha}>
                <h4> Deseja entrar nessa campanha? </h4>
                <button className="btn btn-info"> Confirmar </button>
            </div> */}


        </section>
    );
}