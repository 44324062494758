import React, { useEffect } from 'react';
import styles from "./AdicionarSaldo.module.css";
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react';
import axios from 'axios';
import { useState } from 'react';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CampoTexto from '../../../../components/CampoTexto';
import { mascaraDinheiro } from '../../../../utils/mascaras';
import autenticaStore from '../../../../stores/autentica.store';
import { collection, doc, getDocs, updateDoc } from 'firebase/firestore/lite';
import { db } from '../../../../db/banco';
import IAnunciante from '../../../../types/IAnunciante';
import aceitoStore from '../../../../stores/aceito';
import UsuarioNaoAceitoAnunciante from '../../../UsuarioNaoAceito/UsuarioNaoAceitoAnunciante';

export default function AdicionarSaldo() {  
  // verifica se usuário foi aceito na plataforma
  const statusPlataforma = aceitoStore.getStatus();
  const [preferenceId, setPreferenceId] = useState(null);
  const [valor, setValor] = useState("");
  const [auxValor, setAuxValor] = useState("");
  // obtem o email(serve como id) do anunciante
  const email = autenticaStore.getEmail();
  const [idAnunciante, setIdAnunciante] = useState("");
  const [saldoAnterior, setSaldoAterior] = useState(0);
  // loading
  const [loading, setLoading] = useState(false);

  // banco de dados
  const useCollectionRef = collection(db, "anunciante");
  // puxar dados
  useEffect(() => {
    const obterAnunciantes = async () => {
      const dataBD = await getDocs(useCollectionRef);
      const todosAnunciantes: IAnunciante[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as IAnunciante)
      const filtrado: IAnunciante[] = todosAnunciantes.filter(filtrado => filtrado.email === email)
      
      filtrado.map(esseAnunciante => {
        setIdAnunciante(esseAnunciante.id);
        setSaldoAterior(esseAnunciante.saldo);
      })
    }
    obterAnunciantes();
  }, [])

  initMercadoPago('APP_USR-f91faa40-b318-4640-8440-255104c47612', {
    locale: 'pt-BR',
  });
  // http://localhost:3001/create_reference
  const createPreference = async () => {
    try {
      const response = await axios.post("https://us-central1-ism-teste.cloudfunctions.net/mercadoPago", {
        title: "Saldo ISM",
        quantity: 1,
        price: auxValor
      });

      const { id } = response.data;   
      adicionarNoSaldoEmAnalise();        
      
      return id;
    } catch (error) {
      // console.log(error);
    }

  }

  function adicionarNoSaldoEmAnalise() {
    const novoSaldo = Number(auxValor)
    const anunciante = {
      saldoEmAnalise: novoSaldo,
    }
    editarAnunciante(anunciante);
  }

  async function editarAnunciante(novosDados: any) {
    try {
      const anuncianteDocRef = doc(db, "anunciante", idAnunciante);

      await updateDoc(anuncianteDocRef, novosDados);

      // alert("Dados editados");
    } catch (error) {
      // alert("Nao deu certo")
    }
  }

  // const handleBuy = async () => {
  //   adicionarNoSaldo();
  //   const id = await createPreference();
  //   if (id) {
  //     setPreferenceId(id);
  //   }
  // }

  const handleBuy = async () => {
    try {
      setLoading(true); // Ativa o indicador de carregamento



      const id = await createPreference();

      if (id) {
        setPreferenceId(id);
      }

      setLoading(false); // Desativa o indicador de carregamento
    } catch (error) {
      // console.log(error);
      setLoading(false); // Em caso de erro, também desativa o indicador de carregamento
    }
  };


  useEffect(() => {
    let novoValor = valor.replace(/[^\d,]/g, '');
    setAuxValor(novoValor)
  }, [valor])

  return (
    <>
      {statusPlataforma === "aceito" ? <section className={styles.AdicionarSaldo}>
      <h2> Valor do saldo que deseja comprar </h2>

      <CampoTexto
        titulo='Valor'
        valor={mascaraDinheiro(valor)}
        controle={setValor}
        icone={<AttachMoneyIcon fontSize='large' />}
      />

      {/* <div className={styles.CampoTexto}>
        <label> Valor </label>
        <div>
          <AttachMoneyIcon fontSize='large'/>
          <input
            value={valor}
            onChange={(e) => setValor(e.target.value)}
            placeholder="R$100,00"
            
            required
            
          />
        </div>
      </div> */}



      <button className='btn btn-success ' onClick={handleBuy}> Adicionar </button>
      {loading && <div className="spinner-border text-info" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>}
      {preferenceId && valor !== "" ? <Wallet initialization={{ preferenceId: preferenceId }} /> : ""}

    </section> : <UsuarioNaoAceitoAnunciante />}
    
    </>
  )
}









