import FiltroNotaFiscal from "../../../components/FiltroNotaFiscal";
import aceitoStore from "../../../stores/aceito";
import UsuarioNaoAceitoAnunciante from "../../UsuarioNaoAceito/UsuarioNaoAceitoAnunciante";
import styles from "./NotaFiscal.module.css";

export default function NotaFiscal() {
    // verifica se usuário foi aceito na plataforma
    const statusPlataforma = aceitoStore.getStatus();

    return(
        <>
            {statusPlataforma === "aceito" ? <section className={styles.NotaFiscal}>
            <h2> Notas fiscais </h2>

            <FiltroNotaFiscal />
        </section> : <UsuarioNaoAceitoAnunciante />}
        </>
    )
}