import { collection, doc, getDocs, updateDoc } from "firebase/firestore/lite";
import { useEffect, useState } from "react";
import { db, storage } from "../../../db/banco";
import IInfluenciador from "../../../types/IInfluenciador";
import styles from "./CriarNotaFiscalCPF.module.css";
import autenticaStore from "../../../stores/autentica.store";
import BadgeIcon from '@mui/icons-material/Badge';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import DirectionsIcon from '@mui/icons-material/Directions';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import PinIcon from '@mui/icons-material/Pin';
import ApartmentIcon from '@mui/icons-material/Apartment';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { Link, useLocation, useNavigate } from "react-router-dom";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import IChatPropostas from "../../../types/ICharPropostas";
import CampaignIcon from '@mui/icons-material/Campaign';
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import CampoTexto from "../../../components/CampoTexto";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import aceitoStore from "../../../stores/aceito";
import UsuarioNaoAceitoInfluenciador from "../../UsuarioNaoAceito/UsuarioNaoAceitoInfluenciador";

export default function CriarNotaFiscalCPF() {
    // verifica se usuário foi aceito na plataforma
    const statusPlataforma = aceitoStore.getStatus();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const parametro = params.get("id"); // Parâmetro da página
    const valorVindo = params.get("valor");
    const idPropostaVindo = params.get("idProposta");
    // para criar o numero da fatura a partir do ultimo numero (começando pelo 100)
    const [quantasFatuas, setQuantasFaturas] = useState(0);
    // email usado como id
    const email = autenticaStore.getEmail();
    // recebe o influenciador
    const [Influenciador, setInfluenciador] = useState<IInfluenciador[]>([]);
    // dados do influenciador para nota fiscal
    const [dataNascimento, setDataNascimento] = useState("");
    const [idadeInfluenciador, setIdadeInfluenciador] = useState(0);
    // controlar botões de baixar e enviar pdf
    const [botaoBaixar, setBotaoBaixar] = useState(false);
    const [botaoUpload, setBotaoUpLoad] = useState(false);
    // Recebe o parametro, caso a pagina seja atualiza, não perde
    const [nomeDaCampanha, setNomeDaCampanha] = useState("");
    const [valorDaCampanha, setValorDaCampanha] = useState("");
    const [idChatProposta, setIdChatProposta] = useState("");
    // upload recibo de mídia
    const [comprovanteTrabalho, setComprovanteTrabalho] = useState("");
    // Usado para navegar entre rotas
    const navigate = useNavigate()

    useEffect(() => {
        if (parametro) setNomeDaCampanha(parametro);
        if (valorVindo) setValorDaCampanha(valorVindo);
        if (idPropostaVindo) setIdChatProposta(idPropostaVindo);
    }, [])

    // banco de dados
    const useCollectionRef = collection(db, "influenciador");
    // puxar dados
    useEffect(() => {
        const obterAnunciantes = async () => {
            const dataBD = await getDocs(useCollectionRef);
            const todosInfluenciadores: IInfluenciador[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as IInfluenciador)
            const filtrado: IInfluenciador[] = todosInfluenciadores.filter(filtrado => filtrado.email === email);
            setInfluenciador(filtrado);
            console.log("influenciador:", filtrado);
            filtrado.map(encontrado => {
                setDataNascimento(encontrado.data);
            })
            // calcula a idade do influenciador
            if (dataNascimento) {
                let dataAtual = new Date();
                let dataNiver = new Date(dataNascimento);
                let diferenca: number = dataAtual.getTime() - dataNiver.getTime();

                const idade: number = Math.floor(diferenca / (1000 * 60 * 60 * 24 * 365.25));
                setIdadeInfluenciador(idade);
            }

        }
        obterAnunciantes();
    }, [dataNascimento])

    // banco de dados
    const useCollectionReff = collection(db, "chatPropostas");
    // puxar dados
    useEffect(() => {
        const obterChat = async () => {
            // pega o chat proposta certo
            const dataBD = await getDocs(useCollectionReff);
            const todasPropostas: IChatPropostas[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id } as IChatPropostas))

            console.log("todos chats", todasPropostas);

            const somenteFaturasEnviadas: IChatPropostas[] = todasPropostas.filter(somente => somente.notaFiscalEnviada === true);

            setQuantasFaturas(somenteFaturasEnviadas.length);


        }
        obterChat();

    }, []);

    const stylesPDF = StyleSheet.create({
        page: {
            flexDirection: 'column',
            padding: 10,
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1,
        },
        sectionDois: {
            display: 'flex',
            justifyContent: 'space-around',
            flexDirection: 'row',
            marginTop: 30
        },
        label: {
            marginBottom: 5,
        },
        title: {
            fontSize: 20,
            marginBottom: 16,
            fontWeight: 'bold',
            textAlign: 'center'
        },
        text: {
            fontSize: 18,
            marginBottom: 14,
        },
    });


    function ativarBotaoBaixar() {
        setBotaoBaixar(true!);
    }

    const uploadRecibo = (event: any) => {
        event.preventDefault();
        const file = event.target[0]?.files[0];
        if (!file) return;

        const storageRef = ref(storage, `influenciador/${email}/notaFiscal/${parametro}/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            "state_changed",
            snapshot => {
            },
            error => {
                alert(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then(url => {
                    setComprovanteTrabalho(url);
                    // alert(`Arquivo enviado com sucesso!`);
                    navigate("/influenciador/fatura");
                })
            }
        )
        notaFiscalEnviada();
    }

    function aparecerUpload() {
        setBotaoUpLoad(true);
    }

    async function editarChamado(novosDados: any) {
        try {
            if (idChatProposta) {
                const chamadoDocRef = doc(db, "chatPropostas", idChatProposta);

                await updateDoc(chamadoDocRef, novosDados);
                // alert("Dados editados");
            }
        } catch (error) {
            alert("Nao deu certo")
        }
    }

    async function notaFiscalEnviada() {
        // enviarEmail();
        const conversa = {
            notaFiscalEnviada: true
        }
        await editarChamado(conversa)
        alert("Nota fiscal enviada!");

    }

    return (
        <>
            {statusPlataforma === "aceito" ? <section className={styles.CriarNotaFiscalCPF}>
            <h2> Criar recibo de mídia, pessoa física </h2>

            <h3> Caso queira alterar algum dado, vá até seu perfil, <Link to="/influenciador/perfil"> clicando aqui </Link>. </h3>

            <nav className={styles.CardCarteira}>
                <header> <p> Recibo de mídia </p> </header>

                <main>
                    {Influenciador.map(influ => (
                        <>
                            <div>
                                <BadgeIcon fontSize="large" />
                                <label> <strong> Nome: </strong> {influ.nomeCompleto} </label>
                            </div>

                            <div>
                                <CampaignIcon fontSize="large" />
                                <label> <strong> Campanha: </strong> {nomeDaCampanha} </label>
                            </div>

                            <div>
                                <ApartmentIcon fontSize="large" />
                                <label> <strong> CEP: </strong> {influ.cep} </label>
                            </div>

                            <div>
                                <DirectionsIcon fontSize="large" />
                                <label> <strong> Logradouro: </strong> {influ.logradouro} </label>
                            </div>

                            <div>
                                <MapsHomeWorkIcon fontSize="large" />
                                <label> <strong> Bairro: </strong> {influ.bairro} </label>
                            </div>

                            <div>
                                <EditLocationIcon fontSize="large" />
                                <label> <strong> Complemento: </strong> {influ.complemento} </label>
                            </div>

                            <div>
                                <PinIcon fontSize="large" />
                                <label> <strong> CPF: </strong> {influ.cpf} </label>
                            </div>

                            <section>
                                <div>
                                    <DateRangeIcon fontSize="large" />
                                    <label> <strong> Idade: </strong> {idadeInfluenciador} </label>
                                </div>

                                <div>
                                    <DateRangeIcon fontSize="large" />
                                    <label> <strong> Emissão: </strong> {new Date().toLocaleDateString()} </label>
                                </div>

                                <div>
                                    <AccessTimeIcon fontSize="large" />
                                    <label> <strong> Horário: </strong> {new Date().toLocaleTimeString()} </label>
                                </div>
                            </section>

                            <section>
                                <div>
                                    <ReceiptLongIcon fontSize="large" />
                                    <label> <strong> Fatura Nº: </strong> {100 + quantasFatuas} </label>
                                </div>

                                <div>
                                    <EventAvailableIcon fontSize="large" />
                                    <label> <strong> Vencimento: </strong> {new Date(Date.now() + (15 * 24 * 60 * 60 * 1000)).toLocaleDateString()} </label>
                                </div>

                                <div>
                                    <AttachMoneyIcon fontSize="large" />
                                    <label> <strong> Valor: </strong> R$ {valorVindo},00 </label>
                                </div>

                            </section>

                            <address>
                                <p> Adonweb Brasil Marketing de Rede Ltda </p>
                                <p> Avenida Treze de Maio, 23 sala 1216, CEP: 20031-902, Rio de Janeiro, RJ </p>
                                <p> CNPJ: 20.074.359/0001-21 </p>
                            </address>

                            <section>
                                <button className="btn btn-primary" onClick={ativarBotaoBaixar}> Gerar recibo </button>
                            </section>

                            {botaoBaixar && <PDFDownloadLink
                                document={
                                    <Document>
                                        <Page style={stylesPDF.page}>
                                            <View>
                                                <Text style={stylesPDF.title}> Recibo de mídia </Text>
                                            </View>

                                            <View style={stylesPDF.section}>
                                                <Text style={stylesPDF.text}> Nome: {influ.nomeCompleto} </Text>
                                                <Text style={stylesPDF.text}> Campanha: {nomeDaCampanha}</Text>
                                                <Text style={stylesPDF.text}> CEP: {influ.cep} </Text>
                                                <Text style={stylesPDF.text}> Logradouro: {influ.logradouro} </Text>
                                                <Text style={stylesPDF.text}> Bairro: {influ.bairro} </Text>
                                                <Text style={stylesPDF.text}> Complemento: {influ.complemento} </Text>
                                                <Text style={stylesPDF.text}> CPF: {influ.cpf} </Text>

                                                <View style={stylesPDF.sectionDois}>
                                                    <Text style={stylesPDF.text}> Idade: {idadeInfluenciador} </Text>
                                                    <Text style={stylesPDF.text}> Emissão: {new Date().toLocaleDateString()} </Text>
                                                    <Text style={stylesPDF.text}> Horário: {new Date().toLocaleTimeString()} </Text>
                                                </View>

                                                <View style={stylesPDF.sectionDois}>
                                                    <Text style={stylesPDF.text}> Fatura Nº: {100 + quantasFatuas} </Text>
                                                    <Text style={stylesPDF.text}> Vencimento: {new Date(Date.now() + (15 * 24 * 60 * 60 * 1000)).toLocaleDateString()} </Text>
                                                    <Text style={stylesPDF.text}> Valor: R$ {valorVindo},00 </Text>
                                                </View>

                                                <View style={stylesPDF.section}>
                                                    <Text style={stylesPDF.text}> Adonweb Brasil Marketing de Rede Ltda </Text>
                                                    <Text style={stylesPDF.text}> Avenida Treze de Maio, 23 sala 1216, CEP: 20031-902, Rio de Janeiro, RJ </Text>
                                                    <Text style={stylesPDF.text}> CNPJ: 20.074.359/0001-21 </Text>
                                                </View>
                                            </View>

                                        </Page>
                                    </Document>
                                }
                                fileName={`recibo-${100 + quantasFatuas}.pdf`} className="btn btn-info" onClick={aparecerUpload}>
                                {({ blob, url, loading, error }) =>
                                    loading ? 'Carregando documento...' : 'Baixar recibo'
                                }
                            </PDFDownloadLink>}
                        </>
                    ))}

                    {botaoUpload && <form onSubmit={uploadRecibo} className={styles.FormEnviar}>
                        <span> Insira seu recibo </span>
                        <CampoTexto
                            titulo="Insira a nota fiscal"
                            valor={comprovanteTrabalho}
                            controle={setComprovanteTrabalho}
                            type="file"
                            icone={<FileCopyOutlinedIcon fontSize="large" />}
                        />

                        <button className="btn btn-success">
                            Enviar
                        </button>

                        {/* <button onClick={baixarPdf} className="btn btn-primary"> Visualizar mídia kit </button> */}
                    </form>}
                </main>
            </nav>
        </section> : <UsuarioNaoAceitoInfluenciador />}
        </>
    )
}