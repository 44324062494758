import axios, { AxiosResponse, AxiosError } from 'axios';

interface Estado {
    id: number;
    sigla: string;
    nome: string;
}

interface Cidade {
    id: number;
    nome: string;
}

const ibgeApiUrl = 'https://servicodados.ibge.gov.br/api/v1/localidades';

let estados: Estado[] = [];

axios.get<Estado[]>(`${ibgeApiUrl}/estados`)
    .then((response: AxiosResponse<Estado[]>) => {
        estados = response.data;
    })
    .catch((error: AxiosError) => {
        console.error('Ocorreu um erro ao obter a lista de estados:', error);
    });

async function obterNomesDasCidadesPorEstadoNome(nomeEstado: string): Promise<string[]> {
    const estado = estados.find((estado) => estado.nome === nomeEstado);

    if (estado) {
        try {
            const response = await axios.get<Cidade[]>(`${ibgeApiUrl}/estados/${estado.sigla}/municipios`);
            // Mapeia os nomes das cidades a partir dos dados da resposta e retorna um array de nomes
            const nomesDasCidades = response.data.map((cidade) => cidade.nome);
            return nomesDasCidades;
        } catch (error) {
            console.error(`Ocorreu um erro ao obter a lista de cidades do estado ${nomeEstado}:`, error);
            return [];
        }
    } else {
        console.error(`Estado com o nome ${nomeEstado} não encontrado.`);
        return [];
    }
}



export { estados, obterNomesDasCidadesPorEstadoNome };
