import "./Menu.css"
import { useState, useEffect } from "react";
import logoComTexto from "./logo_com_texto.png";
import HomeIcon from '@mui/icons-material/Home';
import CategoryIcon from '@mui/icons-material/Category';
import HandshakeIcon from '@mui/icons-material/Handshake';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import ArticleIcon from '@mui/icons-material/Article';
import LanguageIcon from '@mui/icons-material/Language';
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function Menu() {  
    const localizacao = useLocation();
    const navigate = useNavigate();

    const [paginaInicial, setPaginaInicial] = useState(true);
    
    useEffect(() => {
        function verificaPagina() {
            if (localizacao.pathname === '/') setPaginaInicial(true);
            else setPaginaInicial(false);
        }
        verificaPagina();
    }, [localizacao])

    function navegarParaLogin() {
        navigate('/login')
    }

    function navegarParaRegistrar() {
        navigate('/cadastrar')
    }
       
    return (
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
            <div className="container-fluid">
                <Link to="/"> <img src={logoComTexto} alt="logo da ism" /> </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    {paginaInicial && <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link" aria-current="page" href="#inicio">
                                <HomeIcon />
                                Início
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#categorias">
                                <CategoryIcon />
                                Categorias
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#parceiros">
                                <HandshakeIcon />
                                Parceiros
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#remuneracao">
                                <LocalAtmIcon />
                                Remuneração
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#planos">
                                <ArticleIcon />
                                Planos
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://blog.influenciesuamarca.com.br/" target="_blank" rel="noreferrer">
                                <LanguageIcon />
                                Blog
                            </a>
                        </li>
                    </ul>}
                    <div className="div-botao">
                        {/* Colocar as funções de navegar */}
                        <button type="button" className="btn btn-light" onClick={navegarParaLogin}> Login </button>
                        <button type="button" className="btn btn-light" onClick={navegarParaRegistrar}> Cadastrar </button>
                    </div>
                </div>
            </div>
        </nav>        
    )
}