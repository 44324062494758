import { Link, useNavigate } from "react-router-dom";
import styles from "./Chamado.module.css"
import CampoTexto from "../CampoTexto";
import { useEffect, useState } from "react"
import SubjectIcon from '@mui/icons-material/Subject';
import CampoEscolha from "../CampoEscolha";
import TypeSpecimenIcon from '@mui/icons-material/TypeSpecimen';
import AreaTextoMenor from "../AreaTextoMenor";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { addDoc, collection, getDocs } from "firebase/firestore/lite";
import { db } from "../../db/banco";
import autenticaStore from "../../stores/autentica.store";
import IInfluenciador from "../../types/IInfluenciador";
import IAnunciante from "../../types/IAnunciante";

export default function Chamado() {
    // obtem o email(serve como id) do anunciante
    const email = autenticaStore.getEmail();
    const navigate = useNavigate();
    const tipoDeChamado = [        
        "Financeiro", // financeiro
        "Suporte/Dúvida", // suporte        
    ]
    const [assunto, setAssunto] = useState("");
    const [ticket, setTicket] = useState("");
    const [descreva, setDescreva] = useState("");
    
    const [nomeDeQuemAbriu, setNomeQuemAbriu] = useState("");
    const [tipo, setTipo] = useState("");
    // status
    const status = "aberto";

    const useCollectionRef = collection(db, "abrirChamado");

    const useCollectionReff = collection(db, "influenciador");

    const useCollectionRefff = collection(db, "anunciante");
    // puxar dados
    useEffect(() => {
        const obterChamados = async () => {
            const dataBD = await getDocs(useCollectionReff);
            const todosInfluenciadores: IInfluenciador[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id } as IInfluenciador));

            const dataBDD = await getDocs(useCollectionRefff);
            const todosAnunciantes: IAnunciante[] = dataBDD.docs.map(doc => ({ ...doc.data(), id: doc.id } as IAnunciante));

            

            const temInfluenciador = todosInfluenciadores.filter(tem => tem.email === email);
            const temAnunciante = todosAnunciantes.filter(tem => tem.email === email);

            

            if (temInfluenciador.length > 0) {
                temInfluenciador.map(nome => {
                    setNomeQuemAbriu(nome.nomeCompleto);
                    setTipo("influenciador")
                })
                
            } else {
                temAnunciante.map(nome => {
                    setNomeQuemAbriu(nome.nomeCompleto);
                    setTipo("anunciante")
                })
            }
                       
        }
        obterChamados();

    }, [])

    

    async function criarChamado(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        const chamado = await addDoc(useCollectionRef, {             
            nomeDeQuemAbriu,           
            assunto,
            ticket,            
            descreva,
            status,
            emailQuemAbriu: email,
            conversaLadoSuporte: [""],
            conversaLadoUsuario: [""],
            tipo
        });
        
        alert("Chamado aberto com sucesso!"); 
        if (tipo === "anunciante") {
            navigate("/anunciante/meus-chamados")
        } else {
            navigate("/influenciador/meus-chamados")
        }
           
    }

    return(
        <section className={styles.Chamado}>
            <h3> <strong> OBS: </strong> verifique o <strong> <Link to="/faq"> FAQ </Link> </strong> para saber
                se a sua dúvida já foi respondida 
            </h3>

            <form onSubmit={criarChamado}>
                <CampoTexto
                    titulo="Assunto"
                    valor={assunto}
                    controle={setAssunto}
                    placeholder="Ajuda em criar campanha"
                    icone={<SubjectIcon fontSize="large" />}               
                />

                <CampoEscolha
                    titulo="Tipo"
                    valorPadrao="Escolha o tipo de ticket"
                    controle={setTicket}
                    lista={tipoDeChamado}
                    icone={<TypeSpecimenIcon fontSize="large" />}                
                />

                <AreaTextoMenor 
                    titulo="Descreva seu problema"
                    valor={descreva}
                    controle={setDescreva}
                    colunas={30}
                    linhas={3}
                    placeholder="Estou com problema em adicionar o título na campanha"
                    icone={<BorderColorIcon fontSize="large" />}               
                />

                <button className="btn btn-secondary"> Abrir chamado </button>
            </form>            
        </section>
    );
}