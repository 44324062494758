import CardVantagens from "./CardVantagens";
import styles from "./Vantagens.module.css";

export default function Vantangens() {
    return(
        <section className={styles.Vantagens}>            
                <CardVantagens 
                    titulo="Marca" 
                    descricao="Por que anunciar no ISM?"
                    parafragoUm="Grande variedade de influenciadores"
                    parafragoDois="Equipe de suporte especializada em influencer marketing"
                    parafragoTres="Diferentes formatos de pagamento disponíveis"
                />
                <CardVantagens 
                    titulo="Influenciador" 
                    descricao="Por que me cadastrar no ISM?"
                    parafragoUm="Oportunidade de ser visto por grandes marcas"
                    parafragoDois="Cadastro e acesso à plataforma 100% gratuitos"
                    parafragoTres="Liberdade para se conectar nas campanhas que mais te interessarem"
                />            
        </section>
    );
}