import { action, makeAutoObservable, observable } from "mobx";

interface INumeroCampanhas {
    quantidade: number,    
}

class NumeroCampanhas {
    campanhasDisponiveis: INumeroCampanhas = {quantidade: 0}
    campanhasFinalizadas: INumeroCampanhas = {quantidade: 0}    

    constructor() {
        makeAutoObservable(this, {
            campanhasDisponiveis: observable,
            campanhasFinalizadas: observable,
            pegarCampanhasDisponiveis: action,
            pegarCampanhasFinalizadas: action,
        })
    }

    pegarCampanhasDisponiveis({quantidade}: INumeroCampanhas) {
        this.campanhasDisponiveis = {quantidade}
    }

    pegarCampanhasFinalizadas({quantidade}: INumeroCampanhas) {
        this.campanhasFinalizadas = {quantidade}
    }   

    getCampanhasDisponiveis(): number {
        return this.campanhasDisponiveis.quantidade
    }

    getCampanhasFinalizadas(): number {
        return this.campanhasFinalizadas.quantidade
    }
}

const numeroCampanhas = new NumeroCampanhas();

export default numeroCampanhas;