import "./BotaoStepper.css"

interface BotaoStepperProps {
    children: string,
    controle: () => void,
}

export default function BotaoStepper({ children, controle }: BotaoStepperProps) {
    return(
        <button onClick={controle} className="btn btn-secondary">
            {children} 
        </button>
    );
} 