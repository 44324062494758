import styles from "./CampoEscolha.module.css";
import { ReactNode } from 'react';

interface CampoEscolhaProps {
    titulo: string,
    controle: (value: string) => void 
    lista: string[]
    icone: ReactNode,
    valorPadrao?: string,
    todas?: boolean
}

export default function CampoEscolha({ titulo, controle, lista, icone, valorPadrao, todas }: CampoEscolhaProps) {
    return (
        <div className={styles.CampoEscolha}>
            <label htmlFor={titulo}> {titulo} </label>
            <div>
                {icone}
                <select onChange={(e) => controle(e.target.value)} id={titulo} required>
                    <option disabled selected> {valorPadrao} </option>
                    {lista.map(item => (
                        <option key={item}> {item} </option>
                    ))}
                    {todas && <option> Todas </option>}
                </select>
            </div>
        </div>
    );
}