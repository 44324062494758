import { action, makeAutoObservable, observable } from "mobx";

interface IPagina {
    paginaAnterior: string,
    tipo: boolean
}

class GuardaPaginaAnterior {
    pagina: IPagina = { paginaAnterior: "", tipo: true }

    constructor() {
        makeAutoObservable(this, {
            pagina: observable,
            salvarPagina: action
        });
    }

    salvarPagina(paginaAnterior: string, tipo: boolean) {
        this.pagina = { paginaAnterior, tipo }
    }

    getPagina(): string {
        return this.pagina.paginaAnterior;
    }

    getTipo(): boolean {
        return this.pagina.tipo;
    }
}

const guardaPaginaAnterior = new GuardaPaginaAnterior();

export default guardaPaginaAnterior;