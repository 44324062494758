import FiltroPequeno from "../../../components/FiltroPequeno";
import aceitoStore from "../../../stores/aceito";
import UsuarioNaoAceitoAnunciante from "../../UsuarioNaoAceito/UsuarioNaoAceitoAnunciante";
import styles from "./InfluenciadoresEmAnalise.module.css";

export default function InfluenciadoresEmAnalise() {
    // verifica se usuário foi aceito na plataforma
    const statusPlataforma = aceitoStore.getStatus();

    return (
        <>
            {statusPlataforma === "aceito" ? <section className={styles.InfluenciadoresEmAnalise}>
                <h2> Influenciadores em análise </h2>

                <FiltroPequeno />
            </section> : <UsuarioNaoAceitoAnunciante />}
        </>
    )
}