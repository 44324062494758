import { useEffect, useState } from "react";
import CampoTexto from "../../../../components/CampoTexto";
import { mascaraDinheiro } from "../../../../utils/mascaras";
import styles from "./EnvPropostasAnunciante.module.css";
import AreaTextoMenor from "../../../../components/AreaTextoMenor";
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import BotaoSubmit from "../../../../components/BotaoSubmit";
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import IChatPropostas from "../../../../types/ICharPropostas";
import { db } from "../../../../db/banco";
import { Link, useLocation, useNavigate } from "react-router-dom";
import usuario from "../../../../Image/usuario.png";
import anunciante from "../../../../Image/anunciante.png";
import axios from "axios";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import IInfluenciador from "../../../../types/IInfluenciador";
import { listaTiposDeCampanha } from "../../../../utils/listas";
import ICriarCampanha from "../../../../types/ICriarCampanha";
import { addDoc, collection, doc, getDocs, updateDoc } from "firebase/firestore/lite";
import autenticaStore from "../../../../stores/autentica.store";

export default function EnvPropostasAnunciante() {
    // obtem o email(serve como id) do anunciante
    const email = autenticaStore.getEmail();
    const [statusCampanha, setStatusCampanha] = useState("");
    const navigate = useNavigate();
    // Pegar parametro da página
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const parametro = params.get("id");
    // codigo actionpay influenciador
    const [quantidadeInfluenciadoresComCodigo, setQuantidadeInfluenciadoresComCodigo] = useState(0);
    const [idInfluenciador, setIdInfluenciador] = useState("");
    // Recebe e controla o chat proposta
    const [chatPropostas, setChatPropostas] = useState<IChatPropostas[]>([]);
    const [nomeDaCampanha, setNomeDaCampanha] = useState("");
    // Mensagem
    const [novaMensagem, setNovaMensagem] = useState("");
    // Mensagens existentes
    const [aux, setAux] = useState([""]);
    // enviar e atualizar
    const [atualizar, setAtualizar] = useState(true);
    // Influenciador
    const [emailDoInfluenciador, setEmailDoInflueciador] = useState("");
    const [nomeDoInflueciador, setNomeDoInflueciador] = useState("");
    const [valor, setValor] = useState("");
    // banco de dados
    const useCollectionRef = collection(db, "chatPropostas");
    // Verifica se o influenciador tem codigo actionpay
    const [jaTemCodigo, setJaTemCodigo] = useState(0);
    // verifica se é campanha de performance
    const [tipoCampanha, setTipoCampanha] = useState("");
    // valor da campanha
    const [saldoEmCampanha, setSaldoEmCampanha] = useState(0);
    // Valor combinado
    const [valorJaCombinado, setValorJaCombinado] = useState(0);
    // atualizar saldo campanha
    const [idCampanha, setIdCampanha] = useState("");
    const [saldoDaCampanha, setSaldoDaCampanha] = useState(0);

    // puxar dados
    useEffect(() => {
        const obterChat = async () => {
            // pega o chat proposta certo
            const dataBD = await getDocs(useCollectionRef);
            const todosChamados = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id } as IChatPropostas));
            const filtrado = todosChamados.filter(chamado => chamado.id === parametro);
            setChatPropostas(filtrado);


            filtrado.forEach(chatProposta => {
                setStatusCampanha(chatProposta.statusProposta);
                setEmailDoInflueciador(chatProposta.emailInfluenciador);
                setNomeDoInflueciador(chatProposta.nomeInfluenciador);
                setNomeDaCampanha(chatProposta.nomeCampanha);
                setIdInfluenciador(chatProposta.idInfluenciador);
                setTipoCampanha(chatProposta.tipoCampanha);
                setSaldoEmCampanha(chatProposta.saldoCampanha);
                setValorJaCombinado(chatProposta.valorCombinado);
                setIdCampanha(chatProposta.idCampanha);
            });

            // pega as mensagens já existentes e coloca numa variável auxiliar
            const mensagensJaTem = filtrado.map(men => men?.conversaAnunciante);

            setAux(mensagensJaTem[0]);
        };

        obterChat();

        // Atualize os dados a cada 5 segundos
        const intervalId = setInterval(obterChat, 2000);

        // Limpar o intervalo quando o componente for desmontado
        return () => clearInterval(intervalId);
    }, [parametro, atualizar]);

    async function editarChamado(novosDados: any) {
        try {
            if (parametro) {
                const chamadoDocRef = doc(db, "chatPropostas", parametro);

                await updateDoc(chamadoDocRef, novosDados);
                // alert("Dados editados");
            }
        } catch (error) {
            alert("Nao deu certo")
        }
    }

    async function enviarMensagem(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        const conversaAnunciante = [...aux, novaMensagem];

        const conversa = {
            conversaAnunciante
        }
        await editarChamado(conversa)

        setAtualizar(!atualizar);
        setNovaMensagem("");
    }

    async function definirValorCombinado(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        let valorLimpo = valor.replace(/[^\d,]/g, '');
        const valorConvertido = Number(valorLimpo);

        if (valorConvertido > saldoEmCampanha) {
            alert("Valor pretendido maior que o saldo na campanha.");
        } else {
            const conversa = {
                valorCombinado: valorConvertido,

            }
            await editarChamado(conversa);
            alert("Valor combinado");
            setAtualizar(!atualizar);
            setValor("");
        }
    }

    // const enviarEmail = async () => {

    //     try {
    //         const response = await axios.post(
    //             "https://us-central1-ism-teste.cloudfunctions.net/enviarEmail",
    //             {
    //                 destinatario: emailDoInfluenciador,
    //                 assunto: "Você foi adicionado a uma campanha!",
    //                 mensagem: `Oii, ${nomeDoInflueciador}\n\n` +

    //                     `Estamos passando aqui pra te avisar que você foi conectado à campanha ${nomeDaCampanha}\n` +
    //                     "pela nossa administração. A partir de agora, só precisamos aguardar a aprovação da marca\n\n" +
    //                     "anunciante para começarmos a parceria :)\n\n" +

    //                     "Assim que tivermos uma resposta, entraremos em contato para comunicar\n\n" +

    //                     "Você pode olhar mais detalhes sobre essa campanha clicando aqui:\n\n" +

    //                     "Ver campanha: influenciesuamarca.com.br/influenciador/minhas-campanhas\n\n" +

    //                     "Conte com a gente para influenciar sempre mais\n" +
    //                     "Equipe ISM"

    //             }
    //         );

    //         const { id } = response.data;
    //         return id;
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };


    const enviarEmail = async () => {
        try {
            const response = await axios.post(
                "https://us-central1-ism-teste.cloudfunctions.net/enviarEmail",
                {
                    destinatario: emailDoInfluenciador,
                    assunto: "Você foi aprovado!",
                    mensagem: `Oii, ${nomeDoInflueciador}\n\n` +

                        "Temos uma ótima notícia para te dar!\n\n" +

                        "O anunciante aprovou a sua participação na campanha 💜\n\n" +

                        "Agora você pode acessar os materiais de divulgação na página da campanha e começar as\n" +
                        "postagens de acordo com as instruções ditas na plataforma e/ou o que foi alinhado com o\n" +
                        "anunciante pelo chat\n\n" +

                        "Não perca tempo! Acesse agora mesmo a nossa plataforma e comece a influenciar! 🙂\n\n" +

                        "Equipe ISM"
                }
            );

            const { id } = response.data;
            return id;
        } catch (error) {
            // console.log(error);
        }
    };

    const enviarEmailCampanhaInvisivel = async () => {
        try {
            const response = await axios.post(
                "https://us-central1-ism-teste.cloudfunctions.net/enviarEmail",
                {
                    destinatario: email,
                    assunto: "Sua campanha está invisível para os influenciadores!",
                    mensagem: `Oii, anunciante\n\n` +

                        "Sua campanha está com saldo de menos de R$100,00!\n\n" +

                        "Quando isso acontece, a campanha se torna invisível para os influenciadores\n\n" +

                        "Vá em minhas campanhas e adicione um saldo nessa campanha!\n" +

                        "Equipe ISM"
                }
            );

            const { id } = response.data;
            return id;
        } catch (error) {
            // console.log(error);
        }
    };

    async function aceitarProposta() {
        // primeira validação: valor da proposta não pode ser diferente de 0        
        if (valorJaCombinado !== 0) {            
            criarCodigoActionpayInfluenciador();
            criarNovaNotificacao();
            enviarEmail();
            editarSaldoCampanha();
            const conversa = {
                statusProposta: "aceito",
                saldoCampanha: saldoEmCampanha - valorJaCombinado
            }
            await editarChamado(conversa)
            if ((saldoDaCampanha - valorJaCombinado) < 100) {
                enviarEmailCampanhaInvisivel();
                await criarNovaNotificacaoCampanhaInvisivel();
            }
            alert("Proposta aceita.");
            navigate("/anunciante/influenciadores-em-analise");
        } else {
            alert("Valor combinado não pode ser R$0,00 e nem maior que o saldo da campanha");
        }
    }

    async function aceitarPropostaComPerformance() {
        // primeira validação: valor da proposta não pode ser diferente de 0        

        criarCodigoActionpayInfluenciador();
        criarNovaNotificacao();
        enviarEmail();
        editarSaldoCampanha();
        const conversa = {
            statusProposta: "aceito",
            saldoCampanha: saldoEmCampanha - valorJaCombinado
        }
        await editarChamado(conversa)
        if ((saldoDaCampanha - valorJaCombinado) < 100) {
            enviarEmailCampanhaInvisivel();
            await criarNovaNotificacaoCampanhaInvisivel();
        }
        alert("Proposta aceita.");
        navigate("/anunciante/influenciadores-em-analise");

    }

    // Criar notificação
    // banco de dados
    const useCollectionRefNotificacao = collection(db, "notificacao");

    async function criarNovaNotificacao() {
        const notificacao = await addDoc(useCollectionRefNotificacao, {
            mensagem: `O anunciante, te aceitou na campanha: ${nomeDaCampanha}!`,
            visto: false,
            tipoDoUsuarioQueVaiReceberNotificacao: "influenciador",
            emailDeQuemVaiReceberNotificacao: emailDoInfluenciador,
            data: new Date().toLocaleDateString(),
            link: "https://influenciesuamarca.com.br/influenciador/minhas-campanhas"
        })
    }

    async function criarNovaNotificacaoCampanhaInvisivel() {
        const notificacao = await addDoc(useCollectionRefNotificacao, {
            mensagem: `Anunciante, sua campanha se tornou invisível para os influenciadores. Adicione saldo nela, para se tornar visível novamente!`,
            visto: false,
            tipoDoUsuarioQueVaiReceberNotificacao: "anunciante",
            emailDeQuemVaiReceberNotificacao: email,
            data: new Date().toLocaleDateString(),
            link: "https://influenciesuamarca.com.br/anunciante/minhas-campanhas"
        })
    }

    // Criar codigo influenciador actionpay
    // obtem o influenciador
    // banco de dados
    const useCollectionRefInfluenciador = collection(db, "influenciador");
    useEffect(() => {
        const obterUsuario = async () => {
            const dataBD = await getDocs(useCollectionRefInfluenciador);
            const todosInfluenciadores: IInfluenciador[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as IInfluenciador);
            const influenciadoresComCodigoActionpay = todosInfluenciadores.filter(achou => achou.codigoActionpayInfluenciador);
            setQuantidadeInfluenciadoresComCodigo(influenciadoresComCodigoActionpay.length);




            //  Verifica se o influenciador tem codigo actionpay
            if (emailDoInfluenciador) {
                const esseJaTem = influenciadoresComCodigoActionpay.filter(esse => esse.email === emailDoInfluenciador);

                setJaTemCodigo(esseJaTem.length);

            }
        };
        obterUsuario();
    }, [emailDoInfluenciador, jaTemCodigo]);

    async function editarInfluenciador(novosDados: any) {
        try {
            if (idInfluenciador) {
                const chamadoDocRef = doc(db, "influenciador", idInfluenciador);

                await updateDoc(chamadoDocRef, novosDados);
                // alert("Dados editados");
            }
        } catch (error) {
            alert("Nao deu certo")
        }
    }

    async function criarCodigoActionpayInfluenciador() {
        if (jaTemCodigo === 0) {
            const influenciador = {
                codigoActionpayInfluenciador: 1001 + quantidadeInfluenciadoresComCodigo
            }
            await editarInfluenciador(influenciador);
        } else {

        }
    }

    // Editar saldo campanha
    // Obtem e atualiza os dadoa
    const useCollectionCampanhaRef = collection(db, "criarCampanha");
    useEffect(() => {
        const obterCampanha = async () => {
            const dataBD = await getDocs(useCollectionCampanhaRef);
            const todasCampanhas: ICriarCampanha[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as ICriarCampanha);
            const campanhasDesseAnunciante = todasCampanhas.filter(cam => cam.id === idCampanha);

            campanhasDesseAnunciante.map(desse => {
                setSaldoDaCampanha(desse.saldoCampanha);
            })
        }
        obterCampanha();
    }, [idCampanha]);

    async function editarSaldoCampanha() {
        const campanhaCompleta = {
            saldoCampanha: saldoDaCampanha - valorJaCombinado
        }
        editarCampanha(campanhaCompleta);
    }

    async function editarCampanha(novosDados: any) {
        try {
            const criarCampanhaDocRef = doc(db, "criarCampanha", idCampanha);

            await updateDoc(criarCampanhaDocRef, novosDados);

        } catch (error) {
            alert("Nao deu certo")
        }
    }


    return (
        <section className={styles.EnvPropostasAnunciante}>
            {chatPropostas.map(valorCombinado => (
                <>
                    <h3> Chat com <Link to={`/anunciante/perfil-influenciador?id=${valorCombinado.idInfluenciador}`}> {valorCombinado.nomeInfluenciador} </Link> </h3>
                    {tipoCampanha === listaTiposDeCampanha[2] || tipoCampanha === listaTiposDeCampanha[3]
                        || tipoCampanha === listaTiposDeCampanha[4] ? "" : <>
                        <h3> Valor combinado até o momento: R${valorCombinado.valorCombinado},00 </h3>
                        <h3> Saldo nessa campanha: R${valorCombinado.saldoCampanha},00 </h3>
                    </>}
                </>
            ))}

            <aside className={styles.Chat}>
                <div className={styles.LadoUsuario}>
                    <img src={anunciante} alt="suporte" />

                    <ul className={styles.MensagemUsuario}>
                        {chatPropostas.map(mensa => (
                            mensa.conversaAnunciante?.map(cadaMensagem => (
                                <li> {cadaMensagem} </li>
                            ))
                        ))}
                    </ul>
                </div>

                <div className={styles.LadoUsuario}>
                    <ul className={styles.PrimeiraMensagem}>
                        {chatPropostas.map(mensa => (
                            mensa.conversaLadoInfluenciador?.map(cadaMensagem => (
                                <li> {cadaMensagem} </li>
                            ))
                        ))}
                    </ul>

                    <img src={usuario} alt="usuário" />


                </div>

            </aside>
            <form onSubmit={enviarMensagem}>
                {/* <CampoTexto
                    valor={mascaraDinheiro(valor)}
                    controle={setValor}
                    titulo="Valor"
                    placeholder="R$100,00"
                    icone={<PaidOutlinedIcon fontSize="large" />}
                /> */}
                <AreaTextoMenor
                    titulo="Digite sua proposta"
                    icone={<CurrencyExchangeOutlinedIcon fontSize="large" />}
                    valor={novaMensagem}
                    controle={setNovaMensagem}
                    placeholder="Você gostou da campanha?"
                    linhas={2}
                    colunas={30}
                />

                <BotaoSubmit classe="btn btn-secondary">
                    <SendOutlinedIcon fontSize="large" />
                    Enviar
                </BotaoSubmit>
            </form>

            {tipoCampanha === listaTiposDeCampanha[2] || tipoCampanha === listaTiposDeCampanha[3]
                || tipoCampanha === listaTiposDeCampanha[4] ? "" : <form onSubmit={definirValorCombinado}>
                <CampoTexto
                    valor={mascaraDinheiro(valor)}
                    controle={setValor}
                    titulo="Valor"
                    placeholder="R$100,00"
                    icone={<AttachMoneyIcon fontSize="large" />}
                />

                <BotaoSubmit classe="btn btn-secondary">
                    <CheckCircleOutlineIcon fontSize="medium" />
                    Ok
                </BotaoSubmit>

            </form>}


            {/* {statusCampanha === "espera" ? <div className={styles.ContainerConfirmarCampanha}>
                <h4> Deseja aceitar esse influenciador? </h4>
                <button className="btn btn-info" onClick={aceitarProposta}> Aceitar </button>
            </div> : ""} */}

            {statusCampanha === "espera" && <>
                {tipoCampanha === listaTiposDeCampanha[2] || tipoCampanha === listaTiposDeCampanha[3]
                    || tipoCampanha === listaTiposDeCampanha[4] ? <div className={styles.ContainerConfirmarCampanha}>
                    <h4> Deseja aceitar esse influenciador? </h4>
                    <button className="btn btn-info" onClick={aceitarPropostaComPerformance}> Aceitar </button>
                </div> : <>
                    <div className={styles.ContainerConfirmarCampanha}>
                        <h4> Deseja aceitar esse influenciador? </h4>
                        <button className="btn btn-info" onClick={aceitarProposta}> Aceitar </button>
                    </div>
                </>}


            </>}
        </section>
    );
}