import styles from "./VerPerfilFinanceiro.module.css";
import { useState } from "react";
import VerPerfilInfluFinanceiro from "./VerPerfilInfluFinanceiro";
import VerPerfilAnunFinanceiro from "./VerPerfilAnunFinanceiro";


export default function VerPerfilFinanceiro() {
    // Controla qual tipo de conta sera criada
    const [qualUsuario, setQualUsuario] = useState(true); // true é para influenciador
    // controla o botao clicado
    const [botaoAnunciante, setBotaoAnunciante] = useState("btn btn-outline-secondary");
    const [botaoInfluenciador, setBotaoInfluenciador] = useState("btn btn-dark");

    // Funcao que muda o formulario para Anunciante
    function mudaParaAnunciante() {
        setQualUsuario(false);
        setBotaoAnunciante("btn btn-dark");
        setBotaoInfluenciador("btn btn-outline-secondary");
    }
    // Funcao que muda o formulario para Influenciador
    function mudaParaInfluenciador() {
        setQualUsuario(true);
        setBotaoInfluenciador("btn btn-dark");
        setBotaoAnunciante("btn btn-outline-secondary");
    }

    return (
        <section className={styles.VerPerfilFinanceiro}>
            <h2> Ver usuários Financeiro </h2>

            <p> Escolha o tipo de usuário que deseja visualizar. </p>

            <div>
                <button type="button" className={botaoInfluenciador} onClick={mudaParaInfluenciador}> Influenciador </button>
                <button type="button" className={botaoAnunciante} onClick={mudaParaAnunciante}> Anunciante </button>
            </div>

            {qualUsuario ? <VerPerfilInfluFinanceiro /> : <VerPerfilAnunFinanceiro />}
        </section>
    );
}