import styles from "./Cabecalho.module.css";

export default function Cabecalho() {
    return (
        <header className={styles.Cabecalho} id="inicio">
            <div>
                <h1> CONECTANDO VOCÊ AO QUE PROCURA </h1>
                <h2> O influenciador ideal para sua marca </h2>
                <h2> A marca ideal para você influenciar </h2>
            </div>
        </header>
    )
}