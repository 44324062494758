import { collection, getDocs } from "firebase/firestore/lite";
import styles from "./VerPerfilInfluenciador.module.css";
import { db } from "../../../../../db/banco";
import { useEffect, useState } from "react";
import IInfluenciador from "../../../../../types/IInfluenciador";
import { Link, useNavigate } from "react-router-dom";


export default function VerPerfilInfluenciador() {
    const navigate = useNavigate();
    // influenciador
    const [influenciadores, setInfluenciadores] = useState<IInfluenciador[]>([]);
    // puxar dados se for influenciador
    const useCollectionRef = collection(db, "influenciador");
    
    useEffect(() => {
        const obterInfluenciadores = async () => {
            const dataBD = await getDocs(useCollectionRef);
            const todosInfluenciadores: IInfluenciador[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id } as IInfluenciador))
            setInfluenciadores(todosInfluenciadores)
            console.log(todosInfluenciadores);
        }
        obterInfluenciadores();

    }, [])

    function verPerfil(id: string) {
        navigate(`/suporte/ver-influenciador?id=${id}`)
    }

    return (
        <section className={styles.VerPerfilInfluenciador}>
            <h3> Lista de influenciadores </h3>

            <table className="table table-light table-striped text-center ">
                <thead>
                    <tr>
                        <th scope="col" className="text-white">Nome</th>
                        <th scope="col" className="text-white">Ações</th>
                    </tr>
                </thead>

                {influenciadores.map(cel => (
                    <tbody>
                        <tr>
                            <td> {cel.nomeCompleto} </td>
                            <td>
                                <button className="btn btn-primary" onClick={() => verPerfil(cel.id)}> perfil </button>
                            </td>
                        </tr>
                    </tbody>
                ))}
            </table>

        </section>
    );
}