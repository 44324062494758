import "./PaginaBasePrivadaModerador.css"
import { Link, Outlet } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { useState } from "react";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import CampaignIcon from '@mui/icons-material/Campaign';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import InterpreterModeIcon from '@mui/icons-material/InterpreterMode';
import BarChartIcon from '@mui/icons-material/BarChart';

export default function PaginaBasePrivadaModerador() {
    const fakeNotificaçoes = [
        {
            mensagem: "O influenciador João aceitou sua proposta"
        },
        {
            mensagem: "Campanha criada com sucesso!"
        }
    ];
    const [notificacoes, setNofificacoes] = useState(fakeNotificaçoes);

    function limparNotificacoes() {
        setNofificacoes([]);
    }
    return (
        <>
            <nav className="navbar navbar-expand-lg bg-body-tertiary MenuPainel">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/moderador/inicio"> Painel Moderador </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link to="/moderador/inicio" className="nav-link">
                                    <HomeIcon fontSize="large" />
                                    Início
                                </Link>
                            </li>                           

                            <li className="nav-item">
                                <Link to="/moderador/aceitar-usuarios" className="nav-link">
                                <PersonAddAlt1Icon fontSize="large" />
                                    Aceitar
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link to="/moderador/usuarios-recusados" className="nav-link">
                                <PersonOffIcon fontSize="large" />
                                    Recusados
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link to="/moderador/editar-usuarios" className="nav-link">
                                <ManageAccountsIcon fontSize="large" />
                                    Editar
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link to="/moderador/usuarios-reciclados" className="nav-link">
                                <RecordVoiceOverIcon fontSize="large" />
                                    Reciclados
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link to="/moderador/usuarios-aceitos" className="nav-link">
                                <InterpreterModeIcon fontSize="large" />
                                    Aceitos
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link to="/moderador/campanhas-performance" className="nav-link">
                                <CampaignIcon fontSize="large" />
                                    Campanhas de Performance
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link to="/moderador/atualizacao" className="nav-link">
                                <AutorenewIcon fontSize="large" />
                                    Atualização
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link to="/moderador/estatistica-de-campanha" className="nav-link">
                                <BarChartIcon fontSize="large" />
                                    Estatísticas de campanhas
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link to="/login-administrativo" className="nav-link">
                                    <ExitToAppIcon fontSize="large" />
                                    Sair
                                </Link>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <NotificationsActiveIcon fontSize="large" />
                                    Notificações
                                </a>
                                <ul className="dropdown-menu">
                                    {/* <Notificacoes lista={notificacoes} />
                                    {notificacoes.length > 0 ?
                                        <li className="dropdown-item text-center limpar" onClick={limparNotificacoes}>
                                            Limpar
                                        </li> :
                                        <li className="dropdown-item">
                                            Nenhuma no momento
                                        </li>
                                    } */}
                                </ul>
                            </li>                            
                        </ul>
                    </div>

                </div>
            </nav>

            <Outlet />
        </>
    );
}