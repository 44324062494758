import styles from "./DetalhesCampanha.module.css"
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import WrapTextOutlinedIcon from '@mui/icons-material/WrapTextOutlined';
import EventNoteIcon from '@mui/icons-material/EventNote';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import TypeSpecimenIcon from '@mui/icons-material/TypeSpecimen';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import CategoryIcon from '@mui/icons-material/Category';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore/lite";
import OneKkIcon from '@mui/icons-material/OneKk';
import DescriptionIcon from '@mui/icons-material/Description';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import ICriarCampanha from "../../../types/ICriarCampanha";
import { db } from "../../../db/banco";

export default function VisualizarCampanha() {   
    // Pegar parametro da página
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const parametro = params.get("id"); // Parâmetro da página
    // recebe a campanha
    const [campanha, setCampanha] = useState<ICriarCampanha[]>([]);     
    // banco de dados
    const useCollectionRef = collection(db, "criarCampanha");
    // puxar dados campanha
    useEffect(() => {
        const obterCampanha = async () => {
            const dataBD = await getDocs(useCollectionRef);
            const todasCampanhas: ICriarCampanha[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as ICriarCampanha)

            const campanhaEncontrada: ICriarCampanha[] = todasCampanhas.filter(dis => dis.id === parametro);
            // console.log(campanhaEncontrada);
            setCampanha(campanhaEncontrada);
            console.log(campanhaEncontrada.map(lo => lo));
            console.log("campanha", campanhaEncontrada);
           
        }
        obterCampanha();
    }, [])

    function decodeHTML(html: any) {
        const temp = document.createElement('div');
        temp.innerHTML = html;
        return temp.innerText || temp.textContent || '';
    }

    return (
        <section className={styles.DetalhesCampanha}>
            <h2> Detalhes da campanha </h2>

            <form>
                <h3> Sobre a campanha </h3>
                {campanha.map(camp => (
                    <>
                        <div>
                            <DriveFileRenameOutlineIcon fontSize="large" />
                            <label> <strong> Nome: </strong> {camp.nomeDaCampanha} </label>
                        </div>

                        <div>
                            <WrapTextOutlinedIcon fontSize="large" />
                            <label> <strong> Tipo de campanha: </strong> {camp.tipoDeCampanha} </label>
                        </div>

                        <div>
                            <EventNoteIcon fontSize="large" />
                            <label> <strong> Inscrição aberta: </strong> {camp.inicioInscricao.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1')} </label>
                        </div>

                        <div>
                            <EventBusyOutlinedIcon fontSize="large" />
                            <label> <strong> Inscrição fechada: </strong> {camp.fimInscricao.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1')} </label>
                        </div>

                        <div>
                            <TypeSpecimenIcon fontSize="large" />
                            <label> <strong> Tipo de material: </strong> {camp.tipoDeMaterial} </label>
                        </div>

                        <div>
                            <CategoryIcon fontSize="large" />
                            <label> <strong> Nichos: </strong> {camp.nichos} </label>
                        </div>

                        <div>
                            <InsertLinkIcon fontSize="large" />
                            <label> <strong> Link: </strong> {camp.link} </label>
                        </div>

                        <div>
                            <DescriptionIcon fontSize="large" />
                            <label > <strong> Descrição: </strong> {decodeHTML(camp.descreva)} </label>
                        </div>
                    </>
                ))}
            </form>

            <form>
                <h3> Influenciador desejado </h3>
                {campanha.map(camp => (
                    <>
                        <div>
                            <AccountBoxIcon fontSize="large" />
                            <label > <strong> Gênero: </strong> {camp.genero} </label>
                        </div>

                        <div>
                            <AccountBoxIcon fontSize="large" />
                            <label><strong> Aceita outro gênero? </strong>{camp.switchGenero ? 'Sim' : 'Não'}</label>
                        </div>

                        <div>
                            <OneKkIcon fontSize="large" />
                            <label > <strong> Quantidade de seguidores: </strong> {camp.qtdSeguidores} </label>
                        </div>

                        <div>
                            <OneKkIcon fontSize="large" />
                            <label><strong> Aceita fora dessa quantidade de seguidores? </strong>{camp.switchQtdSeguidores ? 'Sim' : 'Não'}</label>
                        </div>

                        <div>
                            <CategoryIcon fontSize="large" />
                            <label > <strong> Nichos: </strong> {camp.categorias.map((categoria, index) => (
                                <React.Fragment key={index}>
                                    {categoria}
                                    {index !== camp.categorias.length - 1 && ', '}
                                </React.Fragment>
                            ))} </label>
                        </div>

                        <div>
                            <CategoryIcon fontSize="large" />
                            <label><strong> Aceita fora desse(s) nicho(s)? </strong>{camp.switchCategoria ? 'Sim' : 'Não'}</label>
                        </div>

                        <div>
                            <PermContactCalendarIcon fontSize="large" />
                            <label > <strong> Faixa de idade: </strong> de {camp.idadeInicio} a {camp.idadeFim} </label>
                        </div>

                        <div>
                            <PermContactCalendarIcon fontSize="large" />
                            <label><strong> Aceita fora dessa faixa de idade? </strong>{camp.switchIdade ? 'Sim' : 'Não'}</label>
                        </div>
                    </>
                ))}
            </form>            

        </section>
    );
}