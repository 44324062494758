import { useState } from "react";
import styles from "./InfluAcabaramTrabalho.module.css";
import { Radio } from "@mui/material";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

export default function InfluAcabaramTrabalho() {
    const [valor, setValor] = useState('');

    const trocaRadio = (event: any) => {
        setValor(event.target.value);
    };

    function confirmarTrabalho() {
        console.log(valor);
    }

    return (
        <>
            <h3 className={styles.H3}> Influenciadores que finalizaram o trabalho </h3>
            <section className={styles.InfluAcabaramTrabalho}>

                <p> <strong> Jéssica Maria da Silva </strong>, </p>
                <p> marcou que finalizou o trabalho, você confirma essa informação? </p>

                <div className={styles.Radio}>
                    <Radio
                        checked={valor === 'a'}
                        onChange={trocaRadio}
                        value="a"
                        name="radio-buttons"

                    />
                    <p> Fez o proposto </p>
                </div>

                <div className={styles.Radio}>
                    <Radio
                        checked={valor === 'b'}
                        onChange={trocaRadio}
                        value="b"
                        name="radio-buttons"
                    />
                    <p> Ainda não fez </p>
                </div>

                <p> <strong> Comprovantes: </strong> </p>
                <ul>
                    <li> delta1.png </li>
                </ul>

                <div>
                    <button className="btn btn-success" onClick={confirmarTrabalho} disabled={valor !== "a"}>
                        <AssignmentTurnedInIcon fontSize="medium" />
                        Trabalho feito
                    </button>
                </div>

            </section>
        </>
    )
}