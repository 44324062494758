import { useEffect, useState } from "react";
import styles from "./FiltroNotaFiscal.module.css";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { useLocation } from "react-router-dom";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import autenticaStore from "../../stores/autentica.store";

export default function FiltroNotaFiscal() {
     // obtem o email(serve como id) do anunciante
     const email = autenticaStore.getEmail();
    // Usado para navegar entre rotas
    const [comprovanteTrabalho, setComprovanteTrabalho] = useState("");
    const [downloadMidiaKit, setDownloadMidiaKit] = useState("");

    const [caminhoPdf, setCaminhoPdf] = useState<string[]>([""]);

    useEffect(() => {
        const storage = getStorage();
        const listRef = ref(storage, `gs://ism-teste.appspot.com/anunciante/${email}/notaFiscal`);
        const listaCaminhosPdfs: any = [];

        listAll(listRef)
            .then((res => {
                res.items.forEach((itemRef => {
                    listaCaminhosPdfs.push(itemRef.fullPath);
                    setCaminhoPdf(listaCaminhosPdfs);
                }))
            }));
    }, []);

    function baixarPdf(url: any) {
        window.open(url);
    }

    function pegaCaminho(caminho: string) {
        
        const caminhoEncontrado = caminho;
        criaDownload(caminhoEncontrado);
    }

    function criaDownload(caminhoRecebido: any) {
        const storage = getStorage();

        getDownloadURL(ref(storage, caminhoRecebido))
            .then((url) => {
                const xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.open('GET', url);
                xhr.send();
                baixarPdf(url);
            });
    }

    return (
        <section className={styles.EnviarTrabalho}>            
            <ul>
                {caminhoPdf.map(pdf => {
                    const indiceNotaFiscal = pdf.indexOf("/notaFiscal/");
                    const nomeArquivo = indiceNotaFiscal !== -1 ? pdf.substring(indiceNotaFiscal + 12) : pdf;

                    return (
                        <li onClick={() => pegaCaminho(pdf)} key={pdf}>
                            <FileDownloadIcon fontSize="medium" className={styles.Icone} />
                            <label>{nomeArquivo}</label>
                        </li>
                    );
                })}
            </ul>
        </section>
    );
}