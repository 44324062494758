import styles from "./Parceiros.module.css";
import { listaImagensParceiros } from "./listaImagensParceiros";

export default function Parceiros() {
    return(
        <section className={styles.Parceiros} id="parceiros">
            <div>
                {listaImagensParceiros.map(partner => (
                    <img src={partner.image} alt={partner.alt} key={partner.id}/>
                ))}
            </div>
            <aside>
                <h2> Parceiros </h2>    
                <p> Marcas que confiam no ISM </p>            
            </aside>
        </section>
    )
}