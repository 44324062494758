import { collection, getDocs } from "firebase/firestore/lite";
import styles from "./VerPerfilAnunFinanceiro.module.css";
import { db } from "../../../../../db/banco";
import { useEffect, useState } from "react";
import IAnunciante from "../../../../../types/IAnunciante";
import { Link, useNavigate } from "react-router-dom";


export default function VerPerfilAnunFinanceiro() {
    const navigate = useNavigate();
    // influenciador
    const [anunciantes, setAnunciantes] = useState<IAnunciante[]>([]);
    // puxar dados se for influenciador
    const useCollectionRef = collection(db, "anunciante");
    useEffect(() => {
        const obterAnunciantes = async () => {
            const dataBD = await getDocs(useCollectionRef);
            const todosAnunciantes: IAnunciante[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id } as IAnunciante));
            setAnunciantes(todosAnunciantes);
        }
        obterAnunciantes();

    }, [])

    function notaFiscal(id: string, idEnviado: string, nomeAnunciante: string) {
        navigate(`/financeiro/nota-fiscal-anunciante?id=${id}&idEnviado=${idEnviado}&nomeAnunciante=${nomeAnunciante}`)
    }

    function verPerfil(id: string) {
        navigate(`/financeiro/ver-perfil-anunciante?id=${id}`)
    }

    return (
        <section className={styles.VerPerfilAnunFinanceiro}>
            <h3> Lista de anunciantes </h3>

            <table className="table table-light table-striped text-center ">
                <thead>
                    <tr>
                        <th scope="col" className="text-white"> Nome </th>
                        <th scope="col" className="text-white"> Status </th>
                        <th scope="col" className="text-white"> Ações </th>                        
                    </tr>
                </thead>

                {anunciantes.map(cel => (
                    <tbody>
                        <tr>
                            <td onClick={() => verPerfil(cel.id)} className={styles.Perfil}> {cel.nomeCompleto} </td>
                            <td className={cel.notaFiscalEnviada ? styles.enviada : styles.naoEnviada}> {cel.notaFiscalEnviada ? "Enviada" : "Não enviada"}</td>
                            <td>
                                <button className="btn btn-primary" onClick={() => notaFiscal(cel.email, cel.id, cel.nomeCompleto)}> Enviar nota fiscal </button>
                            </td>
                        </tr>
                    </tbody>
                ))}
            </table>

        </section>
    );
}