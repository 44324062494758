import { useEffect, useState } from "react";
import styles from "./Segmentos.module.css";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import { register } from 'swiper/element/bundle';
import { Swiper, SwiperSlide } from 'swiper/react'
import { listaItensCarrossel } from "./listaItensCarrossel";

// Precisa pra rodar o carrossel
register();

export default function Segmentos() {
    // Controle da quantidade de itens do Carrossel
    const [slidesPerView, setSliderPerView] = useState(5);
    // Muda a quantidade de itens de acordo com o tamanho da tela    
    useEffect(() => {
        // Pensar uma forma melhor e diminuir quantidade de ifs
        function mudaPorTamanho() {
           if (window.innerWidth < 720) setSliderPerView(3);           
           if (window.innerWidth > 720 && window.innerWidth < 1450) setSliderPerView(5);    
           if (window.innerWidth > 1460) setSliderPerView(7);      
           if (window.innerWidth > 2200) setSliderPerView(11);  
           if (window.innerWidth > 3500) setSliderPerView(14);  
           if (window.innerWidth > 3500) setSliderPerView(16); 
           if (window.innerWidth > 4500) setSliderPerView(17); 
        }
        mudaPorTamanho();
    }, [])
    return(
        <section className={styles.Segmentos} id='categorias'>
            <h2> Trabalhamos com diversos segmentos </h2>
            <div>
                <Swiper
                    slidesPerView={slidesPerView}
                    pagination={{ clickable: true }}
                    navigation
                    autoplay

                    effect={'coverflow'}
                    grabCursor={true}
                    centeredSlides={true}
                    loop={true}
                    
                    coverflowEffect={{
                        rotate: 0,
                        stretch: 0,
                        depth: 100,
                        modifier: 2.5,
                    }}
                >
                    {listaItensCarrossel.map(item => (
                        <SwiperSlide key={item.id}>
                            <img src={item.image} alt={item.alt}/>
                            <p> {item.label} </p>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </section>
    )
}