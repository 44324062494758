import { collection, addDoc } from "firebase/firestore/lite";
import { db } from "../../db/banco";
import axios from "axios";
// Notificações
// Criar notficação do influenciador aceito em uma campanha
// banco de dados
const useCollectionRefNotificacao = collection(db, "notificacao");
export async function criarNotificacaoInfluenciadorAceitoEmCampanha(nomeDaCampanha: string, emailDoInfluenciador: string) {
    const notificacao = await addDoc(useCollectionRefNotificacao, {
        mensagem: `O anunciante, te aceitou na campanha: ${nomeDaCampanha}!`,
        visto: false,
        tipoDoUsuarioQueVaiReceberNotificacao: "influenciador",
        emailDeQuemVaiReceberNotificacao: emailDoInfluenciador,
        data: new Date().toLocaleDateString(),
        link: "https://influenciesuamarca.com.br/influenciador/minhas-campanhas"
    })
}

export async function criarNovaNotificacaoCampanhaInvisivel(emailAnunciante: string) {
    const notificacao = await addDoc(useCollectionRefNotificacao, {
        mensagem: `Anunciante, sua campanha se tornou invisível para os influenciadores. Adicione saldo nela, para se tornar visível novamente!`,
        visto: false,
        tipoDoUsuarioQueVaiReceberNotificacao: "anunciante",
        emailDeQuemVaiReceberNotificacao: emailAnunciante,
        data: new Date().toLocaleDateString(),
        link: "https://influenciesuamarca.com.br/anunciante/minhas-campanhas"
    })
}
// Enviar e-mail
// Enviar e-mail para influenciador aceito em campanha
export const enviarEmailParaInfluenciadorAceitoEmCampanha = async (emailDoInfluenciador: string, nomeDoInflueciador: string) => {
    try {
        const response = await axios.post(
            "https://us-central1-ism-teste.cloudfunctions.net/enviarEmail",
            {
                destinatario: emailDoInfluenciador,
                assunto: "Você foi aprovado!",
                mensagem: `Oii, ${nomeDoInflueciador}\n\n` +

                    "Temos uma ótima notícia para te dar!\n\n" +

                    "O anunciante aprovou a sua participação na campanha 💜\n\n" +

                    "Agora você pode acessar os materiais de divulgação na página da campanha e começar as\n" +
                    "postagens de acordo com as instruções ditas na plataforma e/ou o que foi alinhado com o\n" +
                    "anunciante pelo chat\n\n" +

                    "Não perca tempo! Acesse agora mesmo a nossa plataforma e comece a influenciar! 🙂\n\n" +

                    "Equipe ISM"
            }
        );

        const { id } = response.data;
        return id;
    } catch (error) {
        // console.log(error);
    }
};

export const enviarEmailParaAnuncianteCampanhaInvisivel = async (emailAnunciante: string) => {
    try {
        const response = await axios.post(
            "https://us-central1-ism-teste.cloudfunctions.net/enviarEmail",
            {
                destinatario: emailAnunciante,
                assunto: "Sua campanha está invisível para os influenciadores!",
                mensagem: `Oii, anunciante\n\n` +

                    "Sua campanha está com saldo de menos de R$100,00!\n\n" +

                    "Quando isso acontece, a campanha se torna invisível para os influenciadores\n\n" +

                    "Vá em minhas campanhas e adicione um saldo nessa campanha!\n" +

                    "Equipe ISM"
            }
        );

        const { id } = response.data;
        return id;
    } catch (error) {
        // console.log(error);
    }
};