import { Link, useNavigate } from "react-router-dom";
import styles from "./EditarAnunciantes.module.css";
import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore/lite";
import IAnunciante from "../../../../../types/IAnunciante";
import { db } from "../../../../../db/banco";
import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import CampoEscolha from "../../../../../components/CampoEscolha";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import CampoTexto from "../../../../../components/CampoTexto";
import guardaPaginaAnterior from "../../../../../stores/paginaAnterior";
import { TextField as T, Autocomplete } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import ChoiceField from "../../../../../components/ChoiceField";
import { listaFiltroModeradorPorAnunciante } from "../../../../../utils/listas";
import { autocompleteStyles } from "../../../../../utils/styles";

export default function EditarAnunciantes() {
    // Aux para atualizar página
    const [aux, setAux] = useState(0);
    const navigate = useNavigate();
    // recebe e controla os influenciadores
    const [anunciantes, setAnunciantes] = useState<IAnunciante[]>([]);
    const [anunciantesAux, setAnunciantesAux] = useState<IAnunciante[]>([]);
    const [anuncianteFiltrado, setAnuncianteFiltrado] = useState<IAnunciante[]>([]);
    // filtro
    const [Tipofiltro, setTipoFiltro] = useState("");
    const listaFiltro = ["email"];
    const [textoFiltro, setTextoFiltro] = useState("");
    // filtro inteligente
    const [filtrarPor, setFiltrarPor] = useState("");
    const [filtroPorNome, setFiltroPorNome] = useState("");
    const [filtrarPorEmail, setFiltrarPorEmail] = useState("");
    const [filtrarPorRazaoSocial, setFiltroPorRazaoSocial] = useState("");
    const useCollectionRefInfluenciador = collection(db, "anunciante");
    // obtem os anunciantes
    useEffect(() => {
        const obterInfluenciadores = async () => {
            const dataBD = await getDocs(useCollectionRefInfluenciador);
            const todosAnunciantes: IAnunciante[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as IAnunciante);
            setAnunciantes(todosAnunciantes);
            setAnunciantesAux(todosAnunciantes);

        };
        obterInfluenciadores();
    }, []);


    function irParaPerfil(email: string) {
        navigate(`/moderador/editar-perfil-anunciante?id=${email}&pg=${pagina}`);
    }

    function irParaCampanhas(id: string) {
        navigate(`/moderador/ver-camapanhas?id=${id}&pg=${pagina}`)
    }

    // Paginação

    const [pagina, setPagina] = useState(1);

    const handleChangePagina = (event: any, value: any) => {
        setPagina(value);
    };
    const linhasPorPagina = 10;
    const startIndex = (pagina - 1) * linhasPorPagina;
    const endIndex = startIndex + linhasPorPagina;
    const anunciantesPaginados = anunciantes.slice(startIndex, endIndex);

    // Filtro

    function filtrarAnunciantes() {
        if (textoFiltro.length > 0) {
            let resultadoFiltrado: IAnunciante[] = [...anunciantesAux];
            resultadoFiltrado = resultadoFiltrado.filter((filtrado) => filtrado.email === textoFiltro);
            if (resultadoFiltrado.length === 1) setAnuncianteFiltrado(resultadoFiltrado);
            else alert("Esse email não corresponde a essa pesquisa.");

        } else {
            alert("Campo em branco, digite um e-mail pare realizar a pesquisa.")
        }
    }

    // voltar pra página
    useEffect(() => {
        const guardaPagina = guardaPaginaAnterior.getPagina();
        const convertePagina = Number(guardaPagina);

        if (convertePagina > 0) {
            setPagina(convertePagina);
            setAux(convertePagina);
        }
    }, [aux]);

    function filtrar() {
        if (filtrarPor === "nome") {
            let resultadoFiltrado: IAnunciante[] = [...anunciantesAux];
            const recebeFiltro: IAnunciante[] = resultadoFiltrado.filter(porNome => porNome.nomeCompleto === filtroPorNome);
            setAnuncianteFiltrado(recebeFiltro);
        }

        if (filtrarPor === "email") {
            let resultadoFiltrado: IAnunciante[] = [...anunciantesAux];
            const recebeFiltro: IAnunciante[] = resultadoFiltrado.filter(porNome => porNome.email === filtrarPorEmail);
            setAnuncianteFiltrado(recebeFiltro);
        }

        if (filtrarPor === "razão social") {
            let resultadoFiltrado: IAnunciante[] = [...anunciantesAux];
            const recebeFiltro: IAnunciante[] = resultadoFiltrado.filter(porNome => porNome.razaoSocial === filtrarPorRazaoSocial);
            setAnuncianteFiltrado(recebeFiltro);

            if (recebeFiltro.length === 0) alert("Não existe influenciador com essa categoria aceito na plataforma!");
        }
    }

    return (
        <section className={styles.EditarAnunciantes}>
            <h3> Lista de anunciantes </h3>

            <aside>
                <ChoiceField
                    title=""
                    control={setFiltrarPor}
                    icon={<FilterAltIcon fontSize="large" />}
                    list={listaFiltroModeradorPorAnunciante}
                    defaultValue="Escolha"
                />

                {filtrarPor === "nome" &&
                    <>
                        <Autocomplete
                            sx={autocompleteStyles}
                            freeSolo
                            options={anunciantes.map((option) => option.nomeCompleto)}
                            onInputChange={(event, newInputValue) => {
                                setFiltroPorNome(newInputValue);
                            }}
                            renderInput={(params) => (
                                <T

                                    {...params}
                                    label="Nome do anunciante"
                                    placeholder="Digite o nome"
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <>
                                                <PersonIcon fontSize="large" />
                                                {params.InputProps.startAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            )}
                        />

                        <button className="btn btn-info btn-lg mb-2 ml-3" onClick={filtrar}> Filtrar </button>

                    </>}

                {filtrarPor === "email" &&
                    <>
                        <Autocomplete
                            sx={autocompleteStyles}
                            freeSolo
                            options={anunciantes.map((option) => option.email)}
                            onInputChange={(event, newInputValue) => {
                                setFiltrarPorEmail(newInputValue);
                            }}
                            renderInput={(params) => (
                                <T

                                    {...params}
                                    label="E-mail do anunciante"
                                    placeholder="Digite o email"
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <>
                                                <PersonIcon fontSize="large" />
                                                {params.InputProps.startAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            )}
                        />

                        <button className="btn btn-info btn-lg mb-2 ml-3" onClick={filtrar}> Filtrar </button>

                    </>}

                {filtrarPor === "razão social" &&
                    <>
                        <Autocomplete
                            sx={autocompleteStyles}
                            freeSolo
                            options={anunciantes.map((option) => option.razaoSocial)}
                            onInputChange={(event, newInputValue) => {
                                setFiltroPorRazaoSocial(newInputValue);
                            }}
                            renderInput={(params) => (
                                <T

                                    {...params}
                                    label="Razão social do anunciante"
                                    placeholder="Digite a razão social"
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <>
                                                <PersonIcon fontSize="large" />
                                                {params.InputProps.startAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            )}
                        />

                        <button className="btn btn-info btn-lg mb-2 ml-3" onClick={filtrar}> Filtrar </button>

                    </>}

            </aside>

            {anuncianteFiltrado.length > 0 && <h6> Resultado da pesquisa </h6>}

            {anuncianteFiltrado.map((anuncianteEncontrado, index) => (
                <div className="table-responsive">
                    <table className="table table-light table-striped text-center">
                        <thead>
                            <tr>
                                <th scope="col" className="text-white" style={{ width: '33%' }}>Nome</th>
                                <th scope="col" className="text-white" style={{ width: '33%' }}>Razão social</th>
                                <th scope="col" className="text-white" style={{ width: '33%' }}>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr key={anuncianteEncontrado.id} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
                                <td className="align-middle text-center" style={{ width: '33%' }}>
                                    <Link to={`/moderador/editar-perfil-anunciante?id=${anuncianteEncontrado.email}&pg=${pagina}`}>{anuncianteEncontrado.nomeCompleto}</Link>
                                </td>
                                <td className="align-middle text-center" style={{ width: '33%' }}>
                                    {anuncianteEncontrado.razaoSocial}
                                </td>
                                <td className="align-middle" style={{ width: '33%' }}>
                                    <div className="d-flex justify-content-center">
                                        <button className="btn btn-primary me-2" onClick={() => irParaPerfil(anuncianteEncontrado.email)}>perfil</button>
                                        <button className="btn btn-secondary" onClick={() => irParaCampanhas(anuncianteEncontrado.id)}>campanhas</button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            ))}

            <h6> Lista dos anunciantes em espera </h6>

            {anunciantesPaginados.length > 0 ? <Stack spacing={2}>
                <table className="table table-light table-striped text-center">
                    <thead>
                        <tr>
                            <th scope="col" className="text-white" style={{ width: '33%' }}>Nome</th>
                            <th scope="col" className="text-white" style={{ width: '33%' }}>Razão social</th>
                            <th scope="col" className="text-white" style={{ width: '33%' }}>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {anunciantesPaginados.map((cel, index) => (
                            <tr key={cel.id} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
                                <td className="align-middle text-center" style={{ width: '33%' }}>
                                    <Link to={`/moderador/editar-perfil-anunciante?id=${cel.email}&pg=${pagina}`}>{cel.nomeCompleto}</Link>
                                </td>
                                <td className="align-middle text-center" style={{ width: '33%' }}>
                                    {cel.razaoSocial}
                                </td>
                                <td className="align-middle" style={{ width: '33%' }}>
                                    <div className="d-flex justify-content-center">
                                        <button className="btn btn-primary me-2" onClick={() => irParaPerfil(cel.email)}>perfil</button>
                                        <button className="btn btn-secondary" onClick={() => irParaCampanhas(cel.id)}>campanhas</button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>


                <Pagination
                    count={Math.ceil(anunciantes.length / linhasPorPagina)}
                    page={pagina}
                    onChange={handleChangePagina}
                />
            </Stack> : <p> Não existe anunciantes nessa pesquisa </p>}
        </section>

    );
}