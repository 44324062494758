import Chamado from "../../../components/Chamado";
import aceitoStore from "../../../stores/aceito";
import UsuarioNaoAceitoInfluenciador from "../../UsuarioNaoAceito/UsuarioNaoAceitoInfluenciador";
import styles from "./AbrirChamadoInfluenciador.module.css"

export default function AbrirChamadoInfluenciador() {
    // verifica se usuário foi aceito na plataforma
    const statusPlataforma = aceitoStore.getStatus();

    return(
        <>
            {statusPlataforma === "aceito" ? <section className={styles.AbrirChamadoInfluenciador}>
            <h2> Abrir chamado </h2>

            <Chamado />
        </section> : <UsuarioNaoAceitoInfluenciador />}
        </>
    );
}