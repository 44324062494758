import { useEffect, useState } from "react";
import styles from "./FiltroInfluenciador.module.css";
import CampoEscolha from "../CampoEscolha";

import TypeSpecimenOutlinedIcon from '@mui/icons-material/TypeSpecimenOutlined';
import BotaoSubmit from "../BotaoSubmit";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import TabelaInfluenciador from "../TabelaInfluenciador";
import { collection, getDocs } from "firebase/firestore/lite";
import { db } from "../../db/banco";
import ICriarCampanha from "../../types/ICriarCampanha";
import numeroCampanhas from "../../stores/numeroCampanhas.store";
import { listaTiposDeCampanha } from "../../utils/listas";

interface FiltroInfluenciadorProps {
    qual?: string;
}

export default function FiltroInfluenciador({ qual }: FiltroInfluenciadorProps) {

    const [nome, setNome] = useState("");
    const [tipoDeCampanha, setTipoDeCampanha] = useState("");
    const [ordenar, setOrdenar] = useState("");
    // Recebe as campanhas
    const [campanhas, setCampanhas] = useState<ICriarCampanha[]>([]);
    const [campanhaAux, setCampanhaAux] = useState<ICriarCampanha[]>([]);

    // banco de dados
    const useCollectionRef = collection(db, "criarCampanha");

    // puxar dados
    useEffect(() => {
        const obterCampanhas = async () => {
            const dataBD = await getDocs(useCollectionRef);
            const todasCampanhas: ICriarCampanha[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as ICriarCampanha)

            const campanhasDisponiveis = todasCampanhas.filter(dis => dis.status === "ativa" && dis.saldoCampanha >= 100);
            numeroCampanhas.pegarCampanhasDisponiveis({quantidade: campanhasDisponiveis.length})            
            

            if (qual === "disponiveis") {                
                setCampanhas(campanhasDisponiveis);
                setCampanhaAux(campanhasDisponiveis);                
                return
            }

            const campanhasFinalizadas = todasCampanhas.filter(dis => dis.status === "desativada");
            numeroCampanhas.pegarCampanhasFinalizadas({quantidade: campanhasFinalizadas.length});

            if (qual === "finalizadas") {
                
                setCampanhas(campanhasFinalizadas);
                setCampanhaAux(campanhasFinalizadas);                
                return
            }

        }
        obterCampanhas();
    }, [])

    function filtrar(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

       

        let resultadoFiltrado: ICriarCampanha[] = [...campanhaAux]; // Cria uma cópia do array original

        if (tipoDeCampanha !== "Todas") {
            resultadoFiltrado = resultadoFiltrado.filter(
                (filtradoTipoCampanha) => filtradoTipoCampanha.tipoDeCampanha === tipoDeCampanha
            );
        } else {
            resultadoFiltrado = campanhaAux
        }

        setCampanhas(resultadoFiltrado); // Define o estado da campanha com o resultado filtrado
    }
    return (
        <>
            <form className={styles.FiltroInfluenciador} onSubmit={filtrar}>
                {/* <CampoTexto
                    titulo="Nome da campanha?"
                    valor={nome}
                    controle={setNome}
                    icone={<CreateOutlinedIcon fontSize="large" />}
                    placeholder="Riot Games"
                /> */}
                <CampoEscolha
                    titulo="Tipo de campanha"
                    lista={listaTiposDeCampanha}
                    icone={<TypeSpecimenOutlinedIcon fontSize="large" />}
                    controle={setTipoDeCampanha}
                    valorPadrao="Selecione entre os tipos"
                    todas
                />
                {/* <CampoEscolha
                    titulo="Ordenar por"
                    lista={listaOrdenar}
                    icone={<AlignHorizontalLeftOutlinedIcon fontSize="large" />}
                    controle={setOrdenar}
                    valorPadrao="Como prefere organizar?"
                /> */}

                <BotaoSubmit classe="btn btn-secondary">
                    <SearchOutlinedIcon fontSize="large" />
                    Pesquisar
                </BotaoSubmit>
            </form>

            <TabelaInfluenciador campanhas={campanhas} />
        </>
    );
}