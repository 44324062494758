import Chamado from "../../../components/Chamado";
import aceitoStore from "../../../stores/aceito";
import UsuarioNaoAceitoAnunciante from "../../UsuarioNaoAceito/UsuarioNaoAceitoAnunciante";
import styles from "./AbrirChamadoAnunciante.module.css"

export default function AbrirChamadoAnunciante() {
    // verifica se usuário foi aceito na plataforma
    const statusPlataforma = aceitoStore.getStatus();

    return(
        <>
            {statusPlataforma === "aceito" ? <section className={styles.AbrirChamadoAnunciante}>
            <h2> Abrir chamado </h2>

            <Chamado />
        </section> : <UsuarioNaoAceitoAnunciante />}
        </>
    );
}