import styles from "./TermosDeUso.module.css";
import BotaoVoltar from "../../../components/BotaoVoltar"
import { Link } from "react-router-dom";

export default function TermosDeUso() {
    // Ao abrir a pagina, ir sempre para o topo
    window.scrollTo(0,0);
    return (
        <section className={styles.TermosDeUso}>
            <BotaoVoltar />
            <h2> TERMOS E CONDIÇÕES DE USO DO SITE </h2>

            <p> 1 - Este Termo refere-se ao <strong> Influencie sua Marca </strong>. Ao navegar neste site e usar
                os serviços que são fornecidos, você AFIRMA que LEU, COMPREENDEU e CONCORDA com nossos Termos e Condições.
                Estes Termos e Condições abrangem todos os aplicativos, serviços de Internet ou extensões dos sites relacionados.
                Caso você não concorde ou não tenha ficado claro algum ponto, sugere-se que você NÃO NAVEGUE MAIS NELE
                até que você tenha sanado todas as suas dúvidas.
                Você poderá ainda, a qualquer tempo, retornar ao site, clicar e termos de uso e reler quantas vezes desejar.
            </p>

            <h2> Termos e Condições </h2>

            <p> 2 - Os Termos e Condições do <strong> Influencie Sua Marca </strong> regem o uso deste Site e todo
                o seu conteúdo ("Site").
                Estes Termos descrevem as regras e regulamentos que orientam o uso de <strong> Influencie Sua Marca </strong>
                localizado em <strong>
                    <a href="https://influenciesuamarca.com.br/" target="_blank" rel="noreferrer"> https://influenciesuamarca.com.br/
                    </a> </strong>.
                Todos os materiais/informações/documentos/serviços ou todas as outras entidades
                (coletivamente referidas como "conteúdo") que aparecem no
                <strong>
                    <a href="https://influenciesuamarca.com.br/" target="_blank" rel="noreferrer"> https://influenciesuamarca.com.br/
                    </a> </strong> serão administrados de acordo com estes Termos e Condições.
            </p><br />

            <p> <strong> ATENÇÃO: </strong> Não continue a usar este site se você tiver qualquer objeção a qualquer um dos Termos
                e Condições declarados nesta página.
            </p><br />

            <p> 3 - O site é destinado a usuários com 18 (dezoito) anos de idade ou mais.
                Se você tem menos de 18 (dezoito) anos, não poderá usar ou registrar-se para usar este site ou seus serviços sem a permissão ou consentimento dos pais.
                Ao concordar com estes Termos e Condições, você tem a capacidade legal necessária para cumprir e ficar vinculado por estes Termos e Condições.
            </p><br />

            <p> 4 - <strong> Influencie Sua Marca </strong> faz uso de cookies. Ao acessar nosso site, você concorda em usar cookies de acordo com nossa Política de Cookies.
                "Algum dos nossos parceiros de site podem usar cookies."
            </p><br />

            <p> 5 - <strong> Influencie Sua Marca </strong>  detêm os direitos de propriedade intelectual de todo o conteúdo.
                Todos os direitos de propriedade intelectual são reservados.
                Você pode acessar qualquer conteúdo do site para seu uso pessoal, sujeito às restrições
                definidas nestes termos e condições.
            </p><br />

            <p> <strong> Influencie Sua Marca </strong>, por meio deste, determina que o usuário do site não cometa
                as seguintes ações:
            </p><br />

            <ul>
                <li> *Reproduzir, republicar, duplicar ou copiar qualquer conteúdo do <strong> Influencie Sua Marca</strong>; </li>
                <li> *Vender, alugar, sublicenciar e/ou de outra forma comercializar qualquer conteúdo do
                    <strong> Influencie Sua Marca</strong>; </li>
                <li> *Executar e / ou exibir publicamente qualquer conteúdo do <strong> Influencie Sua Marca</strong>; </li>
                <li> *Usar este site de forma que seja, ou talvez, danifique e/ou afete o acesso do usuário a este site; </li>
                <li> *Usar este site contrário às regras, leis e regulamentos relevantes do seu país de residência,
                    ou de uma maneira que cause, ou possa causar, danos ao site ou a qualquer pessoa ou entidade comercial; </li>
                <li> *Realizar mineração de dados ou qualquer outra atividade semelhante relacionada a este site,
                    ou durante o uso deste site; </li>
                <li> *Usando este site para se envolver em qualquer forma de publicidade ou marketing empresarial. </li>
            </ul><br />

            <p> 6 - Áreas específicas deste site podem ser restritas ao acesso do usuário, e <strong> Influencie Sua Marca</strong>
                pode estender ainda mais essa restrição a todo o site, a qualquer momento e a seu exclusivo critério.
                Qualquer identificação de usuário, chave de segurança ou senha que você possa ter neste site são
                confidenciais e você é responsável por manter a confidencialidade dessas informações.
            </p>

            <h2> Link e Hiperlink </h2>

            <p> 7 - Nós nos reservamos o direito de registrar solicitações para que você remova todos os links ou qualquer
                link específico criado por você que redirecione para o nosso site, e você aprova a remoção imediata desses
                links para o nosso site, mediante solicitação.
            </p><br />

            <p> 8 - Podemos alterar os termos e condições desses direitos de vinculação a qualquer momento.
                Ao conectar-se continuamente ao nosso site, você concorda em se comprometer e seguir os termos desta
                política de links.
            </p>

            <p> 9 - Por favor, entre em contato conosco se encontrar algum link em nosso site que seja ofensivo,
                e poderemos considerar e analisar solicitações de remoção de tais links.
            </p>

            <h2> Link para conteúdo de terceiros </h2>

            <p> 10 - Este site pode conter links para sites ou aplicativos operados por terceiros.
                Não controlamos nenhum desses sites ou aplicativos de terceiros ou o operador de terceiros.
                Este Site, objeto do presente Termos de Uso não é responsável e não endossa quaisquer sites
                ou aplicativos de terceiros ou sua disponibilidade ou conteúdo. <strong> Influencie Sua Marca </strong>
                não se responsabiliza pelos anúncios contidos no site. Você concorda em fazê-lo por sua própria
                conta e risco ao adquirir quaisquer bens e / ou serviços de terceiros. O anunciante é quem permanece
                responsável por tais bens e/ou serviços, e se você tiver alguma dúvida ou reclamação sobre eles,
                você deve entrar em contato com o anunciante.
            </p><br />

            <h2> Conteúdo do usuário </h2>

            <p> 11 - Importante salientar que o termo usado "Conteúdo do Usuário" significa qualquer áudio, vídeo, texto,
                imagens ou outro material ou conteúdo que você decida exibir neste Site.
                Com relação ao conteúdo do usuário, ao exibi-lo, você concede ao <strong> Influencie Sua Marca </strong>
                uma licença não exclusiva, mundial, irrevogável, isenta de royalties e sublicenciável para usar,
                reproduzir, adaptar, publicar, traduzir e distribuir em qualquer mídia.
            </p><br />

            <p> 12 - .O Conteúdo do Usuário deve ser seu e não deve infringir os direitos de terceiros.
                <strong> Influencie Sua Marca </strong>  reserva-se o direito de remover qualquer parte do seu
                conteúdo deste site a qualquer momento, sem aviso prévio. <strong> Influencie Sua Marca </strong>
                tem permissão para monitorar suas atividades no site e remover qualquer conteúdo do usuário considerado
                impróprio, ofensivo, contrário às leis e regulamentos aplicáveis, ou que cause uma violação destes
                Termos e Condições.
            </p>

            <h2> Disposições Gerais </h2>

            <p> 13 - Os Termos e Condições deste site serão regidos e interpretados de acordo com as leis do país ou estado
                em que o Site opera.
                Você, por meio deste, se submete incondicionalmente à jurisdição não exclusiva dos tribunais localizados
                no Brasil para a resolução de quaisquer disputas.
            </p><br />

            <p> 14 - Este Site reserva-se o direito de revisar estes Termos a qualquer momento conforme julgar adequado.
                Por isso é fundamental que os seus usuários estejam atentos à essas aleterações.
            </p><br />

            <p> 15 - O Site reserva-se o direito de ceder, transferir e subcontratar seus direitos e/ou obrigações sob
                este Acordo sem qualquer notificação ou consentimento prévio necessário.
                Os usuários não terão permissão para atribuir, transferir ou subcontratar qualquer um de seus direitos
                e/ou obrigações sob estes Termos.
                Além disso, uma pessoa que não seja parte destes Termos e Condições não terá o direito de fazer
                cumprir qualquer disposição neles contida.
            </p><br />

            <p> 16 - Estes Termos e Condições, incluindo quaisquer avisos legais e isenções de responsabilidade neste site,
                constituem o acordo completo entre o Site e você em relação ao uso deste site.
                Em última análise, este Acordo substitui todos os acordos e entendimentos anteriores relativos ao mesmo.
            </p><br />

            <h2> Política de Privacidade </h2>

            <p> 17 - Acesse a nossa <strong> <Link to="/policity"> Política & Privacidade </Link> </strong>
                para saber mais detalhes.
            </p><br />

            <p> 18 - Qualquer dúvida, entre em contato por meio do endereço de e-mail: <strong>
                <a href="mailto:contato@influenciesuamarca.com.br"> contato@influenciesuamarca.com.br </a> </strong>
            </p>
        </section>
    )
}