import { collection, getDocs } from "firebase/firestore/lite";
import styles from "./VerPerfilInfluFinanceiro.module.css";
import { db } from "../../../../../db/banco";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import IChatPropostas from "../../../../../types/ICharPropostas";

export default function VerPerfilInfluFinanceiro() {
    const navigate = useNavigate();
    // influenciador
    const [propostas, setPropostas] = useState<IChatPropostas[]>([]);
    // puxar dados se for influenciador
    const useCollectionRef = collection(db, "chatPropostas");
    useEffect(() => {
        const obterInfluenciadores = async () => {
            const dataBD = await getDocs(useCollectionRef);
            const todasPropostas: IChatPropostas[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id } as IChatPropostas))
            setPropostas(todasPropostas)
            console.log(todasPropostas);
        }
        obterInfluenciadores();

    }, [])

    function verNotaFiscal(id: string, nomeCampanha: string) {
        navigate(`/financeiro/nota-fiscal-influenciador?id=${id}&nome=${nomeCampanha}`)
    }

    function verPerfil(id: string) {
        navigate(`/financeiro/ver-perfil-influenciador?id=${id}`)
    }

    return (
        <section className={styles.VerPerfilInfluFinanceiro}>
            <h3> Lista de fatura dos influenciadores </h3>

            <table className="table table-light table-striped text-center ">
                <thead>
                    <tr>
                        <th scope="col" className="text-white"> Nome </th>
                        <th scope="col" className="text-white"> Status Nota fiscal </th>
                        <th scope="col" className="text-white"> Ações </th>
                    </tr>
                </thead>



                {propostas.map(cel => (
                    <tbody>
                        <tr>
                            <td onClick={() => verPerfil(cel.idInfluenciador)} className={styles.Perfil}> {cel.nomeInfluenciador} </td>                            
                            <td className={cel.notaFiscalEnviada ? styles.enviada : styles.naoEnviada}> {cel.notaFiscalEnviada ? "Enviada" : "Não enviada"}</td>
                            <td>
                                <button className="btn btn-primary" onClick={() => verNotaFiscal(cel.emailInfluenciador, cel.nomeCampanha)}> Ver nota fiscal </button>
                            </td>
                        </tr>
                    </tbody>
                ))}
            </table>

        </section>
    );
}