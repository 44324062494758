import { getDownloadURL, getStorage, listAll, ref, uploadBytesResumable } from "firebase/storage";
import styles from "./EnviarTrabalho.module.css";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import autenticaStore from "../../../../stores/autentica.store";
import { db, storage } from "../../../../db/banco";
import CampoTexto from "../../../../components/CampoTexto";
import { doc, updateDoc } from "firebase/firestore/lite";
import axios from "axios";

export default function EnviarNotaFiscalInflu() {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const parametro = params.get("id"); // Parâmetro da página
    const idRecebido = params.get("idEnviado"); // Parâmetro da página
    const nomeAnunciante = params.get("nomeAnunciante");
    // Usado para navegar entre rotas
    const navigate = useNavigate()
    const [comprovanteTrabalho, setComprovanteTrabalho] = useState("");
    const [downloadMidiaKit, setDownloadMidiaKit] = useState("");

    const [caminhoPdf, setCaminhoPdf] = useState<string[]>([""]);
    // parametros
    const [emailVindo, setEmailVindo] = useState("");
    const [idVindo, setIdVindo] = useState("");


    useEffect(() => {
        if (parametro) setEmailVindo(parametro);
        if (idRecebido) setIdVindo(idRecebido);
    }, []);

    useEffect(() => {
        const storage = getStorage();
        const listRef = ref(storage, `gs://ism-teste.appspot.com/anunciante/${parametro}/notaFiscal`);
        const listaCaminhosPdfs: any = [];

        listAll(listRef)
            .then((res => {
                res.items.forEach((itemRef => {
                    listaCaminhosPdfs.push(itemRef.fullPath);
                    setCaminhoPdf(listaCaminhosPdfs);
                }))
            }));
    }, []);

    function baixarPdf(url: any) {
        window.open(url);
    }

    function pegaCaminho(caminho: string) {
        
        const caminhoEncontrado = caminho;
        criaDownload(caminhoEncontrado);
    }

    function criaDownload(caminhoRecebido: any) {
        const storage = getStorage();

        getDownloadURL(ref(storage, caminhoRecebido))
            .then((url) => {
                const xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.open('GET', url);
                xhr.send();
                baixarPdf(url);
            });
    }

    const uploadMidiaKit = (event: any) => {
        event.preventDefault();
        const file = event.target[0]?.files[0];
        if (!file) return;

        const storageRef = ref(storage, `anunciante/${parametro}/notaFiscal/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            "state_changed",
            () => {
            },
            error => {
                alert(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then(url => {
                    setComprovanteTrabalho(url);
                    alert(`Arquivo enviado com sucesso!`);
                    navigate("/financeiro/ver-perfil");
                })
            }
        )
        enviarEmail();
        editarNotaFiscalEnviada();
    }

    async function editarAnunciante(novosDados: any) {
        if (idRecebido) {
            try {
                const anuncianteDocRef = doc(db, "anunciante", idRecebido);

                await updateDoc(anuncianteDocRef, novosDados);

                // alert("Dados editados");
            } catch (error) {
                alert("Erro")
            }
        }
    }

    function editarNotaFiscalEnviada() {
        const anunciante = {
            notaFiscalEnviada: true
        }
        editarAnunciante(anunciante)
    }

    const enviarEmail = async () => {
        try {
            const response = await axios.post(
                "https://us-central1-ism-teste.cloudfunctions.net/enviarEmail",
                {
                    destinatario: parametro,
                    assunto: "A sua nota fiscal já está disponível!",
                    mensagem: `Oii, ${nomeAnunciante}!\n\n` +

                        "Sua nota fiscal está disponível\n\n" +

                        "Você pode acessá-la facilmente pela página de notas fiscais na plataforma ou dentro da\n\n" +
                        "página da campanha\n\n" +

                        "Ver notas fiscais: influenciesuamarca.com.br/anunciante/nota-fiscal\n\n" +

                        "Se tiver alguma dúvida, nossa equipe está à disposição para ajudar.\n\n" +

                        "Conte com a gente para influenciar sempre mais\n" +
                        "Equipe ISM"
                }
            );

            const { id } = response.data;
            return id;
        } catch (error) {
            // console.log(error);
        }
    };

    return (
        <section className={styles.EnviarTrabalho}>
            <h2> Enviar nota fiscal </h2>
            {/* <h3> Aqui você pode enviar prints que comprovem o seu trabalho feito. </h3> */}

            <form onSubmit={uploadMidiaKit} className={styles.FormPdf}>


                <CampoTexto
                    titulo="Insira a nota fiscal"
                    valor={comprovanteTrabalho}
                    controle={setComprovanteTrabalho}
                    type="file"
                    icone={<FileCopyOutlinedIcon fontSize="large" />}
                />

                <button className="btn btn-success">
                    Enviar
                </button>

                {/* <button onClick={baixarPdf} className="btn btn-primary"> Visualizar mídia kit </button> */}
            </form>
            <h3> Notas fiscais enviadas </h3>
            <ul>
                {caminhoPdf.map(pdf => {
                    const indiceNotaFiscal = pdf.indexOf("/notaFiscal/");
                    const nomeArquivo = indiceNotaFiscal !== -1 ? pdf.substring(indiceNotaFiscal + 12) : pdf;

                    return (
                        <li onClick={() => pegaCaminho(pdf)} key={pdf}>
                            <FileDownloadIcon fontSize="medium" className={styles.Icone} />
                            <label>{nomeArquivo}</label>
                        </li>
                    );
                })}
            </ul>
        </section>
    );
}