import aceitoStore from "../../../stores/aceito";
import UsuarioNaoAceitoAnunciante from "../../UsuarioNaoAceito/UsuarioNaoAceitoAnunciante";
import styles from "./AguardandoConfirmacao.module.css";
import TabelaAguardandoConfirmacao from "./TabelaAguardandoConfirmacao";

export default function AguardandoConfirmacao() {
    // verifica se usuário foi aceito na plataforma
    const statusPlataforma = aceitoStore.getStatus();
    return (
        <>
            {statusPlataforma === "aceito" ? <section className={styles.AguardandoConfirmacao}>
            <h2> Aguardando confirmação </h2>
            <TabelaAguardandoConfirmacao />
        </section> : <UsuarioNaoAceitoAnunciante />}
        </>
    );
}