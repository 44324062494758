import styles from "./EditarCampanhaAnunciante.module.css";
import { useEffect, useState } from "react";

import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';

import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';

import WrapTextOutlinedIcon from '@mui/icons-material/WrapTextOutlined';
import EventNoteIcon from '@mui/icons-material/EventNote';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';

import PolylineIcon from '@mui/icons-material/Polyline';

import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';

import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import CategoryIcon from '@mui/icons-material/Category';

import MapIcon from '@mui/icons-material/Map';


import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useLocation, useNavigate } from "react-router-dom";
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';

import { collection, doc, getDocs, updateDoc } from "firebase/firestore/lite";

import AreaTexto from "../../../../components/AreaTexto";
import BotaoStepper from "../../../../components/BotaoStepper";
import CampoEscolha from "../../../../components/CampoEscolha";
import CampoSwitch from "../../../../components/CampoSwitch";
import CampoTexto from "../../../../components/CampoTexto";
import StepperPasso from "../../../../components/StepperPasso";
import { db } from "../../../../db/banco";
import autenticaStore from "../../../../stores/autentica.store";
import { estados } from "../../../../utils/apiIbge";
import { listaDeCategorias } from "../../../../utils/listaDeCategorias";
import { listaDeGeneros } from "../../../../utils/listaDeGeneros";
import { listaDeIdadesInicio, listaDeIdadesFim } from "../../../../utils/listaDeIdades";
import { listaDeNichos } from "../../../../utils/listaDeNichos";
import { listaQtdDeSeguidores } from "../../../../utils/listaQtdDeSeguidores";
import { listaTipoDeCampanha } from "../../../../utils/tipoDeCampanha";
import guardaPaginaAnterior from "../../../../stores/paginaAnterior";
import { listaTipoDeMaterialDaCampanha } from "../../../../utils/listas";

export default function EditarCampanhaModerador() {
    // Pegar parametro da página
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const parametro = params.get("id"); // Parâmetro da página    
    // pagina
    const paginaParametro = params.get("pg");
    // Controlar stepper
    const [step, setStep] = useState(0)
    // Gera o id do storage
    const [idStorage, setIdStorage] = useState("");
    // Sobre a campanha
    const [nomeDaCampanha, setNomeDaCampanha] = useState("");
    const [tipoDeCampanha, setTipoDeCampanha] = useState("");
    const [inicioInscricao, setInicioInscricao] = useState("");
    const [fimInscricao, setFimInscricao] = useState("");
    // Material
    const [tipoDeMaterial, setTipoDeMaterial] = useState("");
    const [nichos, setNichos] = useState("");
    const [link, setLink] = useState("");
    const [descreva, setDescreva] = useState("");
    const [cupom, setCupom] = useState("");
    const [descontoCupom, setDescontoCupom] = useState("");
    // Perfil do influenciador
    const [genero, setGenero] = useState("");
    const [switchGenero, setSwitchGenero] = useState(false);
    //
    const [qtdSeguidores, setQtdSeguidores] = useState("");
    const [switchQtdSeguidores, setSwitchQtdSeguidores] = useState(false)
    //
    const [categoria, setCaegoria] = useState("");
    const [switchCategoria, setSwitchCaegoria] = useState(false);
    const [categorias, setCategorias] = useState<string[]>([]);

    //
    const [idadeInicio, setIdadeInicio] = useState("");
    const [idadeFim, setIdadeFim] = useState("");
    const [switchIdade, setSwitchIdade] = useState(false);
    //
    const [estado, setEstado] = useState("");
    const navigate = useNavigate();

    const nomesEstados = estados.map((estado) => estado.nome);
    nomesEstados.sort();

    // banco de dados
    const useCollectionRef = collection(db, "criarCampanha");

    const email = autenticaStore.getEmail();

    // salva a Página
    useEffect(() => {        
        if (paginaParametro) guardaPaginaAnterior.salvarPagina(paginaParametro, false);        
    }, [paginaParametro])

    // puxar dados
    useEffect(() => {
        const obterCampanhas = async () => {
            const dataBD = await getDocs(useCollectionRef);
            const todosChamados = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }))
            const chamadoFiltrado = todosChamados.filter(filtrado => filtrado.id === parametro)
            chamadoFiltrado.forEach(chamado => {
                if ("idStorage" in chamado) setIdStorage(chamado.idStorage as string);
                if ("nomeDaCampanha" in chamado) setNomeDaCampanha(chamado.nomeDaCampanha as string);
                if ("tipoDeCampanha" in chamado) setTipoDeCampanha(chamado.tipoDeCampanha as string);
                if ("inicioInscricao" in chamado) setInicioInscricao(chamado.inicioInscricao as string);
                if ("fimInscricao" in chamado) setFimInscricao(chamado.fimInscricao as string);
                if ("tipoDeMaterial" in chamado) setTipoDeMaterial(chamado.tipoDeMaterial as string);
                if ("nichos" in chamado) setNichos(chamado.nichos as string);
                if ("link" in chamado) setLink(chamado.link as string);
                if ("descreva" in chamado) setDescreva(chamado.descreva as string);
                if ("cupom" in chamado) setCupom(chamado.cupom as string);
                if ("descontoCupom" in chamado) setDescontoCupom(chamado.descontoCupom as string);
                if ("genero" in chamado) setGenero(chamado.genero as string);
                if ("switchGenero" in chamado) setSwitchGenero(chamado.switchGenero as boolean);
                if ("qtdSeguidores" in chamado) setQtdSeguidores(chamado.qtdSeguidores as string);
                if ("switchQtdSeguidores" in chamado) setSwitchQtdSeguidores(chamado.switchQtdSeguidores as boolean);
                if ("categorias" in chamado) setCategorias(chamado.categorias as string[]);
                if ("switchCategoria" in chamado) setSwitchCaegoria(chamado.switchCategoria as boolean);
                if ("idadeInicio" in chamado) setIdadeInicio(chamado.idadeInicio as string);
                if ("idadeFim" in chamado) setIdadeFim(chamado.idadeFim as string);
                if ("switchIdade" in chamado) setSwitchIdade(chamado.switchIdade as boolean);
                if ("estado" in chamado) setEstado(chamado.estado as string);

            })
            console.log(chamadoFiltrado);
            console.log("id storage:", idStorage);            

        }
        obterCampanhas();
    }, [])

    // controla as categorias
    useEffect(() => {
        if (categoria !== '') {
            // Verifica se a categoria já existe no array
            if (!categorias.includes(categoria)) {
                setCategorias(prevCategorias => [...prevCategorias, categoria]);
            }
        }
    }, [categoria, categorias]);

    const removerCategoria = (index: number) => {
        const novasCategorias = [...categorias];
        novasCategorias.splice(index, 1); // Remove o elemento do array usando o índice
        setCategorias(novasCategorias);

        // Atualiza o estado com o novo array sem o item removido
        console.log(index);
        console.log("categoria", categoria);
        console.log("categorias", categorias);
    };

    async function editarCampanha(novosDados: any) {
        try {
            if (parametro) {
                const idCriarChamado = parametro
                const criarCampanhaDocRef = doc(db, "criarCampanha", idCriarChamado);

                await updateDoc(criarCampanhaDocRef, novosDados);
                alert("Dados editados");
            }
        } catch (error) {
            alert("Nao deu certo")
        }
    }

    async function salvarCampanha(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        const campanhaCompleta = {
            idStorage,
            anuncianteEmail: email,
            status: "ativo",
            // Sobre a campanha
            nomeDaCampanha,
            tipoDeCampanha,
            inicioInscricao,
            fimInscricao,
            // Material
            tipoDeMaterial,
            nichos,
            link,
            descreva,
            cupom,
            descontoCupom,
            // Perfil do influenciador
            genero,
            switchGenero,
            //
            qtdSeguidores,
            switchQtdSeguidores,
            //
            categorias,
            switchCategoria,
            //
            idadeInicio,
            idadeFim,
            switchIdade,
            //
            estado
        }
        editarCampanha(campanhaCompleta)
        console.log(campanhaCompleta);        
        navigate(`/moderador/editar-imagem-campanha?id=${idStorage}`)
    }

    function avancaStep() {
        setStep(step + 1);
    }

    function voltaStep() {
        setStep(step - 1);
    }

    return (
        <main className={styles.EditarCampanhaAnunciante}>
            <h2> Editar campanha </h2>

            <StepperPasso passo={step} />

            <form onSubmit={salvarCampanha}>
                {step === 0 ? <div>
                    <CampoTexto
                        titulo="Nome da campanha"
                        valor={nomeDaCampanha}
                        controle={(valor) => setNomeDaCampanha(valor)}
                        icone={<DriveFileRenameOutlineIcon fontSize="large" />}
                        placeholder="Riot Games"
                        minLength={3}
                    />
                    <div className={styles.ContainerAlertas}>
                        {!nomeDaCampanha && <span> Preencha esse campo </span>}
                        {nomeDaCampanha.length < 3 ? <span> Número mínimo de caracteres: 3 </span> : ""}
                    </div>

                    <CampoEscolha
                        lista={listaTipoDeCampanha}
                        titulo="Tipo de campanha"
                        controle={setTipoDeCampanha}
                        icone={<WrapTextOutlinedIcon fontSize="large" />}
                        valorPadrao={tipoDeCampanha}
                    />
                    {tipoDeCampanha.length === 0 ? <span> Preencha esse campo </span> : ""}

                    <CampoTexto
                        titulo="Início da incrição"
                        valor={inicioInscricao}
                        controle={(valor) => setInicioInscricao(valor)}
                        icone={<EventNoteIcon fontSize="large" />}
                        type="date"
                    />
                    {!inicioInscricao && <span> Preencha esse campo </span>}

                    <CampoTexto
                        titulo="Fim da incrição"
                        valor={fimInscricao}
                        controle={(valor) => setFimInscricao(valor)}
                        icone={<EventBusyOutlinedIcon fontSize="large" />}
                        type="date"
                    />
                    <div className={styles.ContainerAlertas}>
                        {!fimInscricao && <span> Preencha esse campo </span>}

                        <button
                            onClick={avancaStep}
                            className="btn btn-secondary"
                            disabled={!nomeDaCampanha || tipoDeCampanha.length === 0 || !inicioInscricao ||
                                !fimInscricao || nomeDaCampanha.length < 3}
                        >
                            Próximo
                        </button>
                        {!nomeDaCampanha || tipoDeCampanha.length === 0 || !inicioInscricao || !fimInscricao ?
                            <span> Preencha os campos para avançar </span> :
                            ""}

                    </div>
                </div> : ''}

                {step === 1 ? <div>
                    <CampoEscolha
                        lista={listaTipoDeMaterialDaCampanha}
                        titulo="Tipo de material"
                        controle={setTipoDeMaterial}
                        icone={<SwitchAccountIcon fontSize="large" />}
                        valorPadrao={tipoDeMaterial}
                    />
                    {tipoDeMaterial.length === 0 ? <span> Preencha esse campo </span> : ""}

                    <CampoEscolha
                        lista={listaDeNichos}
                        titulo="Nicho"
                        controle={setNichos}
                        icone={<PolylineIcon fontSize="large" />}
                        valorPadrao={nichos}
                    />
                    {nichos.length === 0 ? <span> Preencha esse campo </span> : ""}

                    <CampoTexto
                        titulo="Link"
                        valor={link}
                        controle={(valor) => setLink(valor)}
                        icone={<EventBusyOutlinedIcon fontSize="large" />}
                        placeholder="instagram.com/riotgames/"
                    />
                    {!link && <span> Preencha esse campo </span>}

                    <AreaTexto titulo="Descreva" valor={descreva} controle={setDescreva} />


                    <CampoTexto
                        titulo="Cupom"
                        placeholder="LUIZA20"
                        valor={cupom}
                        controle={setCupom}
                        minLength={3}
                        icone={<LocalActivityIcon fontSize="large" />}
                    />

                    <div className={styles.ContainerAlertas}>
                        {!cupom && <span> Preencha esse campo </span>}
                        {cupom.length < 3 ? <span> Número mínimo de caracteres: 3 </span> : ""}
                    </div>

                    <CampoTexto
                        titulo=" Desconto do cupom"
                        placeholder="20%"
                        valor={descontoCupom}
                        controle={setDescontoCupom}
                        minLength={2}
                        icone={<ConfirmationNumberIcon fontSize="large" />}
                    />

                    <div className={styles.ContainerAlertas}>
                        {!descontoCupom && <span> Preencha esse campo </span>}
                        {descontoCupom.length < 2 ? <span> Número mínimo de caracteres: 2 </span> : ""}
                    </div>
                    <div className={styles.ContainerAlertas}>
                        {/* {!descreva && <span> Preencha esse campo </span>} */}

                        {tipoDeMaterial.length === 0 || nichos.length === 0 || !link ?
                            <span> Preencha os campos para avançar </span> :
                            ""}
                    </div>

                    <section>
                        <BotaoStepper controle={voltaStep}>
                            Anterior
                        </BotaoStepper>

                        <button
                            onClick={avancaStep}
                            className="btn btn-secondary"
                            disabled={tipoDeMaterial.length === 0 || nichos.length === 0 || !link}
                        >
                            Próximo
                        </button>

                    </section>
                </div> : ''}

                {step === 2 ? <div>

                    <CampoEscolha
                        lista={listaDeGeneros}
                        titulo="Gênero"
                        icone={<PeopleOutlineOutlinedIcon fontSize="large" />}
                        valorPadrao={genero}
                        controle={setGenero}
                    />
                    {genero.length === 0 ? <span> Preencha esse campo </span> : ""}

                    <CampoSwitch
                        titulo="Aceita fora do escolhido?"
                        valor={switchGenero}
                        controle={setSwitchGenero}
                    />

                    <CampoEscolha
                        lista={listaQtdDeSeguidores}
                        titulo="Quantidade de seguidores"
                        icone={<GroupsOutlinedIcon fontSize="large" />}
                        valorPadrao={qtdSeguidores}
                        controle={setQtdSeguidores}
                    />
                    {qtdSeguidores.length === 0 ? <span> Preencha esse campo </span> : ""}

                    <CampoSwitch
                        titulo="Aceita fora do escolhido?"
                        valor={switchQtdSeguidores}
                        controle={setSwitchQtdSeguidores}
                    />

                    <CampoEscolha
                        lista={listaDeCategorias}
                        titulo="Categoria do seu influenciador"
                        icone={<CategoryIcon fontSize="large" />}
                        valorPadrao="Nicho que prefere"
                        controle={setCaegoria}
                    />
                    {categorias.length === 0 ? <span> Preencha esse campo </span> : ""}

                    {/* Usar como componente no futuro */}
                    <div className={styles.Categorias}>
                        <label> Nichos escolhidos </label>
                        <ul>
                            {categorias.map((categoria, index) => (
                                <li key={index}> {categoria} <CloseOutlinedIcon fontSize="small" onClick={() => removerCategoria(index)} /> </li>
                            ))}
                        </ul>
                    </div>

                    <CampoSwitch
                        titulo="Aceita fora do escolhido?"
                        valor={switchCategoria}
                        controle={setSwitchCaegoria}
                    />

                    <section>
                        <CampoEscolha
                            lista={listaDeIdadesInicio}
                            titulo="Idade, de:"
                            icone={<EventNoteIcon fontSize="large" />}
                            valorPadrao={idadeInicio}
                            controle={setIdadeInicio}
                        />

                        <CampoEscolha
                            lista={listaDeIdadesFim}
                            titulo="até"
                            icone={<EventBusyOutlinedIcon fontSize="large" />}
                            valorPadrao={idadeFim}
                            controle={setIdadeFim}
                        />
                    </section>
                    {idadeInicio.length === 0 || idadeFim.length === 0 ? <span> Preencha esses campos </span> : ""}
                    <CampoSwitch
                        titulo="Aceita fora do escolhido?"
                        valor={switchIdade}
                        controle={setSwitchIdade}
                    />

                    <CampoEscolha
                        lista={nomesEstados}
                        titulo="Estado"
                        icone={<MapIcon fontSize="large" />}
                        valorPadrao={estado}
                        controle={setEstado}
                    />
                    <div className={styles.ContainerAlertas}>
                        {estado.length === 0 ? <span> Preencha esse campo </span> : ""}

                        <BotaoStepper controle={voltaStep}>
                            Anterior
                        </BotaoStepper>
                    </div>
                </div> : ''}

                {step === 2 ? <><button className="btn btn-primary" disabled={genero.length === 0 ||
                    qtdSeguidores.length === 0 || categorias.length === 0 || idadeInicio.length === 0 ||
                    idadeFim.length === 0 || estado.length === 0}>
                    Editar
                </button> {genero.length === 0 || qtdSeguidores.length === 0 || categorias.length === 0 ||
                    idadeInicio.length === 0 || idadeFim.length === 0 || estado.length === 0
                    ? <span> Preencha os campos para criar </span> : ""}</> : ''}
            </form>
        </main>
    );
}