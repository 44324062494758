import styles from "./Carteira.module.css";
import autenticaStore from "../../../stores/autentica.store";
import { collection, getDocs } from "firebase/firestore/lite";
import { useEffect, useState } from "react";
import { db } from "../../../db/banco";
import IAnunciante from "../../../types/IAnunciante";
import ICriarCampanha from "../../../types/ICriarCampanha";
import CardCampanhaCarteira from "./CardCampanhaCarteira";
import aceitoStore from "../../../stores/aceito";
import UsuarioNaoAceitoAnunciante from "../../UsuarioNaoAceito/UsuarioNaoAceitoAnunciante";

export default function Carteira() {
    // verifica se usuário foi aceito na plataforma
    const statusPlataforma = aceitoStore.getStatus();
    // obtem o email(serve como id) do anunciante
    const email = autenticaStore.getEmail();
    const [saldo, setSaldo] = useState(0);
    const [enviarListaCampanhas] = useState([""]);
    const [saldoDaCampanha, setSaldoDaCampanha] = useState(0);
    const [campanhas, setCampanhas] = useState<ICriarCampanha[]>([]);

    
    // anunciante
    const useCollectionRef = collection(db, "anunciante");
    useEffect(() => {
        const obterAnunciantes = async () => {
            const dataBD = await getDocs(useCollectionRef);
            const todosAnunciantes: IAnunciante[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as IAnunciante);
            const filtrado: IAnunciante[] = todosAnunciantes.filter(filtrado => filtrado.email === email);
            
            filtrado.map(esseAnunciante => {
                setSaldo(esseAnunciante.saldo);
            })
        }
        obterAnunciantes();
    }, []);

    // campanhas
    const useCollectionReff = collection(db, "criarCampanha");
    useEffect(() => {
        const obterAnunciantes = async () => {
            const dataBD = await getDocs(useCollectionReff);
            const todosAnunciantes: ICriarCampanha[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as ICriarCampanha);
            const campanhasFiltradas: ICriarCampanha[] = todosAnunciantes.filter(filtrado => filtrado.anuncianteEmail === email);

            setCampanhas(campanhasFiltradas);
        }
        obterAnunciantes();
    }, [])



    return (
        <>
            {statusPlataforma === "aceito" ? <section className={styles.Carteira}>
            <CardCampanhaCarteira saldo={saldo} />            
        </section> : <UsuarioNaoAceitoAnunciante />}
        </>
    )
}