import { useEffect, useRef, useState } from "react";
import styles from "./NovoChat.module.css";
import iconeOutraPessoa from "./image/icone-ism-branco-verde.png"
import SendIcon from '@mui/icons-material/Send';
import { addDoc, collection, doc, getDocs, updateDoc } from "firebase/firestore/lite";
import { db } from "../../../db/banco";
import IChatPropostas from "../../../types/ICharPropostas";
import { listaTiposDeCampanha } from "../../../utils/listas";
import IInfluenciador from "../../../types/IInfluenciador";
import { criarNotificacaoInfluenciadorAceitoEmCampanha, criarNovaNotificacaoCampanhaInvisivel, enviarEmailParaAnuncianteCampanhaInvisivel, enviarEmailParaInfluenciadorAceitoEmCampanha } from "../../../utils/functions";
import { useLocation, useNavigate } from "react-router-dom";
import { mascaraDinheiro } from "../../../utils/mascaras";
import { Alert, Button } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import INovoChat from "../../../types/INovoChat";

export default function NovoChat() {
    const navigate = useNavigate();
    // Recebe o chat proposta
    const [chatPropostas, setChatPropostas] = useState<IChatPropostas[]>([]);
    const [idChatPropostas, setIdChatPropostas] = useState("");
    const [tipoCampanha, setTipoCampanha] = useState("");
    const [jaTemCodigo, setJaTemCodigo] = useState(0);
    const [quantidadeInfluenciadoresComCodigo, setQuantidadeInfluenciadoresComCodigo] = useState(0);
    const [emailDoInfluenciador, setEmailDoInflueciador] = useState("");
    const [idInfluenciador, setIdInfluenciador] = useState("");
    const [nomeDaCampanha, setNomeDaCampanha] = useState("");
    const [nomeDoInfluenciador, setNomeDoInfluenciador] = useState("");
    const [valorJaCombinado, setValorJaCombinado] = useState(0);
    const [valor, setValor] = useState("");
    const [mostrarValor, setMostrarValor] = useState(true);
    const [saldoDaCampanha, setSaldoDaCampanha] = useState(0);
    const [idCampanha, setIdCampanha] = useState("");
    const [emailDoAnunciante, setEmailDoAnunciante] = useState("");
    const [statusProposta, setStatusProposta] = useState("");
    const [atualizaChatProposta, setAtualizaChatProposta] = useState(false);
    // Recebe o novo chat
    const [novoChat, setNovoChat] = useState<INovoChat[]>([]);
    const [atualizaChat, setAtualizaChat] = useState(false);
    // Alertas
    const [alertaSaldo, setAlertaSaldo] = useState(false);
    const [alertaSucesso, setAlertaSucesso] = useState(false);
    const [alertaAviso, setAlertaAviso] = useState(false);
    const [alertaInfluenciadorAceito, setAlertaInfluenciadorAceito] = useState(false);
    const [alertaNaoPodeAlterarProposta, setAlertaNaoPodeAlterarProposta] = useState(false);
    // Criar um parâmetro para saber quem está conversando com quem.
    // const [ordemConversa, SetOrdemConversa] = useState("anun");
    // Mensagem
    const [mensagem, setMensagem] = useState("");
    const [dataHora, setDataHora] = useState("");
    const [hora, setHora] = useState("");
    
    // Pegar parametro da página
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const parametro = params.get("id");
    const ordemConversa = params.get("tp");
    // Scroll sempre no final
    const chatRef = useRef<HTMLDivElement>(null);

    // Scroll ao carregar o componente
    useEffect(() => {
        const scrollToBottom = () => {
            if (chatRef.current) {
                chatRef.current.scrollTop = chatRef.current.scrollHeight;
            }
        };

        // Pequeno atraso para garantir renderização completa
        setTimeout(scrollToBottom, 0);
    }, []); // Executa apenas uma vez ao montar o componente

    // Scroll ao adicionar novas mensagens
    useEffect(() => {
        const scrollToBottom = () => {
            if (chatRef.current) {
                chatRef.current.scrollTop = chatRef.current.scrollHeight;
            }
        };

        // Pequeno atraso para garantir renderização completa
        setTimeout(scrollToBottom, 0);
    }, [atualizaChat]); // Atualiza sempre que novas mensagens chegarem
    // 0 influenciador, 1 anunciante    
    // Banco de dados
    // Chat propostas
    const useCollectionRefChatProposta = collection(db, "chatPropostas");
    useEffect(() => {
        const obterChatProposta = async () => {
            // pega o chat proposta certo
            const dataBD = await getDocs(useCollectionRefChatProposta);
            const todosChamados = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id } as IChatPropostas));
            const filtrado = todosChamados.filter(chamado => chamado.id === parametro);
            setChatPropostas(filtrado);

            filtrado.map(pegarDadosChatProposta => {
                setIdChatPropostas(pegarDadosChatProposta.id);
                setTipoCampanha(pegarDadosChatProposta.tipoCampanha);
                setIdInfluenciador(pegarDadosChatProposta.idInfluenciador);
                setNomeDaCampanha(pegarDadosChatProposta.nomeCampanha);
                setEmailDoInflueciador(pegarDadosChatProposta.emailInfluenciador);
                setNomeDoInfluenciador(pegarDadosChatProposta.nomeInfluenciador);
                setSaldoDaCampanha(pegarDadosChatProposta.saldoCampanha);
                setIdCampanha(pegarDadosChatProposta.idCampanha);
                setEmailDoAnunciante(pegarDadosChatProposta.emailAnunciante);
                setValorJaCombinado(pegarDadosChatProposta.valorCombinado);
                setStatusProposta(pegarDadosChatProposta.statusProposta);
            })
        };
        obterChatProposta();
    }, []);
    // Influenciador
    const useCollectionRefInfluenciador = collection(db, "influenciador");
    useEffect(() => {
        const obterUsuario = async () => {
            const dataBD = await getDocs(useCollectionRefInfluenciador);
            const todosInfluenciadores: IInfluenciador[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as IInfluenciador);
            const influenciadoresComCodigoActionpay = todosInfluenciadores.filter(achou => achou.codigoActionpayInfluenciador);
            setQuantidadeInfluenciadoresComCodigo(influenciadoresComCodigoActionpay.length);
            //  Verifica se o influenciador tem codigo actionpay
            if (emailDoInfluenciador) {
                const esseJaTem = influenciadoresComCodigoActionpay.filter(esse => esse.email === emailDoInfluenciador);
                setJaTemCodigo(esseJaTem.length);
            }
        };
        obterUsuario();
    }, [emailDoInfluenciador, jaTemCodigo]);

    // Aceitar influenciador
    function aceitarInfluenciador() {
        if (tipoCampanha === listaTiposDeCampanha[2] || tipoCampanha === listaTiposDeCampanha[3]
            || tipoCampanha === listaTiposDeCampanha[4]) aceitarInfluenciadorCampanhaComPerformance();
        else aceitarInfluenciadorCampanhaSemPerformance();
    }
    // Verifica se influenciador tem codigo
    async function criarCodigoActionpayInfluenciador() {
        if (jaTemCodigo === 0) {
            const influenciador = {
                codigoActionpayInfluenciador: 1001 + quantidadeInfluenciadoresComCodigo
            }
            await editarInfluenciador(influenciador);
        } else {

        }
    }
    // Editar chatProposta
    async function editarChamado(novosDados: any) {
        try {
            if (idChatPropostas) {
                const chamadoDocRef = doc(db, "chatPropostas", idChatPropostas);

                await updateDoc(chamadoDocRef, novosDados);
                // alert("Dados editados");
            }
        } catch (error) {
            alert("Nao deu certo")
        }
    }
    // Editar influenciador
    async function editarInfluenciador(novosDados: any) {
        try {
            if (idInfluenciador) {
                const chamadoDocRef = doc(db, "influenciador", idInfluenciador);

                await updateDoc(chamadoDocRef, novosDados);
                // alert("Dados editados");
            }
        } catch (error) {
            alert("Nao deu certo")
        }
    }
    // Aceitar influenciador em campanhas com performance
    async function aceitarInfluenciadorCampanhaComPerformance() {
        alert("Com Performance");
        criarCodigoActionpayInfluenciador();
        criarNotificacaoInfluenciadorAceitoEmCampanha(nomeDaCampanha, emailDoInfluenciador);
        enviarEmailParaInfluenciadorAceitoEmCampanha(emailDoInfluenciador, nomeDoInfluenciador);
        const conversa = {
            statusProposta: "aceito"
        }
        await editarChamado(conversa)
        setAlertaInfluenciadorAceito(true);
    }
    // Aceitar influenciador em campanhas SEM performance
    async function aceitarInfluenciadorCampanhaSemPerformance() {
        if (valorJaCombinado !== 0) {
            criarCodigoActionpayInfluenciador();
            criarNotificacaoInfluenciadorAceitoEmCampanha(nomeDaCampanha, emailDoInfluenciador);
            enviarEmailParaInfluenciadorAceitoEmCampanha(emailDoInfluenciador, nomeDoInfluenciador);
            editarSaldoCampanha();
            const conversa = {
                statusProposta: "aceito",
                saldoCampanha: saldoDaCampanha - valorJaCombinado
            }
            await editarChamado(conversa);
            setAlertaInfluenciadorAceito(true);
            if ((saldoDaCampanha - valorJaCombinado) < 100) {
                enviarEmailParaAnuncianteCampanhaInvisivel(emailDoAnunciante);
                await criarNovaNotificacaoCampanhaInvisivel(emailDoAnunciante);
            }
        } else {
            setAlertaAviso(true);
        }
    }

    // Alterar proposta
    function trocarParaAlterarProposta() {
        setMostrarValor(false);
    }

    async function definirValorCombinado() {
        // setMostrarValor(true);
        let valorLimpo = valor.replace(/[^\d,]/g, '');
        const valorConvertido = Number(valorLimpo);

        if (statusProposta === "aceito") {
            setAlertaNaoPodeAlterarProposta(true);
            return;
        }

        if (valorConvertido > saldoDaCampanha) {
            setAlertaSaldo(true);
        } else {
            setAlertaSaldo(false);
            const conversa = {
                valorCombinado: valorConvertido
            }
            await editarChamado(conversa);
            setAlertaSucesso(true);
            setMostrarValor(true);
        }
        setAtualizaChatProposta(!atualizaChatProposta);
    }
    // Sucesso
    function fechatAlertaSucesso() {
        setAlertaSucesso(false);
    }
    // Influenciador aceito
    function fecharAlertaInfluenciadorAceito() {
        setAlertaInfluenciadorAceito(false);
    }
    // Aviso
    function fechatAlertaAviso() {
        setAlertaAviso(false);
    }
    // Campanha
    async function editarSaldoCampanha() {
        const campanhaCompleta = {
            saldoCampanha: saldoDaCampanha - valorJaCombinado
        }
        editarCampanha(campanhaCompleta);
    }

    async function editarCampanha(novosDados: any) {
        try {
            const criarCampanhaDocRef = doc(db, "criarCampanha", idCampanha);

            await updateDoc(criarCampanhaDocRef, novosDados);

        } catch (error) {
            alert("Nao deu certo")
        }
    }

    // Mensagem
    // banco de dados
    const useCollectionRefNovoChat = collection(db, "novoChat");
    async function enviarMensagem() {
        const pegarHora = new Date();
        const time = pegarHora.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' });
        
        const tipo = ordemConversa === "influ" ? 0 : 1;
        
        const novoChat = await addDoc(useCollectionRefNovoChat, {
            idChatPropostas,
            dataHora: new Date().toISOString(),
            hora: time,
            mensagem,
            tipo
        })
        setAtualizaChat(!atualizaChat);
        setMensagem("");
    }
    // Obter o novo chat    
    useEffect(() => {
        const obterChat = async () => {
            const dataBD = await getDocs(useCollectionRefNovoChat);
            const todosNovosChats: INovoChat[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as INovoChat);
            const somenteChatsDesseChatProposta = todosNovosChats.filter(peloIdChatProposta => peloIdChatProposta.idChatPropostas === idChatPropostas);
            setNovoChat(somenteChatsDesseChatProposta);
            console.log("novo chat", somenteChatsDesseChatProposta);

            // const intervalId = setInterval(obterChat, 2000);

            // return () => clearInterval(intervalId);
        };
        obterChat();
    }, [idChatPropostas, atualizaChat]);

    return (
        <section className={styles.NovoChat}>
            <nav>
                <div>
                    <img src={iconeOutraPessoa} alt="icone ism" />
                    {chatPropostas.map(nomePessoa => (
                        <p> {ordemConversa === "influ" ? nomePessoa.nomeDoAnunciante : nomePessoa.nomeInfluenciador} </p>
                    ))}
                </div>

                {ordemConversa !== "influ" && statusProposta === "espera" && <div>
                    <div>
                        <label> Deseja aceitar esse influenciador? </label>
                        <button className="btn btn-success" onClick={aceitarInfluenciador}> Aceitar </button>
                    </div>


                </div>}                

                {ordemConversa === "influ" && statusProposta === "aceito" && <div> <div> <p> <CheckCircleOutlineIcon fontSize="large" color="success" /> Você já está aceito nessa campanha. </p> </div> </div>}
                {ordemConversa === "anun" && statusProposta === "aceito" && <div> <div>  <p> <CheckCircleOutlineIcon fontSize="large" color="success" /> Você já aceitou o influenciador nessa campanha. </p> </div> </div>}
            </nav>

            {alertaAviso && <span>
                <Alert
                    severity="warning"
                    action={
                        <Button color="inherit" size="small" onClick={fechatAlertaAviso}>
                            x
                        </Button>
                    }
                >
                    Valor combinado não pode ser R$ 0,00.
                </Alert>
            </span>}

            {alertaInfluenciadorAceito && <span>
                <Alert
                    severity="success"
                    action={
                        <Button color="inherit" size="small" onClick={fecharAlertaInfluenciadorAceito}>
                            x
                        </Button>
                    }
                >
                    Influenciador aceito na campanha!
                </Alert>
            </span>}

            <main ref={chatRef}>
                {novoChat
                    .filter((mensagem: INovoChat) => {
                        const now = new Date().getTime(); // Data e hora atual em milissegundos
                        const chatTime = new Date(mensagem.dataHora).getTime(); // Data e hora do chat em milissegundos
                        return chatTime <= now; // Comparação precisa em milissegundos
                    })
                    .sort((a: INovoChat, b: INovoChat) => {
                        const dateA = new Date(a.dataHora).getTime();
                        const dateB = new Date(b.dataHora).getTime();
                        return dateA - dateB; // Ordena do mais antigo ao mais recente
                    })
                    .map((mockado: INovoChat) => (
                        <span
                            key={`${mockado.tipo}-${mockado.dataHora}`} // Gera uma chave única combinando tipo e dataHora
                            className={
                                // mockado.tipo === 0 ? styles.LadoPessoaQueEnvia  : styles.LadoOutraPessoa
                                mockado.tipo === 1 && ordemConversa === "anun" ? styles.LadoPessoaQueEnvia  : styles.LadoOutraPessoa
                            }
                        >
                            <div>
                                <p>{mockado.mensagem}</p>
                                <strong>
                                    {new Date(mockado.dataHora).toLocaleTimeString('pt-BR', {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        // second: '2-digit',
                                    })} {/* Exibe a hora com segundos */}
                                </strong>                                
                            </div>
                        </span>
                    ))}
            </main>


            <article>
                {alertaSucesso && <Alert
                    severity="success"
                    action={
                        <Button color="inherit" size="small" onClick={fechatAlertaSucesso}>
                            x
                        </Button>
                    }
                >
                    Valor combinado.
                </Alert>}
                {alertaSaldo && <Alert severity="warning"> Valor pretendido maior que o saldo na campanha. </Alert>}
                {alertaNaoPodeAlterarProposta && <Alert severity="warning"> Não é possível alterar o valor de proposta
                    após aceitar influenciador na campanha. 
                </Alert>}
                <aside>
                    <div>
                        {tipoCampanha === listaTiposDeCampanha[2] || tipoCampanha === listaTiposDeCampanha[3]
                            || tipoCampanha === listaTiposDeCampanha[4] ? <>
                            {/* Campanha com performance */}
                            <p> Campanha com performance </p>
                        </> :
                            <>
                                {/* Campanha sem performance */}
                                {chatPropostas.map(valorCombinado => (
                                    <>
                                        {mostrarValor ? <> <p> {valorCombinado.valorCombinado ? `Valor combinado: R$ ${valorCombinado.valorCombinado},00` : "Valor combinado: R$ 0,00"} </p>
                                            {ordemConversa !== "influ" && <button onClick={trocarParaAlterarProposta}> Alterar proposta </button>} </> :
                                            <>
                                                <p> Seu saldo: R$ {saldoDaCampanha},00 </p>
                                                <input
                                                    value={mascaraDinheiro(valor)}
                                                    placeholder="R$ 100,00"
                                                    onChange={(e) => setValor(e.target.value)}

                                                />
                                                <button onClick={definirValorCombinado}> Confirmar </button>
                                            </>}
                                    </>
                                ))}

                            </>}
                    </div>
                </aside>

                <footer>
                    <div>
                        <input
                            value={mensagem}
                            onChange={(e) => setMensagem(e.target.value)}
                            placeholder={ordemConversa === "influ" ? "Qual sua proposta?" : "Quantas postagens você costuma fazer por campanha?"}
                        />
                        <button onClick={enviarMensagem}> <SendIcon fontSize="large" /> </button>
                    </div>
                </footer>
            </article>
        </section>
    );
}