import { useEffect, useState } from "react";
import styles from "./Atualizacao.module.css";

export default function Atualizacao() {
    const [data, setData] = useState("");
    const [ontem, setOntem] = useState("");
    const [hora, setHora] = useState(0);

    useEffect(() => {
        // data completa
        const dataCompleta = new Date();
        // data de hoje
        const dataHoje = dataCompleta.toLocaleDateString();
        // hora no momento que abre a página
        const horaNoMomento = dataCompleta.getHours();
        // data de ontem
        const dataCompletaParaOntem = new Date();
        dataCompletaParaOntem.setDate(dataCompletaParaOntem.getDate() - 1);
        const dataOntem = dataCompletaParaOntem.toLocaleDateString();

        setData(dataHoje);
        setOntem(dataOntem);
        setHora(horaNoMomento);        
    }, []);    

    return (
        <section className={styles.Atualizacao}>
            <h2> Atualização dos links das campanhas de performance </h2>

            <p> Todos os links das campanhas de performance serão atualizados (caso sejam mudados), de forma automática, às 2h todos os dias. </p>

            <h3> {hora === 0 || hora === 1 ? `Servidor atualizado pela última vez: ${ontem} às 2 horas.` :
                `Servidor atualizado pela última vez: ${data} às 2 horas.`}  
            </h3>             
        </section>
    );
}