import { SxProps } from '@mui/system';

export const autocompleteStyles: SxProps = {
    width: 400,
    '& .MuiOutlinedInput-input': {
        padding: '10.5px 14px',
        borderRadius: '10px', // Adiciona border-radius de 10px
        '&::placeholder': {
            color: 'black',
            opacity: 1, // Garante que a cor preta seja visível
        },
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: '10px', // Adiciona border-radius de 10px
        '& fieldset': {
            border: '1px solid', // Adiciona uma borda sólida
            borderColor: 'transparent', // Torna a borda transparente para usar o gradiente
            borderImage: 'linear-gradient(to right, var(--pink), var(--indigo), var(--dark-blue)) 1', // Gradiente para a borda
            borderRadius: '10px', // Adiciona border-radius de 10px
        },
        '&:hover fieldset': {
            borderColor: 'transparent', // Torna a borda transparente para usar o gradiente
            borderImage: 'linear-gradient(to right, var(--pink), var(--indigo), var(--dark-blue)) 1', // Gradiente para a borda
            borderRadius: '10px', // Adiciona border-radius de 10px
        },
        '&.Mui-focused fieldset': {
            borderColor: 'transparent', // Torna a borda transparente para usar o gradiente
            borderImage: 'linear-gradient(to right, var(--pink), var(--indigo), var(--dark-blue)) 1', // Gradiente para a borda
            borderRadius: '10px', // Adiciona border-radius de 10px
        },
    },
    '& .MuiInputAdornment-root': {
        marginRight: 1,
        '& .MuiSvgIcon-root': {
            background: 'linear-gradient(to right, var(--pink), var(--indigo), var(--dark-blue))',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
        },
    },
    '& .MuiInputLabel-root': {
        background: 'linear-gradient(to right, var(--pink), var(--indigo), var(--dark-blue))',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
    },
};
