import { Outlet } from "react-router-dom";
import Menu from "./Menu";
import Rodape from "./Rodape";

export default function PaginaBaseRotaPublicaVisitante() {
    return (
        <>
            <Menu />
                <Outlet />
            <Rodape />
        </>
    )
}